//icons
import { FaOdnoklassniki, FaTelegramPlane, FaVk, FaYoutube } from 'react-icons/fa'
//translate
import { useTranslation } from 'react-i18next'

/*
    Данный компонент отвечает за отображение подвала сайта
*/
const Footer = () => {
	const { t } = useTranslation()
	const year = new Date().getFullYear()

	return (
		// <div className='flex-none text-xs md:flex justify-between !text-sm bg-gray-200 dark:bg-gray-900 p-4 lg:text-base'>
		<div className='flex-none !text-xs md:grid grid-cols-5 gap-2 md:!text-sm bg-gray-200 dark:bg-gray-900 p-4 lg:text-base'>
			<ul className='mb-3 list-none pl-0 md:!mb-0 dark:!text-white'>
				<li className='mb-[4px]'>
					<a
						href='https://tpu.ru'
						className='dark:!text-white dark:hover:!text-g_color_1'
						target={'_blank'}
						rel='noreferrer'
					>
						{t('footer.website')}
					</a>
				</li>
				<li className='mb-[4px]'>
					<a
						href='https://portal.tpu.ru'
						className='dark:!text-white dark:hover:!text-g_color_1'
						target={'_blank'}
						rel='noreferrer'
					>
						{t('footer.corporate_portal')}
					</a>
				</li>
				<li className='mb-[4px]'>
					<a
						href='https://portal.tpu.ru/science'
						className='dark:!text-white dark:hover:!text-g_color_1'
						target={'_blank'}
						rel='noreferrer'
					>
						{t('footer.events')}
					</a>
				</li>
				<li className='mb-[4px]'>
					<a
						href='https://staff.tpu.ru/personal'
						className='dark:!text-white dark:hover:!text-g_color_1'
						target={'_blank'}
						rel='noreferrer'
					>
						{t('footer.phonebook')}
					</a>
				</li>
				<li className='mb-0'>
					<a
						href='https://maps.tpu.ru'
						className='dark:!text-white dark:hover:!text-g_color_1'
						target={'_blank'}
						rel='noreferrer'
					>
						{t('footer.campus_map')}
					</a>
				</li>
			</ul>
			<ul className='mb-3 list-none pl-0'>
				<li className='mb-[4px]'>
					<a
						href='https://mail.tpu.ru'
						className='dark:!text-white dark:hover:!text-g_color_1'
						target={'_blank'}
						rel='noreferrer'
					>
						{t('footer.email')}
					</a>
				</li>
				<li className='mb-[4px]'>
					<a
						href='https://raspisanie.tpu.ru'
						className='dark:!text-white dark:hover:!text-g_color_1'
						target={'_blank'}
						rel='noreferrer'
					>
						{t('footer.timetable')}
					</a>
				</li>
				<li className='mb-[4px]'>
					<a
						href='https://staff.tpu.ru/html/it'
						className='dark:!text-white dark:hover:!text-g_color_1'
						target={'_blank'}
						rel='noreferrer'
					>
						{t('footer.digital_services')}
					</a>
				</li>
				<li className='mb-[4px]'>
					<a
						href='https://staff.tpu.ru/html/wifi'
						className='dark:!text-white dark:hover:!text-g_color_1'
						target={'_blank'}
						rel='noreferrer'
					>
						{t('footer.wifi_setting')}
					</a>
				</li>
				<li className='mb-0'>
					<a
						href='https://help.tpu.ru'
						className='dark:!text-white dark:hover:!text-g_color_1'
						target={'_blank'}
						rel='noreferrer'
					>
						{t('footer.support')}
					</a>
				</li>
			</ul>
			<ul className='mb-3 list-none pl-0'>
				<li className='mb-[4px]'>
					<a
						href='https://lib.tpu.ru'
						className='dark:!text-white dark:hover:!text-g_color_1'
						target={'_blank'}
						rel='noreferrer'
					>
						{t('footer.library')}
					</a>
				</li>
				<li className='mb-[4px]'>
					<a
						href='https://vk.com/postpu'
						className='dark:!text-white dark:hover:!text-g_color_1'
						target={'_blank'}
						rel='noreferrer'
					>
						{t('footer.student_union')}
					</a>
				</li>
				<li className='mb-[4px]'>
					<a
						href='https://portal.tpu.ru/www/sites'
						className='dark:!text-white dark:hover:!text-g_color_1'
						target={'_blank'}
						rel='noreferrer'
					>
						{t('footer.teachers_websites')}
					</a>
				</li>
				<li className='mb-[4px]'>
					<a
						href='https://edu.tpu.ru'
						className='dark:!text-white dark:hover:!text-g_color_1'
						target={'_blank'}
						rel='noreferrer'
					>
						{t('footer.lecture_hall')}
					</a>
				</li>
				<li className='mb-0'>
					<a
						href='https://openedu.tpu.ru'
						className='dark:!text-white dark:hover:!text-g_color_1'
						target={'_blank'}
						rel='noreferrer'
					>
						{t('footer.mooc')}
					</a>
				</li>
			</ul>
			<ul className='mb-3 list-none pl-0'>
				<li className='mb-[4px]'>
					<a
						href='http://profkom.tpu.ru'
						className='dark:!text-white dark:hover:!text-g_color_1'
						target={'_blank'}
						rel='noreferrer'
					>
						{t('footer.employee_union')}
					</a>
				</li>
				<li className='mb-[4px]'>
					<a
						href='https://profkom.tpu.ru/html/koldogovor'
						className='dark:!text-white dark:hover:!text-g_color_1'
						target={'_blank'}
						rel='noreferrer'
					>
						{t('footer.collective_agreement')}
					</a>
				</li>
				<li className='mb-[4px]'>
					<a
						href='https://tpu.ru/anticorruption'
						className='dark:!text-white dark:hover:!text-g_color_1'
						target={'_blank'}
						rel='noreferrer'
					>
						{t('footer.anti_corruption')}
					</a>
				</li>
				<li className='mb-[4px]'>
					<a
						href='https://portal.tpu.ru/www/instruktazh'
						className='dark:!text-white dark:hover:!text-g_color_1'
						target={'_blank'}
						rel='noreferrer'
					>
						{t('footer.terrorist_attacks_event')}
					</a>
				</li>
				<li className='mb-0'>
					<a
						href='http://intergrant.tpu.ru/webcenter/portal/ompg/actual'
						className='dark:!text-white dark:hover:!text-g_color_1'
						target={'_blank'}
						rel='noreferrer'
					>
						{t('footer.international_programs')}
					</a>
				</li>
			</ul>
			<div>
				<div className='mb-3 flex'>
					<a href='https://vk.com/tpunews' target={'_blank'} rel='noreferrer'>
						<FaVk size='28' className='mr-[10px] dark:!text-white hover:text dark:hover:!text-g_color_1' />
					</a>
					<a href='https://ttttt.me/newstpu' target={'_blank'} rel='noreferrer'>
						<FaTelegramPlane size='28' className='mr-[10px] dark:!text-white dark:hover:!text-g_color_1' />
					</a>
					<a href='https://www.youtube.com/user/TPUmedia' target={'_blank'} rel='noreferrer'>
						<FaYoutube size='28' className='mr-[10px] dark:!text-white dark:hover:!text-g_color_1' />
					</a>
					<a href='https://www.ok.ru/tpunews' target={'_blank'} rel='noreferrer'>
						<FaOdnoklassniki size='28' className='dark:!text-white dark:hover:!text-g_color_1' />
					</a>
				</div>
				<span>
					<a href='https://tpu.ru' target={'_blank'} rel='noreferrer'>
						{t('tpu.title')}
					</a>
				</span>
				<br />
				<span>{t('tpu.rights')}</span>
				<span>&nbsp;2022 - {year}</span>
			</div>
		</div>
	)
}

export default Footer
