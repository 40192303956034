// Продвинутые хуки и функции из файла requestServerApi.jsx по работе с api
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useEffectOnce } from '../hooks/useEffectOnce'
import { requestDeleteApi, requestGetApi, requestPostApi } from '../services/api/requestServerApi'

// TODO: потом убрать переменную devSwitch
const getApi = (url, headers, setResApi, setIsLoading, setIsError, setMessage, devSwitch, otherApi) => {
	requestGetApi(url, headers, setResApi, setIsLoading, setIsError, setMessage, devSwitch, otherApi)
}

const postApi = (url, headers, setResApi, setIsLoading, setIsError, setMessage, devSwitch, otherApi) => {
	requestPostApi(url, headers, setResApi, setIsLoading, setIsError, setMessage, devSwitch, otherApi)
}

const deleteApi = (url, headers, setResApi, setIsLoading, setIsError, setMessage, devSwitch, otherApi) => {
	requestDeleteApi(url, headers, setResApi, setIsLoading, setIsError, setMessage, devSwitch, otherApi)
}

export const useRequestApiEffect = ({
	url,
	headers = {},
	params = {},
	subscribes = [],
	method = 'GET',
	devSwitch = false,
	otherApi = false
}) => {
	const [resApi, setResApi] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [isError, setIsError] = useState(false)
	const [message, setMessage] = useState(null)
	const accessToken = useSelector(state => state.authUser.accessToken)

	useEffect(() => {
		// eslint-disable-next-line
		switch (method) {
			case 'GET':
				getApi(
					url,
					{ headers: { 'X-TOKEN': accessToken, ...headers }, ...params },
					setResApi,
					setIsLoading,
					setIsError,
					setMessage,
					devSwitch,
					otherApi
				)
				break
			case 'POST':
				postApi(
					url,
					{ headers: { 'X-TOKEN': accessToken, ...headers }, ...params },
					setResApi,
					setIsLoading,
					setIsError,
					setMessage,
					devSwitch,
					otherApi
				)
				break
			case 'DELETE':
				deleteApi(
					url,
					{ headers: { 'X-TOKEN': accessToken, ...headers }, ...params },
					setResApi,
					setIsLoading,
					setIsError,
					setMessage,
					devSwitch,
					otherApi
				)
				break
		}
		// eslint-disable-next-line
	}, [...subscribes])

	return { resApi, isLoading, isError, message, setMessage }
}

export const useRequestApiEffectOnce = ({
	url,
	headers = {},
	params = {},
	method = 'GET',
	devSwitch = false,
	otherApi = false
}) => {
	const [resApi, setResApi] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [isError, setIsError] = useState(false)
	const [message, setMessage] = useState(null)
	const accessToken = useSelector(state => state.authUser.accessToken)

	useEffectOnce(() => {
		// eslint-disable-next-line
		switch (method) {
			case 'GET':
				// getApi()
				getApi(
					url,
					{ headers: { 'X-TOKEN': accessToken, ...headers }, ...params },
					setResApi,
					setIsLoading,
					setIsError,
					setMessage,
					devSwitch,
					otherApi
				)
				break
			case 'POST':
				postApi(
					url,
					{ headers: { 'X-TOKEN': accessToken, ...headers }, ...params },
					setResApi,
					setIsLoading,
					setIsError,
					setMessage,
					devSwitch,
					otherApi
				)
				break
		}
	}, [])

	return { resApi, isLoading, isError, message, setMessage }
}
