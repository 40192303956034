import { useState } from 'react'
// import { useSelector } from 'react-redux'
import { addBtn } from '@components/UI/btns/conf-btns/add'
import { FormAddChildInfo } from './ChildInfo/FormAddChildInfo'
// import { delBtn } from '@components/UI/btns/conf-btns/delete'
import Title from '@components/UI/Title'
import btnWithModalForm from '@components/UI/HOC/btnWithModalForm'
// import FormDelete from '@components/UI/FormsReusable/FormDelete'
import Table from '@components/UI/Table'
import Card from '@components/UI/Card'
// import Message from '@components/UI/Message'
//translate
import { useTranslation } from 'react-i18next'

/*
    функция KindergartenPage отвечает за рендеринг страницы 'Электронная очередь в детский сад'
*/
const KindergartenPage = () => {
	const { t } = useTranslation()
	// const currentRole = useSelector(state => state.authUser.currentRole)
	// eslint-disable-next-line
	const [update, setUpdate] = useState({})

	const AddChildForm = btnWithModalForm(FormAddChildInfo, {
		title: t('pages.personal_data.queuing_for_kindergarten.button_add_child'),
		btn_text: t('pages.personal_data.queuing_for_kindergarten.button_add_child'),
		btn_icon: addBtn.btn_icon,
		btn_type: addBtn.btn_type,
		btn_classes: addBtn.btn_classes,
		btn_size: addBtn.btn_size,
		icon_size: addBtn.icon_size,
		updatable: true,
		set_update: () => setUpdate({}),
		dataBsTarget: 'ChildInfoForm'

	})

	// const DeleteChild = btnWithModalForm({}, {
	//         title: t('modal_window.title_modal_delete_data'),
	//         btn_type: delBtn.btn_type,
	//         btn_icon: delBtn.btn_icon,
	//         btn_size: delBtn.btn_size,
	//         icon_size: delBtn.icon_size,
	//         updatable: true,
	//         btn_tooltip_tippy: {status: true, content: t('modal_window.delete_title'), placement: 'top', theme: 'default'},
	//         set_update: () => setUpdate({})
	//     }
	// )

	const FullName = [
		{
			fullname: 'Фамилия Имя Отчество',
			birth_date: '11.02.2015',
			status: 'Принят'
		},
		{
			fullname: 'Фамилия Имя Отчество2',
			birth_date: '11.02.2006',
			status: 'Обрабатывется'
		},
		{
			fullname: 'Фамилия Имя Отчество3',
			birth_date: '11.02.2020',
			status: 'Подать заявку'
		}
	]

	const ChildTableContent = () => (
		<>
			<Card>
				<AddChildForm />
			</Card>
			{
				// (mockFullName && Array.isArray(mockFullName) && mockFullName.length > 0) ?
				<Card>
					<Table classes='!w-[50%]'>
						<thead>
							<tr>
								<th>{t('pages.personal_data.queuing_for_kindergarten.tables.0.columns.0')}</th>
								<th>{t('pages.personal_data.queuing_for_kindergarten.tables.0.columns.1')}</th>
								<th>{t('pages.personal_data.queuing_for_kindergarten.tables.0.columns.2')}</th>
							</tr>
						</thead>
						<tbody>
							{FullName.map((item, i) => (
								<tr key={i}>
									<td>{item.fullname}</td>
									<td>{item.birth_date}</td>
									<td>{item.status}</td>
								</tr>
							))}
						</tbody>
					</Table>
				</Card>
				// : <Message alert alert_status='secondary' key_message='errors.data_not_found'/>
			}
		</>
	)

	return (
		<div>
			<Title tag='h2' title={t('pages.personal_data.queuing_for_kindergarten.title_page')} />
			<ChildTableContent />
		</div>
	)
}

export default KindergartenPage
