import { useEffect, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { languageEnum } from '../../../helpers/enums'
import { NotFoundPage } from '../../NotFoundPage'
import LinkBlock from '@components/LinkBlock'
import GridWrapper from '@components/UI/GridWrapper'
import Title from '@components/UI/Title'
import ContentBlock from './ContentBlock'
import ResponseWithErrors from '@components/UI/HOC/ResponseWithErrors'
import MainLoader from '@components/Loaders/MainLoader'
import axios from 'axios'
//translate
import { useTranslation } from 'react-i18next'
//animate
import styled, { keyframes } from 'styled-components'
import { fadeIn } from 'react-animations'

const zoomInAnimation = keyframes`${fadeIn}`
const ZoomInDiv = styled.div`
	animation: ${p => p.timeAnimate}ms ${zoomInAnimation};
`

const classesGrid = ['grid-cols-1', 'gap-1', 'self-start', 'md:grid-cols-5', 'lg:col-start-1 lg:col-end-4']

function findAlias(arr, alias) {
	// eslint-disable-next-line
	return arr.reduce((a, item) => {
		if (a) return a
		if (item.url === alias) return item.submenu
		if (item.submenu) return findAlias(item.submenu, alias)
	}, null)
}

/*
    функция ContentPage отвечает за рендеринг динамических страниц,
    которые создаются в админке (на бекенде).
	В стейт pageContent записываются все данные по странице, которые парсятся 
	и распределяются по дочерним компонентам.
*/
const ContentPage = () => {
	const params = useParams()
	const [isLoading, setIsLoading] = useState(false)
	const [isError, setIsError] = useState(null)
	const [pageContent, setPageContent] = useState(null)
	const [pageMenu, setPageMenu] = useState(null)
	const sidebarData = useSelector(store => store.sidebar.sidebarData)
	//const accessToken = useSelector(store => store.authUser.accessToken)
	const { accessToken, currentRole } = useSelector(state => state.authUser)
	const { i18n, t } = useTranslation()
	const lang = languageEnum[i18n.language]

	const SERVER_API = process.env.REACT_APP_SERVER_API

	const urlApi = `${SERVER_API}/page/${params.alias}?token=${accessToken}&role=${currentRole}&lang=${lang}`

	const getPageApi = useMemo(async () => {
		try {
			setIsLoading(true)
			setIsError(null)
			const response = await axios.get(urlApi)
			//console.log(response)
			if (response.data.code === 200) {
				response.data?.data && (await setPageContent(response.data.data))
			} else {
				setIsError(response.data)
				setPageContent(null)
			}
			setIsLoading(false)
		} catch (error) {
			setIsError(t('errors.data'))
			setPageContent(null)
			setIsLoading(false)
		}
		// eslint-disable-next-line
	}, [urlApi])

	useEffect(() => {
		const fetchData = () => {
			if (typeof getPageApi === 'function') getPageApi()
		}
		if (params.alias || params.alias !== 'null') {
			fetchData()
		}
		// eslint-disable-next-line
	}, [params, lang, accessToken])

	useEffect(() => {
		setPageMenu(
			params.alias &&
				findAlias(
					sidebarData.flatMap(sidebar => sidebar.items),
					params.alias
				)
		)
	}, [params.alias, sidebarData])

	if (!params.alias || params.alias === 'null') return <NotFoundPage />

	return (
		<>
			{isLoading ? (
				<MainLoader />
			) : (
				<ZoomInDiv timeAnimate={400}>
					{pageContent ? (
						<>
							<Title tag='h1' title={pageContent?.title} className='title_page !text-[24px] sm:!text-[30px]' />
							{pageContent.blocks.map(({ type_id, content, config }, i) => (
								<ContentBlock key={i} type={type_id} content={content} config={config} />
							))}
						</>
					) : (
						<div className='mb-4'>
							<NotFoundPage />
						</div>
					)}
				</ZoomInDiv>
			)}
			{isError && <ResponseWithErrors {...isError} />}
			{/* pageMenu - формирует страницу с карточками подпунктами из бокового меню */}
			{pageMenu && pageMenu.length > 0 && (
				<div className='mt-3'>
					<GridWrapper classes={classesGrid}>
						{pageMenu.map((item, i) => {
							if (item?.url?.includes('http://') || item?.url?.includes('https://')) {
								return (
									<a key={i} href={item.url} className='link-block p-3 text-gray-500' target='_blank' rel='noreferrer'>
										{item.text}
									</a>
								)
							} else {
								return (
									<LinkBlock
										key={i}
										// сделать исключение для profile страница 'Мой профиль', тк это статическая страница,
										// чтобы путь был `profile` а не `page/profile`
										href={item.url === 'profile' ? '/profile' : `/page/${item.url}`}
										title={item.text}
										classes='h-[100%] p-3'
									/>
								)
							}
						})}
					</GridWrapper>
				</div>
			)}
		</>
	)
}

export default ContentPage
