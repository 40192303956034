import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { requestGetApi } from '@services/api/requestServerApi'
import Card from '@components/UI/Card'
import Title from '@components/UI/Title'
import Table from '@components/UI/Table'
import MainLoader from '@components/Loaders/MainLoader'
import Message from '@components/UI/Message'
import DownloadScan from './DownloadScan'
//translate
import { useTranslation } from 'react-i18next'

/*
    функция EmploymentHistoryProfilePage отвечает за рендеринг страницы 'Трудовая книжка'
*/
const EmploymentHistoryProfilePage = () => {
	const { t } = useTranslation()
	const [resApi, setResApi] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [isError, setIsError] = useState(false)
	const accessToken = useSelector(store => store.authUser.accessToken)

	const prepareData = async response => {
		// console.log(response)
		response.data.code === 200 ? setResApi(response?.data?.data) : setIsError(true)
	}

	useEffect(() => {
		requestGetApi(
			`user/employee/work-record`,
			{ headers: { 'X-TOKEN': accessToken } },
			prepareData,
			setIsLoading,
			setIsError
		)
		// eslint-disable-next-line
	}, [])

	const TableContent = ({ data }) =>
		data && Array.isArray(data) && data.length > 0 ? (
			<Table striped bordered>
				<thead>
					<tr>
						<th className='w-[20%]'>{t('pages.personal_data.employment_history.tables.0.columns.0')}</th>
						<th className='w-[20%]'>{t('pages.personal_data.employment_history.tables.0.columns.1')}</th>
						<th className='w-[20%]'>{t('pages.personal_data.employment_history.tables.0.columns.2')}</th>
						<th className='w-[20%]'>{t('pages.personal_data.employment_history.tables.0.columns.3')}</th>
						<th className='w-[10%]'>{t('pages.personal_data.employment_history.tables.0.columns.4')}</th>
						<th className='w-[10%]'>{t('pages.personal_data.employment_history.tables.0.columns.5')}</th>
					</tr>
				</thead>
				<tbody>
					{data.map((item, index) => (
						<tr key={`${index}_work`}>
							<td>{item.department}</td>
							<td>{item.job}</td>
							<td>{item.date_begin}</td>
							<td>{item.date_end}</td>
							<td>{item.category}</td>
							<td>{item.rate}</td>
						</tr>
					))}
				</tbody>
			</Table>
		) : (
			<Message alert alert_status='secondary' key_message='errors.data_not_found' />
		)

	const TablesContent = () => {
		return (
			<>
				{resApi.length > 0 &&
					resApi?.map(table => (
						<div key={table.id} className='mb-3'>
							<Title tag='h3' title={table.title} />
							<TableContent data={table.items} />
						</div>
					))}
			</>
		)
	}

	return (
		<>
			<Title tag='h2' title={t('pages.personal_data.employment_history.title_page')} />
			{resApi && resApi?.length > 0 ? (
				<Card>
					<DownloadScan />
				</Card>
			) : null}
			<Card>
				{isLoading ? <MainLoader /> : isError ? <Message key_message='errors.data_load' alert alert_status='danger'/> : <TablesContent />}
			</Card>
		</>
	)
}

export default EmploymentHistoryProfilePage
