import { useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import btnWithModalForm from '@components/UI/HOC/btnWithModalForm'
import Card from '@components/UI/Card'
import Title from '@components/UI/Title'
// import AlertMessage from '@components/UI/AlertMessage'
//translate
import { useTranslation } from 'react-i18next'
// plagin react-datepicker
import 'react-datepicker/dist/react-datepicker.css'
import { registerLocale } from 'react-datepicker'
import ru from 'date-fns/locale/ru'
import SaveBtn from '@components/UI/btns/SaveBtn'
import CloseBtn from '@components/UI/btns/CloseBtn'
import DatePickerInput from '@components/UI/ElementsReusable/DatePickerInput'
import SelectInput from '@components/UI/ElementsReusable/SelectInput'
import { addBtn } from '@components/UI/btns/conf-btns/add'
registerLocale('ru', ru)

const categoriesSport = [
	{
		id: 1,
		disabled: true,
		className: 'font-bold',
		value: 'disabled',
		title: 'Разряд',
		subCategories: [
			{
				id: 2,
				disabled: false,
				className: '',
				value: '0',
				title: 'МСМК'
			},
			{
				id: 3,
				disabled: false,
				className: '',
				value: '1',
				title: 'МС'
			},
			{
				id: 4,
				disabled: false,
				className: '',
				value: '2',
				title: 'КМС'
			},
			{
				id: 5,
				disabled: false,
				className: '',
				value: '3',
				title: '1'
			},
			{
				id: 6,
				disabled: false,
				className: '',
				value: '4',
				title: '2'
			}
		]
	},
	{
		id: 7,
		disabled: true,
		className: 'font-bold',
		value: 'disabled',
		title: 'Олимпийские игры',
		subCategories: [
			{
				id: 8,
				disabled: false,
				className: '',
				value: '5',
				title: '1 место'
			},
			{
				id: 9,
				disabled: false,
				className: '',
				value: '6',
				title: '2 место'
			},
			{
				id: 10,
				disabled: false,
				className: '',
				value: '7',
				title: '3 место'
			},
			{
				id: 11,
				disabled: false,
				className: '',
				value: '8',
				title: 'Участие'
			}
		]
	},
	{
		id: 12,
		disabled: true,
		className: 'font-bold',
		value: 'disabled',
		title: 'Чемпионат мира/Европы, общий зачёт кубка мира/Европы, Всемирные игры',
		subCategories: [
			{
				id: 13,
				disabled: false,
				className: '',
				value: '9',
				title: '1 место'
			},
			{
				id: 14,
				disabled: false,
				className: '',
				value: '10',
				title: '2 место'
			},
			{
				id: 15,
				disabled: false,
				className: '',
				value: '11',
				title: '3 место'
			},
			{
				id: 16,
				disabled: false,
				className: '',
				value: '12',
				title: '4-10 место'
			}
		]
	},
	{
		id: 17,
		disabled: true,
		className: 'font-bold',
		value: 'disabled',
		title: 'Кубок мира/Европы (этапы)',
		subCategories: [
			{
				id: 18,
				disabled: false,
				className: '',
				value: '13',
				title: '1 место'
			},
			{
				id: 19,
				disabled: false,
				className: '',
				value: '14',
				title: '2 место'
			},
			{
				id: 20,
				disabled: false,
				className: '',
				value: '15',
				title: '3 место'
			},
			{
				id: 21,
				disabled: false,
				className: '',
				value: '16',
				title: 'Участие 4-10 место'
			}
		]
	},
	{
		id: 22,
		disabled: true,
		className: 'font-bold',
		value: 'disabled',
		title: 'Всемирная Универсиада',
		subCategories: [
			{
				id: 23,
				disabled: false,
				className: '',
				value: '17',
				title: '1 место'
			},
			{
				id: 24,
				disabled: false,
				className: '',
				value: '18',
				title: '2 место'
			},
			{
				id: 25,
				disabled: false,
				className: '',
				value: '19',
				title: '3 место'
			},
			{
				id: 26,
				disabled: false,
				className: '',
				value: '20',
				title: '4-10 место'
			}
		]
	},
	{
		id: 27,
		disabled: true,
		className: 'font-bold',
		value: 'disabled',
		title: 'Первенство Мира/Европы',
		subCategories: [
			{
				id: 28,
				disabled: false,
				className: '',
				value: '21',
				title: '1 место'
			},
			{
				id: 29,
				disabled: false,
				className: '',
				value: '22',
				title: '2 место'
			},
			{
				id: 30,
				disabled: false,
				className: '',
				value: '23',
				title: '3 место'
			},
			{
				id: 31,
				disabled: false,
				className: '',
				value: '24',
				title: '4-10 место'
			}
		]
	},
	{
		id: 32,
		disabled: true,
		className: 'font-bold',
		value: 'disabled',
		title: 'Чемпионат России',
		subCategories: [
			{
				id: 33,
				disabled: false,
				className: '',
				value: '25',
				title: '1 место'
			},
			{
				id: 34,
				disabled: false,
				className: '',
				value: '26',
				title: '2 место'
			},
			{
				id: 35,
				disabled: false,
				className: '',
				value: '27',
				title: '3 место'
			},
			{
				id: 36,
				disabled: true,
				className: '',
				value: '28',
				title: '4-10 место'
			}
		]
	},
	{
		id: 37,
		disabled: true,
		className: 'font-bold',
		value: 'disabled',
		title: 'Первенство России',
		subCategories: [
			{
				id: 38,
				disabled: false,
				className: '',
				value: '29',
				title: '1 место'
			},
			{
				id: 39,
				disabled: false,
				className: '',
				value: '30',
				title: '2 место'
			},
			{
				id: 40,
				disabled: false,
				className: '',
				value: '31',
				title: '3 место'
			},
			{
				id: 41,
				disabled: false,
				className: '',
				value: '32',
				title: 'Участие 4-10 место'
			}
		]
	},
	{
		id: 42,
		disabled: true,
		className: 'font-bold',
		value: 'disabled',
		title: 'Всероссийская Универсиада',
		subCategories: [
			{
				id: 43,
				disabled: false,
				className: '',
				value: '33',
				title: '1 место'
			},
			{
				id: 44,
				disabled: false,
				className: '',
				value: '34',
				title: '2 место'
			},
			{
				id: 45,
				disabled: false,
				className: '',
				value: '35',
				title: '3 место'
			},
			{
				id: 46,
				disabled: false,
				className: '',
				value: '36',
				title: '4-10 место'
			}
		]
	},
	{
		id: 47,
		disabled: true,
		className: 'font-bold',
		value: 'disabled',
		title: 'Кубок России',
		subCategories: [
			{
				id: 48,
				disabled: false,
				className: '',
				value: '37',
				title: '1 место'
			},
			{
				id: 49,
				disabled: true,
				className: '',
				value: '38',
				title: '2 место'
			},
			{
				id: 50,
				disabled: false,
				className: '',
				value: '39',
				title: '3 место'
			},
			{
				id: 51,
				disabled: false,
				className: '',
				value: '40',
				title: '4-10 место'
			}
		]
	},
	{
		id: 52,
		disabled: true,
		className: 'font-bold',
		value: 'disabled',
		title: 'Соревнования федеральных округов, всероссийские соревнования',
		subCategories: [
			{
				id: 53,
				disabled: false,
				className: '',
				value: '41',
				title: '1 место'
			},
			{
				id: 54,
				disabled: false,
				className: '',
				value: '42',
				title: '2 место'
			},
			{
				id: 55,
				disabled: false,
				className: '',
				value: '43',
				title: '3 место'
			}
		]
	},
	{
		id: 56,
		disabled: true,
		className: 'font-bold',
		value: 'disabled',
		title: 'Городские и областные соревнования (Чемпионат, Первенство, Кубок)',
		subCategories: [
			{
				id: 57,
				disabled: false,
				className: '',
				value: '44',
				title: '1 место'
			},
			{
				id: 58,
				disabled: false,
				className: '',
				value: '45',
				title: '2 место'
			},
			{
				id: 59,
				disabled: false,
				className: '',
				value: '46',
				title: '3 место'
			}
		]
	},
	{
		id: 60,
		disabled: true,
		className: 'font-bold',
		value: 'disabled',
		title: 'Универсиада (город, область)',
		subCategories: [
			{
				id: 61,
				disabled: false,
				className: '',
				value: '47',
				title: '1 место'
			},
			{
				id: 62,
				disabled: true,
				className: '',
				value: '48',
				title: '2 место'
			},
			{
				id: 63,
				disabled: false,
				className: '',
				value: '49',
				title: '3 место'
			}
		]
	},
	{
		id: 64,
		disabled: true,
		className: 'font-bold',
		value: 'disabled',
		title: 'Спартакиада факультетов ВУЗов',
		subCategories: [
			{
				id: 65,
				disabled: false,
				className: '',
				value: '50',
				title: '1 место'
			},
			{
				id: 66,
				disabled: false,
				className: '',
				value: '51',
				title: '2 место'
			},
			{
				id: 67,
				disabled: false,
				className: '',
				value: '52',
				title: '3 место'
			}
		]
	},
	{
		id: 68,
		disabled: true,
		className: 'font-bold',
		value: 'disabled',
		title: 'Спартакиада ВУЗа (1 курс, студенты, общежития)',
		subCategories: [
			{
				id: 69,
				disabled: false,
				className: '',
				value: '53',
				title: '1 место'
			},
			{
				id: 70,
				disabled: false,
				className: '',
				value: '54',
				title: '2 место'
			},
			{
				id: 71,
				disabled: false,
				className: '',
				value: '55',
				title: '3 место'
			}
		]
	},
	{
		id: 72,
		disabled: true,
		className: 'font-bold',
		value: 'disabled',
		title: 'Выполнение разрядов',
		subCategories: [
			{
				id: 73,
				disabled: false,
				className: '',
				value: '56',
				title: 'МС, МСМК'
			},
			{
				id: 74,
				disabled: false,
				className: '',
				value: '57',
				title: 'КМС'
			},
			{
				id: 75,
				disabled: false,
				className: '',
				value: '58',
				title: '1 разряд'
			}
		]
	},
	{
		id: 76,
		disabled: true,
		className: 'font-bold',
		value: 'disabled',
		title: 'Выполнение норм ВФСК ГТО',
		subCategories: [
			{
				id: 77,
				disabled: false,
				className: '',
				value: '59',
				title: 'Золотой знак отличия'
			}
		]
	}
]

/*
    функция SportAwardsProfilePage отвечает за рендеринг страницы 'Спортивные достижения'
*/
const SportAwardsProfilePage = () => {
	const { t } = useTranslation()
	const [isSportAwards] = useState(true)
	// eslint-disable-next-line
	const [update, setUpdate] = useState({})

	const BtnAddSportAwardForm = btnWithModalForm(AddSportAwardForm, {
		title: t('pages.personal_data.sports_achivments.button_add_sport_award'),
		btn_text: t('pages.personal_data.sports_achivments.button_add_sport_award'),
		btn_icon: addBtn.btn_icon,
		btn_type: addBtn.btn_type,
		btn_classes: addBtn.btn_classes,
		btn_size: addBtn.btn_size,
		icon_size: addBtn.icon_size,
		set_update: () => setUpdate({}),
		dataBsTarget: 'SportAwardFormForm'
	})

	return (
		<Card>
			<Title tag='h2' title={t('pages.personal_data.sports_achivments.title_page')} />
			{isSportAwards ? (
				<div className='mb-3'>
					<BtnAddSportAwardForm />
				</div>
			) : (
				<p>У Вас пока нет записей в этой категории.</p>
			)}
			{/*<AlertMessage status='warning'>*/}
			{/*    <p className='my-2 text-[18px]'>{t('temp_information.temp_1')}</p>*/}
			{/*</AlertMessage>*/}
		</Card>
	)
}

const AddSportAwardForm = ({ apiUrl, handleCloseModal }) => {
	const { t } = useTranslation()
	const { i18n } = useTranslation()
	// eslint-disable-next-line
	let currentValueLang = i18n.language || 'ru'
	// eslint-disable-next-line
	const [isSuccess, setIsSuccess] = useState(false)

	// state react-hook-form
	const methods = useForm({ mode: 'onSubmit', defaultValues: {} })

	const {
		register,
		handleSubmit,
		control,
		formState: { errors }
	} = methods

	const onSubmit = data => {
		alert(JSON.stringify(data))
		// console.log(data)
	}

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className='modal-body'>
					<label className='flex justify-center text-[20px]'>
						{t('pages.personal_data.sports_achivments.modal_add_sport_achievement.basic_data')}
					</label>
					{/*<Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>*/}
					{/*    <Form.Label>{t('pages.personal_data.sports_achivments.modal_add_sport_achievement.category')}</Form.Label><span className='text-red-500'>*</span>*/}
					{/*    <Form.Select defaultValue={'default'} autoFocus {...register('list_awards')}>*/}
					{/*            { categoriesSport.map(item => (*/}
					{/*                <>*/}
					{/*                    <option*/}
					{/*                        key={item.id}*/}
					{/*                        value={item.value}*/}
					{/*                        disabled={item.disabled}*/}
					{/*                        className={item.className}*/}
					{/*                    >*/}
					{/*                        {item.title}*/}
					{/*                    </option>*/}
					{/*                    {item.subCategories.map(item => (*/}
					{/*                        <option*/}
					{/*                            key={item.id}*/}
					{/*                            value={item.value}*/}
					{/*                            disabled={item.disabled}*/}
					{/*                            className={item.className}*/}
					{/*                        >*/}
					{/*                            {item.title}*/}
					{/*                        </option>*/}
					{/*                    ))}*/}
					{/*                </>  */}
					{/*            ))}*/}
					{/*    </Form.Select>*/}
					{/*</Form.Group>*/}

					<SelectInput
						name='list_awards'
						label={t('pages.personal_data.sports_achivments.modal_add_sport_achievement.category')}
						defaultValue={'default'}
						wrapperClasses='mb-3'
						optionsData={categoriesSport.map(item => (
							<>
								<option key={item.id} value={item.value} disabled={item.disabled} className={item.className}>
									{item.title}
								</option>
								{item.subCategories.map(item => (
									<option key={item.id} value={item.value} disabled={item.disabled} className={item.className}>
										{item.title}
									</option>
								))}
							</>
						))}
						redStar
					/>

					<div className='mb-3'>
						<label>{t('pages.personal_data.sports_achivments.modal_add_sport_achievement.name')}</label>
						<span className='text-red-500'>*</span>
						<textarea
							class='form-control'
							rows={4}
							{...register('name_award', {
								required: t('valid_params.required'),
								minLength: {
									value: 5,
									message: t('valid_params.min_length5')
								},
								maxLength: {
									value: 100,
									message: t('valid_params.max_length100')
								}
							})}
							// isInvalid={errors.name_award}
						/>
						<div className='mb-1 text-red-600'>
							{errors?.name_award && <p>{errors?.name_award?.message || 'Ошибка!'}</p>}
						</div>
					</div>

					{/*<Form.Group className='mb-3'>*/}
					{/*    <Form.Label>{t('pages.personal_data.sports_achivments.modal_add_sport_achievement.event_date')}</Form.Label><span className='text-red-500'>*</span>*/}
					{/*    <Controller*/}
					{/*        control={control}*/}
					{/*        name='date_input'*/}
					{/*        defaultValue={null}*/}
					{/*        rules={{ required: t('valid_params.required') }}*/}
					{/*        render={({*/}
					{/*            field: { onChange, value },*/}
					{/*            fieldState: { error }*/}
					{/*        }) => (*/}
					{/*            <DatePicker*/}
					{/*                placeholderText={t('react_datepicker_lib.select_date')}*/}
					{/*                onChange={onChange}*/}
					{/*                selected={value}*/}
					{/*                dateFormat='dd-MM-yyyy'*/}
					{/*                maxDate={new Date()}*/}
					{/*                //filterDate={date => date.getDay() !== 6 && date.getDay() !== 0} // убираем выходные дни*/}
					{/*                isClearable*/}
					{/*                showYearDropdown*/}
					{/*                scrollableYearDropdown*/}
					{/*                scrollableMonthYearDropdown*/}
					{/*                yearDropdownItemNumber={50}*/}
					{/*                locale={currentValueLang === 'ru' ? 'ru': 'en'}*/}
					{/*                className={errors.date_input ? 'form-control is-invalid': 'form-control'}*/}
					{/*            />*/}
					{/*        )}*/}
					{/*    />*/}
					{/*    <div className = 'mb-1 text-red-600'>*/}
					{/*        {errors.date_input && <p>{errors?.date_input?.message || 'Ошибка!'}</p>}*/}
					{/*    </div>*/}
					{/*    <Form.Label>Укажите месяц и год проведения мероприятия (если не помните, укажите примерную дату)</Form.Label>*/}
					{/*</Form.Group>*/}

					<div className='mb-3'>
						<DatePickerInput
							name='date_input'
							label={t('pages.personal_data.sports_achivments.modal_add_sport_achievement.event_date')}
							rules={{ required: t('valid_params.required') }}
							placeholder={t('react_datepicker_lib.select_date')}
							maxDate={new Date()}
							isClearable
							scrollableYearDropdown={true}
							scrollableMonthYearDropdown={true}
							showYearDropdown={true}
							yearDropdownItemNumber={50}
							classes={errors.date_input ? 'form-control is-invalid' : 'form-control'}
							wrapperClasses
							redStar
						/>
						<label>Укажите месяц и год проведения мероприятия (если не помните, укажите примерную дату)</label>
					</div>
					<div className='mb-3'>
						<label>Кол-во участников</label>
						<span className='text-red-500'>*</span>
						<Controller
							control={control}
							name='number_input'
							defaultValue={null}
							rules={{ required: t('valid_params.required') }}
							render={({ field: { onChange, value }, fieldState: { error } }) => (
								<input
									type='number'
									onChange={e => {
										onChange(parseInt(e.target.value, 10))
									}}
									selected={value}
									className={errors.number_input ? 'form-control is-invalid' : 'form-control'}
								/>
							)}
						/>
						<div className='mb-1 text-red-600'>
							{errors.number_input && <p>{errors?.number_input?.message || 'Ошибка!'}</p>}
						</div>
					</div>
				</div>
				<div className='modal-footer'>
					{!isSuccess && (
						<SaveBtn type='submit' onClick={apiUrl}>
							{t('modal_window.save')}
						</SaveBtn>
					)}
					<CloseBtn onClick={handleCloseModal}>
						{t('modal_window.close')}
					</CloseBtn>
				</div>
			</form>
		</FormProvider>
	)
}

export default SportAwardsProfilePage
