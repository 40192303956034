/*
На вход компонента <Title /> поступают три пропса, где
    tag - тег родительского элемента Title. Если пропс будет пустым, выкинет ошибку 
    Принимает четыре значения: h1, h2, h3, h4
    title - текс заголовка; можно использовать функцию useTranslation(),
    чтобы заголовок можно было менять на другие языки.
    className - стили заголовка. По умолчанию принимает 'font-size: 32px'
        Пример использования компонета <Title />:
        <Title title = {t('personal_data.general_info')} className='text-[64px]'} />
*/
const Title = ({ tag, title, className = '' }) => {
	const Error = 'Error. Введите в компонент <PageTitle /> значение пропса "tag". Например: tag="h2" '
	switch (tag) {
		case 'h1':
			return <h1 className={`text-[30px] ${className}`}>{title}</h1>
		case 'h2':
			return <h2 className={`text-[22px] py-3 ${className}`}>{title}</h2>
		case 'h3':
			return <h3 className={`text-[18px] ${className}`}>{title}</h3>
		case 'h4':
			return <h4 className={`text-[16px] ${className}`}>{title}</h4>
		default:
			return (
				<div>
					<span className='text-rose-600'>Error</span>
					{console.warn(Error)}
				</div>
			)
	}
}

export default Title
