import Card from '@components/UI/Card'
import Title from '@components/UI/Title'
import AcademicDegree from './AcademicDegree'
import AcademicTitle from './AcademicTitle'
//translate
import { useTranslation } from 'react-i18next'

/*
    функция ScienceProfilePage отвечает за рендеринг страницы 'Наука'
*/
const ScienceProfilePage = () => {
	const { t } = useTranslation()
	return (
		<>
			<Title tag='h2' title={t('pages.personal_data.science.title_page')} />
			<Card>
				<AcademicDegree />
			</Card>
			<Card>
				<AcademicTitle />
			</Card>
		</>
	)
}

export default ScienceProfilePage
