import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	profilePages: [
		{
			title: 'pages.personal_data.title_page',
			icon: 'FaUserAlt',
			href: 'profile',
			pages: [
				{ title: 'pages.personal_data.general_info.title_page', icon: 'FaInfoCircle', href: 'info', visible: true },
				{ title: 'pages.personal_data.contacts.title_page', icon: 'FaAddressBook', href: 'contacts', visible: true },
				{ title: 'pages.personal_data.education.title_page', icon: 'FaUserGraduate', href: 'education', visible: true },
				{ title: 'pages.personal_data.online.title_page', icon: 'FaGlobeAsia', href: 'links-online', visible: false },
				{ title: 'pages.personal_data.science.title_page', icon: 'FaAtom', href: 'science', visible: true },
				{ title: 'pages.personal_data.awards.title_page', icon: 'FaAward', href: 'awards', visible: true },
				{ title: 'pages.personal_data.sports_achivments.title_page', icon: 'FaTrophy', href: 'sport', visible: false },
				{
					title: 'pages.personal_data.social_activity.title_page',
					icon: 'FaGuitar',
					href: 'social-activity',
					visible: false
				},
				{
					title: 'pages.personal_data.employment_history.title_page',
					icon: 'FaUserTie',
					href: 'employment-history',
					visible: true
				},
				{
					title: 'pages.personal_data.avatar_editor.title_page',
					icon: 'FaUserTie',
					href: 'avatar_editor',
					visible: false
				},
				{
					title: 'pages.personal_data.queuing_for_kindergarten.title_page',
					icon: 'FaRegCalendar',
					href: 'kindergarten',
					visible: false
				}
			],
			visible: true
		}
	]
}

const personalDataSlice = createSlice({
	// Префикс actions в Redux devtools браузера
	name: 'personalData',
	initialState: initialState,
	reducers: {}
})

const { reducer } = personalDataSlice

export default reducer

//export const {} = actions
