import Card from '@components/UI/Card'
import Title from '@components/UI/Title'
import SocialMedia from './SocialMedia'
import PersonalSite from './PersonalSite'
import WikipediaTPU from './WikipediaTPU'
import ScientificIdentifiers from './ScientificIdentifiers'
//translate
import { useTranslation } from 'react-i18next'

/*
    функция LinksOnlineProfilePage отвечает за рендеринг страницы 'Я в сети'
*/
const LinksOnlineProfilePage = () => {
	const { t } = useTranslation()

	return (
		<>
			<Title tag='h2' title={t('pages.personal_data.online.title_page')} />
			<Card>
				<SocialMedia />
			</Card>
			<Card>
				<PersonalSite />
			</Card>
			<Card>
				<WikipediaTPU />
			</Card>
			<Card>
				<ScientificIdentifiers />
			</Card>
		</>
	)
}

export default LinksOnlineProfilePage
