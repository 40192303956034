import React from 'react'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { toggle as toggleChange } from '../../../store/slices/sidebarSlice'
import Tippy from '@tippyjs/react'
import * as FontAwesome from 'react-icons/fa'
import './Sidebar.css'

/*
    Компонент SideBarItem создает пункты меню для компонента RecursiveMenu 
*/
const SideBarItem = ({
	url = '',
	icon,
	text = 'tooltip',
	classes = ['item_default'],
	submenu = false,
	toggle,
	hendlerToggleSubmenu
}) => {
	const classesSidebarIcon = ['item'].concat(classes)
	const FaIcon = icon && FontAwesome[icon]
	const dispatch = useDispatch()

	const handleClickLink = e => {
		if (window.innerWidth < 1279) {
			dispatch(toggleChange())
		}
	}

	/*
        Компонент SidebarLink создает ссылки для сайдбара
    */
	const SidebarLink = React.forwardRef((props, ref) => {
		if (url.includes('http://') || url.includes('https://')) {
			return (
				<a href={url} ref={ref} target='_blank' className={`link ${toggle || 'justify-center py-2'}`} rel='noreferrer'>
					<div>{FaIcon ? <FaIcon size={22} /> : null}</div>
					<div className={`${toggle ? 'description' : 'tooltip'} `}>{text}</div>
				</a>
			)
		} else {
			return (
				<NavLink
					ref={ref}
					// сделать исключение для profile страница 'Мой профиль', тк это статическая страница,
					// чтобы путь был `profile` а не `page/profile`
					to={ url === 'profile' ? `${url}` : `page/${url}`}
					className={`link ${toggle || 'justify-center py-2'}`}
					onClick={handleClickLink}
				>
					<div>{FaIcon ? <FaIcon size={22} /> : null}</div>
					<div className={`${toggle ? 'description' : 'tooltip'} `}>{text}</div>
				</NavLink>
			)
		}
	})

	return (
		<div className={classesSidebarIcon.join(' ')}>
			{url ? (
				!toggle ? (
					<Tippy content={text} placement='right' theme='default'>
						<SidebarLink />
					</Tippy>
				) : (
					<SidebarLink />
				)
			) : (
				<div className={`link no-url ${toggle || 'justify-center'}`} onClick={e => hendlerToggleSubmenu(e)}>
					<div>{FaIcon ? <FaIcon size={22} /> : null}</div>
					<div className={toggle ? 'description' : 'tooltip'}>{text}</div>
				</div>
			)}

			{submenu && toggle && (
				<div className='btn_toggle_submenu' onClick={e => hendlerToggleSubmenu(e)}>
					+
				</div>
			)}
		</div>
	)
}

export default SideBarItem
