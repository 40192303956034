import { useState } from 'react'
import { useSelector } from 'react-redux'
import { requestDeleteApi } from '@services/api/requestServerApi'
import MainLoader from '@components/Loaders/MainLoader'
import CloseBtn from '@components/UI/btns/CloseBtn'
import DeleteBtn from '@components/UI/btns/DeleteBtn'
import Message from '@components/UI/Message'
//translate
import { useTranslation } from 'react-i18next'

/*
    Универсальная модальная форма по удалению любых данных с сайта
*/
const FormDelete = ({ urlApi, handleCloseModal, onDelete, modalMessage = 'modal_window.title_modal_delete_data' }) => {
	const { t } = useTranslation()
	const [isSuccess, setIsSuccess] = useState(false)
	const [isLoaging, setIsLoading] = useState(false)
	// eslint-disable-next-line
	const [isError, setIsError] = useState(false)
	const [message, setMessage] = useState(t(modalMessage))
	const accessToken = useSelector(state => state.authUser.accessToken)

	const prepareDataDelete = response => {
		// console.log(response)
		if (response?.data?.code === 200) {
			setMessage(<Message alert alert_status='success' key_message='success.data_deleted'></Message>)
			setIsSuccess(true)
			// onDelete && onDelete() // закрываем модальное окно без оповещения об удалении TODO: а если это InputCustom?
			// удаляем затемнение от модалки, если закрываем модальное окно без оповещения об удалении
			// const element = document.querySelector('.modal-backdrop')
			// element.parentNode.removeChild(element)
		} else if (response?.data?.code === 404) {
			setIsError(true)
			// setMessage(<Message alert key_message='errors.data_not_found'>{response?.data?.message}</Message>)
		} else if (response?.data?.code === 403) {
			setIsError(true)
			setMessage(
				<Message alert key_message='errors.access_denied' alert_status='danger'>
					{response?.data?.message}
				</Message>
			)
		} else if (response?.data?.code === 500) {
			setIsError(true)
			setMessage(<Message alert key_message='errors.page_500' alert_status='danger' />)
		}
	}

	const handlDeleteApi = () => {
		requestDeleteApi(
			urlApi,
			{ headers: { 'X-TOKEN': accessToken } },
			prepareDataDelete,
			setIsLoading,
			setIsError,
			setMessage
		)
	}

	const ContentForm = () => {
		if (isLoaging) {
			return <MainLoader />
		} else {
			return message && <div className='modal-body'>{message}</div>
		}
	}

	return (
		<>
			<ContentForm />
			<div className='modal-footer'>
				{!isSuccess && (
					<DeleteBtn onClick={handlDeleteApi} icon={false}>
						{t('modal_window.yes')}
					</DeleteBtn>
				)}
				<CloseBtn onClick={handleCloseModal} icon={false}>
					{t('modal_window.close')}
				</CloseBtn>
			</div>
		</>
	)
}

export default FormDelete
