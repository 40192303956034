import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { FaBuilding, FaDoorOpen } from 'react-icons/fa'
import { requestGetApi, requestPostApi } from '@services/api/requestServerApi'
import MainLoader from '@components/Loaders/MainLoader'
import AlertMessage from '@components/UI/AlertMessage'
import SaveBtn from '@components/UI/btns/SaveBtn'
import CloseBtn from '@components/UI/btns/CloseBtn'
import SelectInput from '@components/UI/ElementsReusable/SelectInput'
import { FormProvider, useForm } from 'react-hook-form'
//translate
import { useTranslation } from 'react-i18next'

const FormChangeAddress = ({ handleCloseModal }) => {
	const { t } = useTranslation()
	const [corps, setCorps] = useState(0)
	const [room, setRoom] = useState(0)
	const accessToken = useSelector(state => state.authUser.accessToken)

	const [resApi, setResApi] = useState([])
	// eslint-disable-next-line
	const [isError, setIsError] = useState(false)
	// eslint-disable-next-line
	const [errorMessage, setErrorMessage] = useState(null)
	const [isLoading, setIsLoading] = useState(false)

	const [isSuccessUpdate, setIsSuccessUpdate] = useState(false)
	const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)
	const [isErrorUpdate, setIsErrorUpdate] = useState(false)

	const prepareData = response => {
		// console.log(response)
		if (response?.data?.code === 200) {
			Array.isArray(response?.data?.data) && response?.data?.data.length !== 0 && setResApi(response.data.data)
		} else {
			setIsError(true)
			setErrorMessage(response?.data)
		}
	}

	useEffect(() => {
		let isMounted = true
		if (isMounted) {
			requestGetApi(
				'tool/building/list',
				{ headers: { 'X-TOKEN': accessToken } },
				prepareData,
				setIsLoading,
				setIsError
			)
		}
		return () => {
			isMounted = false
		}
		// eslint-disable-next-line
	}, [])

	const handleSetCorps = e => {
		setCorps(e.target.value)
	}

	const handleSetRoom = e => {
		setRoom(e.target.value)
	}

	const prepareDataUpdateWorkPlace = response => {
		// console.log(response)
		if (response?.data?.code === 200 && response?.data?.data) {
			setIsSuccessUpdate(true)
		} else {
			setIsErrorUpdate(true)
		}
	}

	const handleSaveData = () => {
		if (room !== 0) {
			requestPostApi(
				'user/employee/workplace',
				new URLSearchParams({ room }),
				{
					headers: {
						'X-TOKEN': accessToken,
						'Content-Type': 'application/x-www-form-urlencoded'
					}
				},
				prepareDataUpdateWorkPlace,
				setIsLoadingUpdate,
				setIsErrorUpdate
			)
		}
	}

	const UpdateWorkPlaceContent = () => {
		if (isLoadingUpdate) {
			return <MainLoader />
		} else {
			if (!isErrorUpdate && isSuccessUpdate) {
				return <AlertMessage status='success'>{t('success.data_update')}</AlertMessage>
			}
			if (isErrorUpdate) {
				return <AlertMessage status='danger'>{t('errors.data_update')}</AlertMessage>
			}
		}
		return null
	}

	const methods = useForm({
		defaultValues: {
			select_office_input: null,
			select_room_input: null
		}
	})

	const { handleSubmit } = methods

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(handleSaveData)}>
				<div className='modal-body'>
					<UpdateWorkPlaceContent />
					{!isLoadingUpdate && (
						<>
							{!isSuccessUpdate && (
								<>
									<SelectOffice defaultVal={corps} resApi={resApi} isLoading={isLoading} onChange={handleSetCorps} />
									<SelectRoom defaultVal={corps} onChange={handleSetRoom} />
								</>
							)}
						</>
					)}
				</div>
				<div className='modal-footer'>
					{!isSuccessUpdate && <SaveBtn onClick={handleSaveData}>{t('modal_window.save')}</SaveBtn>}
					<CloseBtn onClick={handleCloseModal}>{t('modal_window.close')}</CloseBtn>
				</div>
			</form>
		</FormProvider>
	)
}

const SelectOffice = ({ defaultVal, resApi, isLoading, onChange }) => {
	const { t } = useTranslation()

	return (
		<SelectInput
			name='select_office_input'
			label={t('pages.personal_data.contacts.corps')}
			rules={{ required: t('valid_params.required') }}
			changeState={onChange}
			defaultValue={defaultVal}
			optionsData={
				resApi &&
				resApi.length > 0 &&
				resApi.map(item => (
					<option key={item.id} value={item.id}>
						{item.name}
					</option>
				))
			}
			inputGroup={
				isLoading ? (
					<MainLoader
						small={'spinner-border-sm'}
						// eslint-disable-next-line
						style={'text-dark'}
					/>
				) : (
					<FaBuilding />
				)
			}
			wrapperClasses='mb-2'
			redStar
		/>
	)
}

const SelectRoom = ({ defaultVal, onChange }) => {
	const { t } = useTranslation()
	const [resApi, setResApi] = useState([])
	const [isLoading, setLoading] = useState(false)
	// eslint-disable-next-line
	const [isError, setError] = useState(false)

	const accessToken = useSelector(state => state.authUser.accessToken)

	const prepareData = response => {
		//console.log(response)
		if (response?.data?.code === 200) {
			Array.isArray(response?.data?.data) && response?.data?.data.length !== 0 && setResApi(response.data.data)
		}
	}

	useEffect(() => {
		let isMounted = true
		if (isMounted) {
			defaultVal &&
				requestGetApi(
					`tool/building/room?building=${defaultVal}`,
					{ headers: { 'X-TOKEN': accessToken } },
					prepareData,
					setLoading,
					setError
				)
		}

		return () => {
			isMounted = false
		}
		// eslint-disable-next-line
	}, [defaultVal])

	return (
		<SelectInput
			name='select_room_input'
			label={t('pages.personal_data.contacts.placement')}
			defaultValue={defaultVal}
			inputGroup={
				isLoading ? (
					<MainLoader
						small={'spinner-border-sm'}
						// eslint-disable-next-line
						style={'text-dark'}
					/>
				) : (
					<FaDoorOpen />
				)
			}
			rules={{ required: t('valid_params.required') }}
			changeState={onChange}
			wrapperClasses='mb-2'
			disabled={!!!defaultVal}
			optionsData={
				resApi &&
				resApi.length > 0 &&
				resApi.map(item => (
					<option key={item.id} value={item.id}>
						{item.name}
					</option>
				))
			}
			redStar
		/>
	)
}

export default FormChangeAddress
