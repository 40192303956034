import { useEffect, useState } from 'react'
import AlertMessage from '@components/UI/AlertMessage'

/*
    Компонент WidgetComponent рендерит динамические данные для виджета (из компонента ContentBlock), 
	который приходит с бекэнда
*/
function WidgetComponent({ name, config = {} }) {
	const [ModalComponent, setModalComponent] = useState(null)
	const [isError, setError] = useState(false)

	const loadModalComponent = async () => {
		try {
			const loadResult = await import(`../../../widgets/${name}`)
			setModalComponent(() => loadResult.default)
			setError(false)
		} catch (error) {
			setError(true)
			return null
		}
	}

	useEffect(() => {
		loadModalComponent()
		// eslint-disable-next-line
	}, [])

	return ModalComponent ? (
		<ModalComponent config={config} />
	) : (
		isError && <AlertMessage status='danger'>Error! Not Found Widget: {name}</AlertMessage>
	)
}

export default WidgetComponent
