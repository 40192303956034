import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { requestGetApi } from '@services/api/requestServerApi'
import MainLoader from '@components/Loaders/MainLoader'
import AlertMessage from '@components/UI/AlertMessage'
import Card from '@components/UI/Card'
import FileSaver from 'file-saver'
//icons
import { FaFile } from 'react-icons/fa'
//translate
import { useTranslation } from 'react-i18next'

/*
    функция InfoFile подтягивает данные по api 'Личный листок учета кадров'
*/
const InfoFile = () => {
	const { t } = useTranslation()
	const [resApi, setResApi] = useState(null)
	const [isError, setIsError] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [isLoadFile, setLoadFile] = useState(false)
	const accessToken = useSelector(store => store.authUser.accessToken)

	const prepareData = async response => {
		if (response?.data?.code === 404 || response?.headers['content-type'] === 'application/json') {
			setIsError(true)
			setIsLoading(false)
		} else {
			await setResApi(response)
		}
	}

	const getFile = async () => {
		await requestGetApi(
			`user/employee/leaflet`,
			{ headers: { 'X-TOKEN': accessToken }, responseType: 'blob' },
			prepareData,
			setIsLoading,
			setIsError
		)
	}

	useEffect(() => {
		resApi && handlerSave()
		// eslint-disable-next-line
	}, [resApi])

	const handlerSave = () => {
		// console.log(resApi)
		if (resApi) {
			const blob = new Blob([resApi?.data], { type: 'application/rtf' })
			// console.log(blob)
			FileSaver.saveAs(blob, 'InfoFile.rtf')
		}
	}

	const handlLoadFile = () => {
		setLoadFile(true)
		getFile()
	}

	const FileComponent = () => {
		if (isLoading) {
			return (
				<Card>
					<button type='button' className='green-button btn btn-success btn-sm' disabled>
						<MainLoader
							small='spinner-border-sm'
							// eslint-disable-next-line
							style={'none'}
							classes='mr-2'
						/>
						{t('pages.personal_data.general_info.file')}
					</button>
				</Card>
			)
		} else {
			if (isError) {
				return <AlertMessage status='warning'>{t('errors.not_found_file')}</AlertMessage>
			} else {
				return (
					resApi && (
						<Card>
							<button type='button' onClick={handlerSave} className='green-button btn btn-success btn-sm'>
								<FaFile size='20' className='mr-2' />
								{t('pages.personal_data.general_info.file')}
							</button>
						</Card>
					)
				)
			}
		}
	}

	return !isLoadFile ? (
		<>
			<Card>
				<button type='button' onClick={handlLoadFile} className='green-button btn btn-success btn-sm'>
					<FaFile size='20' className='mr-2' />
					{t('pages.personal_data.general_info.file')}
				</button>
			</Card>
		</>
	) : (
		<FileComponent />
	)
}

export default InfoFile
