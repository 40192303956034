import './Message.css'
//translate
import { useTranslation } from 'react-i18next'

/*
    Компонент Message отображает разные сообщения при взаимодействии с сервером
*/
const Message = ({
	key_message = '',
	alert_status = 'danger' | 'secondary' | 'primary' | 'success' | 'warning' | 'light' | 'dark',
	variant = 'danger' | 'secondary' | 'primary' | 'success' | 'warning' | 'light' | 'dark',
	alert = true,
	classes = 'my-2',
	children
}) => {
	const { t } = useTranslation()
	const key_arr = key_message.split('.')
	// console.log(key_arr)
	const message = {
		errors: {
			dev_section: t('dev_section'), //Раздел в разработке
			page_404: t('errors.404'), //Страница не найдена
			page_429: t('errors.429'), //Много запросов к серверу
			page_500: t('errors.500'), //Ошибка сервера
			data_load: t('errors.data'), //Ошибка загрузки данных
			data_update: t('errors.data_update'),
			data_not_found: t('errors.data_not_found'),
			session_dined: t('errors.session_dined'),
			load_file: t('errors.load_file'),
			not_found_file: t('errors.not_found_file'),
			server: t('errors.server'),
			access_denied: t('errors.access_denied')
		},
		success: {
			data_send: t('success.data_send'),
			data_update: t('success.data_update'),
			data_deleted: t('success.data_deleted')
		}
	}

	function getValueByKey(object, arr) {
		let val = object
		arr.forEach(element => (val = val[element]))
		return val
	}

	function alerts() {
		if (alert_status === 'secondary') {
			return (
				<div
					className={`alert
						${classes}
						${alert_status && `alert-secondary`}
						${variant && `alert-secondary`}
					`}
				>
					<p>{getValueByKey(message, key_arr)}</p>
					{children}
				</div>
			)
		}

		if (alert_status === 'success') {
			return (
				<div
					className={`alert
						${classes}
						${alert_status && `alert-success`}
						${variant && `alert-success`}
					`}
				>
					<p>{getValueByKey(message, key_arr)}</p>
					{children}
				</div>
			)
		}

		if (alert_status === 'danger') {
			return (
				<div
					className={`alert
						${classes}
						${alert_status && `alert-danger`}
						${variant && `alert-danger`}
					`}
				>
					<p>{getValueByKey(message, key_arr)}</p>
					{children}
				</div>
			)
		}

		if (alert_status === 'warning') {
			return (
				<div
					className={`alert
						${classes}
						${alert_status && `alert-warning`}
						${variant && `alert-warning`}
					`}
				>
					<p>{getValueByKey(message, key_arr)}</p>
					{children}
				</div>
			)
		}

		if (alert_status === 'primary') {
			return (
				<div
					className={`alert
						${classes}
						${alert_status && `alert-primary`}
						${variant && `alert-primary`}
					`}
				>
					<p>{getValueByKey(message, key_arr)}</p>
					{children}
				</div>
			)
		}

		if (alert_status === 'dark') {
			return (
				<div
					className={`alert
						${classes}
						${alert_status && `alert-dark`}
						${variant && `alert-dark`}
					`}
				>
					<p>{getValueByKey(message, key_arr)}</p>
					{children}
				</div>
			)
		}

		if (alert_status === 'light') {
			return (
				<div
					className={`alert
						${classes}
						${alert_status && `alert-light`}
						${variant && `alert-light`}
					`}
				>
					<p>{getValueByKey(message, key_arr)}</p>
					{children}
				</div>
			)
		}

		return <></>
	}

	return alert ? (
		alerts()
	) : (
		// <div className={`alert
		// ${classes}
		// ${alert_status && alert-secondary && alert-danger && alert-success && alert-warning && alert-primary}
		// ${variant && alert-secondary && alert-danger && alert-success && alert-warning && alert-primary}
		// `}>
		//   <p>{getValueByKey(message, key_arr)}</p>
		//   {children}
		// </div>
		// <Alert variant={alert_status} className={${classes}}>
		//   <p>{getValueByKey(message, key_arr)}</p>
		//   {children}
		// </Alert>
		<>{getValueByKey(message, key_arr)}</>
	)
}

export default Message
