/*
	Компонент Overlay выступает оберткой компоненту LogoTpu
*/
const Overlay = ({ children }) => {
	return (
		<div className='h-[100vh] w-[100vw] fixed top-0 left-0 flex items-center justify-center z-[100] bg-black/[.06]'>
			{children}
		</div>
	)
}

export default Overlay
