import './Card.css'

/*
На вход компонента <Card /> поступают два пропса, где 
    classes - стили css
    children - принимает текст данного сообщения (или html код)
        Пример использования компонета <Card />:
        <Card classes='mb-3' >
            *текст (html код) данного сообщения*
        </Card>
*/
const Card = ({ classes, children }) => {
	return <div className={`card mb-3 ${classes}`}>{children}</div>
}

export default Card
