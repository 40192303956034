import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import Input from '@components/UI/ElementsReusable/Input'
import DatePickerInput from '@components/UI/ElementsReusable/DatePickerInput'
import SaveBtn from '@components/UI/btns/SaveBtn'
import CloseBtn from '@components/UI/btns/CloseBtn'
import SelectInput from '@components/UI/ElementsReusable/SelectInput'

export const FormAddChildInfo = ({ handleCloseModal }) => {
	const { t } = useTranslation()
	// eslint-disable-next-line
	const [isSuccess, setIsSuccess] = useState(false)

	// state react-hook-form
	const methods = useForm({
		defaultValues: {
			child_surname_input: null,
			child_name_input: null,
			child_patronymic_input: null,
			child_birth_date_input: null,
			relation_degree_select: null
		}
	})

	const { handleSubmit } = methods

	const onSubmit = data => {
		console.log(data)
		handleCloseModal()
		// reset('', {
		//     keepValues: false,
		//     keepDefaultValues: true,
		// })
	}

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className='modal-body'>{!isSuccess && <ChildData />}</div>
				<div className='modal-footer'>
					{!isSuccess && (
						<SaveBtn type='submit' onClick={handleSubmit(onSubmit)}>
							{t('modal_window.save')}
						</SaveBtn>
					)}
					<CloseBtn onClick={handleCloseModal}>
						{t('modal_window.close')}
					</CloseBtn>
				</div>
			</form>
		</FormProvider>
	)
}

export const ChildData = () => {
	const { t } = useTranslation()

	// const [childSurname, setChildSurname] = useState('')
	// const [childName, setChildName] = useState('')
	// const [childPatronymic, setChildPatronymic] = useState('')

	const relationDegree = [
		{
			value: '1',
			name: t('pages.personal_data.queuing_for_kindergarten.form_add_child.son')
		},
		{
			value: '2',
			name: t('pages.personal_data.queuing_for_kindergarten.form_add_child.daughter')
		},
		{
			value: '3',
			name: t('pages.personal_data.queuing_for_kindergarten.form_add_child.granddaughter')
		},
		{
			value: '4',
			name: t('pages.personal_data.queuing_for_kindergarten.form_add_child.grandson')
		}
	]

	return (
		<>
			<Input
				name='child_surname_input'
				label={t('pages.personal_data.queuing_for_kindergarten.form_add_child.surname')}
				rules={{
					required: t('valid_params.required'),
					pattern: {
						value: /^[a-zA-Zа-яА-ЯёЁ]+(?:[-\s][a-zA-Zа-яА-ЯёЁ]+)*$/,
						message: t('valid_params.wrong_param')
					}
				}}
				redStar
				// changeState={(e) => console.log(e.target.value)}
			/>
			<Input
				name='child_name_input'
				label={t('pages.personal_data.queuing_for_kindergarten.form_add_child.name')}
				rules={{
					required: t('valid_params.required'),
					pattern: {
						value: /^[a-zA-Zа-яА-ЯёЁ]+(?:[-\s][a-zA-Zа-яА-ЯёЁ]+)*$/,
						message: t('valid_params.wrong_param')
					}
				}}
				redStar
				// changeState={(e) => console.log(e.target.value)}
			/>
			<Input
				name='child_patronymic_input'
				label={t('pages.personal_data.queuing_for_kindergarten.form_add_child.patronymic')}
				rules={{
					required: t('valid_params.required'),
					pattern: {
						value: /^[a-zA-Zа-яА-ЯёЁ]+(?:[-\s][a-zA-Zа-яА-ЯёЁ]+)*$/,
						message: t('valid_params.wrong_param')
					}
				}}
				redStar
				// changeState={(e) => console.log(e.target.value)}
			/>
			<DatePickerInput
				name='child_birth_date_input'
				label={t('pages.personal_data.queuing_for_kindergarten.form_add_child.birth_date')}
				rules={{ required: t('valid_params.required') }}
				placeholder={t('react_datepicker_lib.select_date')}
				redStar
			/>
			<SelectInput
				name='relation_degree_select'
				label={t('pages.personal_data.queuing_for_kindergarten.form_add_child.relation_degree')}
				rules={{ required: t('valid_params.required') }}
				optionsData={relationDegree.map((item, index) => (
					<option value={item.value} key={index}>
						{item.name}
					</option>
				))}
				redStar
			/>
		</>
	)
}
