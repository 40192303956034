import { httpRequest } from '@services/http/httpRequest.js'
import { fetching, fetchingError, fetchingSuccess } from '../slices/staffDataSlice'

const SERVER_API = process.env.REACT_APP_SERVER_API_YII2_TEMP
const API_KEY = process.env.REACT_APP_API_KEY

export const getDataStaff = accessToken => async dispatch => {
	const url = `${SERVER_API}/portal/employee/card?apiKey=${API_KEY}&token=${accessToken}`
	try {
		dispatch(fetching())
		const response = await httpRequest({ url })
		// console.log(response)

		if (response.code && response.code !== 200) {
			throw new Error('Ошибка загрузки данных')
		}
		dispatch(fetchingSuccess(response.data))
	} catch (err) {
		//console.log(err)
		dispatch(fetchingError(err.message))
	}
}
