import './LogoTpu.css'

/*
    Анимированный спинер в виде лого ТПУ
*/
const LogoTpu = ({
	fullPage = true,
	widthBox = '1',
	heighBox = '1',
	className = '',
	animate = false,
	hoverAnimate = false
}) => {
	const styleSizeBox = {
		'--width_box': widthBox + 'rem',
		'--height_box': heighBox + 'rem'
	}

	return (
		<div className={`logo_component ${className}`} style={styleSizeBox}>
			<div className='logo_box'>
				<div className={`container box_position_1 ${animate && 'animation_1'} ${hoverAnimate && 'animation-hover_1'}`}>
					<div className='back side'></div>
					<div className='left side dark:bg-white'></div>
					<div className='right side'></div>
					<div className='top side'></div>
					<div className='bottom side'></div>
					<div className='front side'></div>
				</div>
				<div className={`container box_position_1 ${animate && 'animation_1'} ${hoverAnimate && 'animation-hover_1'}`}>
					<div className='back side'></div>
					<div className='left side dark:bg-white'></div>
					<div className='right side'></div>
					<div className='top side'></div>
					<div className='bottom side'></div>
					<div className='front side'></div>
				</div>
				<div className={`container box_position_1 ${animate && 'animation_1'} ${hoverAnimate && 'animation-hover_1'}`}>
					<div className='back side'></div>
					<div className='left side dark:bg-white'></div>
					<div className='right side'></div>
					<div className='top side'></div>
					<div className='bottom side'></div>
					<div className='front side'></div>
				</div>
				<div className={`container box_position_2 ${animate && 'animation_2'} ${hoverAnimate && 'animation-hover_2'}`}>
					<div className='back side'></div>
					<div className='left side dark:bg-white'></div>
					<div className='right side'></div>
					<div className='top side'></div>
					<div className='bottom side'></div>
					<div className='front side dark:bg-white'></div>
				</div>
				<div className={`container box_position_1 ${animate && 'animation_1'} ${hoverAnimate && 'animation-hover_1'}`}>
					<div className='back side'></div>
					<div className='left side dark:bg-white'></div>
					<div className='right side'></div>
					<div className='top side'></div>
					<div className='bottom side'></div>
					<div className='front side'></div>
				</div>
				<div className={`container box_position_2 ${animate && 'animation_2'} ${hoverAnimate && 'animation-hover_2'}`}>
					<div className='back side'></div>
					<div className='left side dark:bg-white'></div>
					<div className='right side'></div>
					<div className='top side'></div>
					<div className='bottom side'></div>
					<div className='front side dark:bg-white'></div>
				</div>
				<div className={`container box_position_2 ${animate && 'animation_2'} ${hoverAnimate && 'animation-hover_2'}`}>
					<div className='back side'></div>
					<div className='left side dark:bg-white'></div>
					<div className='right side'></div>
					<div className='top side'></div>
					<div className='bottom side'></div>
					<div className='front side dark:bg-white'></div>
				</div>
				<div className={`container box_position_1 ${animate && 'animation_1'} ${hoverAnimate && 'animation-hover_1'}`}>
					<div className='back side'></div>
					<div className='left side dark:bg-white'></div>
					<div className='right side'></div>
					<div className='top side'></div>
					<div className='bottom side'></div>
					<div className='front side'></div>
				</div>
				<div className={`container box_position_2 ${animate && 'animation_2'} ${hoverAnimate && 'animation-hover_2'}`}>
					<div className='back side'></div>
					<div className='left side dark:bg-white'></div>
					<div className='right side'></div>
					<div className='top side'></div>
					<div className='bottom side'></div>
					<div className='front side dark:bg-white'></div>
				</div>
			</div>
		</div>
	)
}

export default LogoTpu
