import React from 'react'
import { useMemo } from 'react'
import WidgetComponent from './WidgetComponent'
import Card from '@components/UI/Card'

/*type_id:
    1:content(html)
    2:form(object)    
    3:widgets(text)
*/

/*
	Компонент ContentBlock рендерит динамические данные по страницам (из компонента ContentPage), 
	которые приходят с бекэнда.
*/
const ContentBlock = ({ type, content, config }) => {
	const renderHTML = rawHTML => React.createElement('div', { dangerouslySetInnerHTML: { __html: rawHTML } })

	const getContent = useMemo(() => {
		switch (type) {
			case '1':
				return <Card>{renderHTML(content)}</Card>
			case '2':
				return <div>Form</div>
			case '3':
				return (
					<Card>
						<WidgetComponent name={content} config={config} />
					</Card>
				)
			default:
				return null
		}
		// eslint-disable-next-line
	}, [type, content])

	return <div className='mt-2'>{getContent}</div>
}

export default ContentBlock
