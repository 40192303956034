import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	articleList: [],
	articlesLoadingStatus: 'idle'
}

const articlesSlice = createSlice({
	// Префикс actions в Redux devtools браузера
	name: 'articles',
	initialState: initialState,
	reducers: {
		articlesFetching: state => {
			state.articleList = []
			state.articlesLoadingStatus = 'loading'
		},
		articlesFetched: {
			reducer: (state, action) => {
				state.articleList = action.payload
				state.articlesLoadingStatus = 'idle'
			},
			prepare: articles => ({ payload: articles })
		},
		articlesFetchingError: state => {
			state.articleList = []
			state.articlesLoadingStatus = 'error'
		}
	}
})

const { actions, reducer } = articlesSlice

export default reducer

export const { articlesFetching, articlesFetched, articlesFetchingError } = actions
