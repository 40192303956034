import { Link } from 'react-router-dom'
import Title from '@components/UI/Title'
import LogoTpu from '@components/LogoTpu'
//translate
import { useTranslation } from 'react-i18next'

/*
    функция NotFoundPage отвечает за рендеринг страницы 404
*/
const NotFoundPage = () => {
	const { t } = useTranslation()

	return (
		<div className='flex flex-column items-center justify-center my-4'>
			<LogoTpu className='mb-4' widthBox='2' heighBox='2' hoverAnimate={true} />
			<Title tag='h1' title='404 Page Not Found' className='font-bold mb-0' />
			<Title tag='h2' title={t('errors.404')} className='mb-0 pb-2' />
			<Link to='/'>{t('links.backToHomepage')}</Link>
		</div>
	)
}

export { NotFoundPage }
