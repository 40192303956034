import axios from 'axios'
import { useState, useRef } from 'react'
import AvatarEditor from 'react-avatar-editor'
import { useSelector } from 'react-redux'
import AlertMessage from '@components/UI/AlertMessage'
import { useAccessParams } from '../../hooks/useAccessParams'
//translate
import { useTranslation } from 'react-i18next'

/*
    Компонет ImageEditor работает с редактированием изображения пользователя
*/
const ImageEditor = ({ photo_type_id, width, height }) => {
	const { t } = useTranslation()

	const [image, setImage] = useState('')
	const [changedPhotoResponse, setChangedPhotoResponse] = useState(false)
	const [scaleValue, setScaleValue] = useState(1)
	const [isLoading, setIsLoading] = useState(false)
	const [isError, setIsError] = useState(false)
	const { accessParams } = useAccessParams()

	const lichnost_id = useSelector(store => store.authUser.user.lichnost)

	const editorRef = useRef(null)

	const profileImageCgange = fileChangeEvent => {
		const file = fileChangeEvent.target.files[0]
		const { type } = file
		if (type === 'image/jpeg' || type === 'image/jpg' || type === 'image/png') {
			setImage(file)
		}
	}

	const profileImageSave = async editor => {
		if (editor) {
			const canvasScaled = editorRef.current.getImageScaledToCanvas().toDataURL()
			try {
				setIsLoading(true)
				setIsError(false)
				const response = await axios.put(`${process.env.REACT_APP_SERVER_API_TPU}/lichnost/foto?${accessParams}`, {
					person_id: lichnost_id,
					person_photo_base64: canvasScaled,
					comment: 'Фото загружено через ЛК',
					photo_type_id: photo_type_id
				})
				if (response.status !== 200) {
					throw new Error('Ошибка ответа сервера.')
				}
				setIsLoading(false)
				setIsError(false)
				setImage('')
				setChangedPhotoResponse(true)
			} catch (error) {
				setIsLoading(false)
				setIsError(true)
				console.log(error)
			}
		}
	}

	const handlerCancel = () => {
		setImage('')
	}

	const profileImageScale = scaleValueEvent => {
		const scaleValue = parseFloat(scaleValueEvent.target.value)
		setScaleValue(scaleValue)
	}

	return (
		<>
			{isError && <AlertMessage status='danger'>{t('errors.load_file')}</AlertMessage>}
			{!changedPhotoResponse && (
				<input
					className='form-control'
					type='file'
					accept='image/png, image/jpg, image/jpeg'
					onChange={profileImageCgange}
				/>
			)}
			{isLoading && (
				<div className='spinner-border text-secondary' role='status'>
					<span className='visually-hidden'>Loading...</span>
				</div>
			)}
			{image && (
				<div className='mt-3'>
					<AvatarEditor
						ref={editorRef}
						image={image}
						width={width}
						height={height}
						border={50}
						color={[200, 200, 200, 0.6]} // RGBA
						scale={scaleValue}
						rotate={0}
					/>
					<input
						type='range'
						className='mt-2 form-range'
						value={scaleValue}
						min='1'
						max='5'
						style={{ width: '220px' }}
						onChange={profileImageScale}
						step='0.1'
					></input>
					<div>
						<button type='button' className='btn btn-success mt-2 btn btn-primary' onClick={profileImageSave}>
							Сохранить
						</button>
						<button type='button' className='btn btn-secondary mt-2 ml-2 btn btn-primary' onClick={handlerCancel}>
							Отменить
						</button>
					</div>
				</div>
			)}
			{changedPhotoResponse && (
				<AlertMessage status={'success'}>{t('pages.personal_data.avatar_editor.response')}</AlertMessage>
			)}
		</>
	)
}

export default ImageEditor
