import { useSelector } from 'react-redux'

// хук получает параметры accessToken
export const useAccessParams = () => {
	const API_KEY = process.env.REACT_APP_API_KEY
	const accessToken = useSelector(store => store.authUser.accessToken)
	const accessParams = accessToken ? `access_token=${accessToken}&apiKey=${API_KEY}` : `apiKey=${API_KEY}`

	return { accessParams }
}
