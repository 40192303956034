import { configureStore } from '@reduxjs/toolkit'
import authUserSlice from './slices/authUserSlice'
import articlesSlice from './slices/employee/articlesSlice'
import sidebarSlice from './slices/sidebarSlice'
import personalDataSlice from './slices/personalDataPagesSlice'
import studentDataSlice from './slices/studentDataSlice'
import staffDataSlice from './slices/staffDataSlice'
import staffWorkInfoDataSlice from './slices/staffWorkInfoDataSlice'

export const store = configureStore({
	reducer: {
		authUser: authUserSlice,
		articles: articlesSlice,
		sidebar: sidebarSlice,
		staffData: staffDataSlice,
		staffWorkInfoData: staffWorkInfoDataSlice,
		studentData: studentDataSlice,
		personalData: personalDataSlice
	},
	middleware: getDefaultMiddleware => getDefaultMiddleware(),
	devTools: process.env.NODE_ENV !== 'production'
})
