import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
//icons
import { FaHome } from 'react-icons/fa'
import './Breadcrumbs.css'
//translate
import { useTranslation } from 'react-i18next'

/*
На вход компонента <Breadcrumbs /> поступают один пропс, где 
    listLinks - массив ссылок для breadcrumbs
    relativePath - относительный путь url. ОБЯЗАТЕЛЬНО СТАВИТЬСЯ В НАЧАЛЕ И В КОНЦЕ ПУТИ СЛЕШ!
    isHome - Нужна ли ссылка-иконка 'Домой'

    Пример использования компонета <Breadcrumbs />:
    <Breadcrumbs listLinks={profilePages} relativePath='/page/'/> 
*/
const Breadcrumbs = ({ listLinks = [], relativePath, isHome = true }) => {
	const { t } = useTranslation()
	const location = useLocation()
	// const navigate = useNavigate()
	let pathnames = location.pathname
		.split('/')
		.filter(el => el)
		.filter(el => el !== 'page')
	const findItemNested = (arr, itemId, nestingKey) =>
		// eslint-disable-next-line
		arr.reduce((a, item) => {
			if (a) return a
			if (item.href === itemId) return item
			if (item[nestingKey]) return findItemNested(item[nestingKey], itemId, nestingKey)
		}, null)

	return (
		<>
			<nav aria-label='breadcrumb'>
				<ul className='breadcrumbs pl-0'>
					{isHome && (
						// <button type='button' onClick={() => navigate('/')}>
						// 	<li className='breadcrumbs__item'>
						// 		<FaHome />
						// 	</li>
						// </button>
						<Link to={`/`}>
							<li className='breadcrumbs__item'>
								<FaHome />
							</li>
						</Link>
					)}
					{pathnames.map((pathname, index) => {
						const routeTo = `${relativePath}${pathnames.slice(0, index + 1).join('/')}`
						const pathName = findItemNested(listLinks, pathname, 'pages')
						const Button =
							pathName === undefined ? ( // устраняет баг при нажатии на ссылку в сайдбаре
								<div key={`${index}_list`}></div>
							) : (
								// <button
								// 	type='button'
								// 	onClick={() => {
								// 		pathnames.length - 1 !== index ? navigate(routeTo) : <></>
								// 	}}
								// 	key={`${index}_list`}
								// >
								// 	<li className={`breadcrumbs__item ${pathnames.length - 1 === index && 'is-active'}`}>
								// 		<span>{t(pathName.title)}</span>
								// 	</li>
								// </button>
								<Link to={`${pathnames.length - 1 !== index ? routeTo : '#'}`} key={`${index}_list`}>
									<li className={`breadcrumbs__item ${pathnames.length - 1 === index && 'is-active'}`}>
										<span>{t(pathName.title)}</span>
									</li>
								</Link>
							)
						return Button
					})}
				</ul>
			</nav>
		</>
	)
}

export default Breadcrumbs
