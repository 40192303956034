// /* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	data: null,
	fetchStudentDataStatus: 'idle'
}

const studentDataSlice = createSlice({
	name: 'studentData',
	initialState: initialState,
	reducers: {
		fetching: (state, action) => {
			state.fetchStudentDataStatus = 'loading'
		},

		fetchingError: {
			reducer: (state, action) => {
				state.fetchStudentDataStatus = action.payload
			},
			prepare: strError => {
				return {
					payload: strError || 'Произошла ошибка!!!'
				}
			}
		},

		fetchingSuccess: {
			reducer: (state, action) => {
				state.fetchStudentDataStatus = 'idle'
				state.data = action.payload
			},
			prepare: studentData => {
				return { payload: studentData }
			}
		},

		removeData: (state, action) => {
			state.fetchStudentDataStatus = 'idle'
			state.data = null
		}
	}
})

const { actions, reducer } = studentDataSlice

export const { fetching, fetchingError, fetchingSuccess, removeData } = actions

export default reducer
