import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { requestGetApi } from '@services/api/requestServerApi'
import MainLoader from '@components/Loaders/MainLoader'
import AlertMessage from '@components/UI/AlertMessage'
import FileSaver from 'file-saver'
//icons
import { FaFile } from 'react-icons/fa'
//translate
import { useTranslation } from 'react-i18next'

/*
    функция DownloadScan подтягивает данные по api скан трудовой книжки пользователя
*/
const DownloadScan = () => {
	const { t } = useTranslation()
	const [resApi, setResApi] = useState(null)
	const [isError, setIsError] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [isLoadFile, setLoadFile] = useState(false)
	const accessToken = useSelector(store => store.authUser.accessToken)

	const prepareData = async response => {
		if (response?.data?.code === 404 || response?.headers['content-type'] === 'application/json') {
			setIsError(true)
			setIsLoading(false)
		} else {
			await setResApi(response)
		}
	}

	const getFile = async () => {
		await requestGetApi(
			`user/employee/work-record-file`,
			{ headers: { 'X-TOKEN': accessToken }, responseType: 'blob' },
			prepareData,
			setIsLoading,
			setIsError
		)
	}

	useEffect(() => {
		resApi && handlerSave()
		// eslint-disable-next-line
	}, [resApi])

	const handlerSave = () => {
		// console.log(resApi)
		if (resApi) {
			const blob = new Blob([resApi?.data], { type: 'application/pdf' })
			// console.log(blob)
			FileSaver.saveAs(blob, 'Document scan.pdf')
		}
	}

	const handlLoadFile = () => {
		setLoadFile(true)
		getFile()
	}

	const FileComponent = () => {
		if (isLoading) {
			return (
				<button type='button' className='green-button btn btn-success btn-sm' disabled>
					<MainLoader
						small={'spinner-border-sm'}
						// eslint-disable-next-line
						style={'none'}
						classes='mr-2'
					/>
					{t('pages.personal_data.employment_history.scan')}
				</button>
			)
		} else {
			if (isError) {
				return (
					<AlertMessage status='warning' classes='mb-2'>
						{t('errors.not_found_file')}
					</AlertMessage>
				)
			} else {
				return (
					resApi && (
						<button type='button' onClick={handlerSave} className='green-button btn btn-success btn-sm'>
							<FaFile size='20' className='mr-2' /> {t('pages.personal_data.employment_history.scan')}
						</button>
					)
				)
			}
		}
	}

	return !isLoadFile ? (
		<button type='button' className='green-button btn btn-success btn-sm' onClick={handlLoadFile}>
			<FaFile size='20' className='mr-2' /> {t('pages.personal_data.employment_history.scan')}
		</button>
	) : (
		<FileComponent />
	)
}

export default DownloadScan
