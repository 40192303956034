const authEnum = {
	accessToken: 'accessToken',
	refreshToken: 'refreshToken',
	user: 'user',
	currentRole: 'currentRole',
	anotherUser: 'anotherUser'
}

const userRoleEnum = {
	guest: {
		id: 0,
		name: { ru: 'Гость', en: 'Guest' }
	},
	student: {
		id: 1,
		name: { ru: 'Студент', en: 'Student' }
	},
	staff: {
		id: 2,
		name: { ru: 'Сотрудник', en: 'Staff' }
	}
}

const languageEnum = {
	ru: 97,
	en: 12
}

export { authEnum, userRoleEnum, languageEnum }
