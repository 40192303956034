import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
//translate
import { useTranslation } from 'react-i18next'

// plagin react-datepicker
import DatePicker, { CalendarContainer } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { registerLocale } from 'react-datepicker'
import ru from 'date-fns/locale/ru'
registerLocale('ru', ru)

/*
    компонент DatePickerInput отвечает за валидирующий инпут с датой из библиотеки DatePicker

    На вход компонента <DatePickerInput /> поступают четыре пропса, где 
        name - имя компонента. Обязательно должен быть уникальным для одной формы!
        label - тайтл данного инпута
        defaultValue - дефолтное значение
        rules - правила по которому будет проходить валидация
        placeholder - плэйсхолдер инпута
        changeState - данный пропс передает функцию, которая, в свою очередь, отслеживает стейты данного инпута. Данные
        стейты, в свою очередь, изменяют ключи в глобальном контексте. Это сделано для того, чтобы отслеживать заполнены 
        были инпуты данной формы или нет, чтобы при заполнение одной формы автоматически появлялась для заполнения другая форма.
        Пример можно посмотреть здесь: 'src\widgets\MyProjectsCkp\MyProjects\subpages\AddProject\index.jsx'
        По дефолту changeState идет как пустая функция
            Пример использования компонета <DatePickerInput />:
            <DatePickerInput
                name='date_start_input'
                label={t('widgets.my_projects_ckp.my_projects.add_project.project_start_date')}
                placeholder={t('react_datepicker_lib.select_date')}
                changeState={(e) => {changeState(e, setDateValue_1)}}
            />
*/
const DatePickerInput = ({
	name,
	wrapperClasses = 'mb-2 flex flex-col',
	labelClasses = 'mb-1',
	classes,
	label,
	defaultValue = null,
	rules,
	placeholder = 'react_datepicker_lib.select_date',
	disabled,
	startDate,
	endDate,
	minDate = null,
	maxDate = null,
	dateFormat = 'dd.MM.yyyy',
	isClearable = true,
	showDisabledMonthNavigation = false,
	showYearDropdown = false,
	yearDropdownItemNumber,
	scrollableYearDropdown = false,
	scrollableMonthYearDropdown = false,
	closeOnScroll = false,
	typeCal = '',
	redStar,
	changeState = () => {}
}) => {
	const { t } = useTranslation()
	const { i18n } = useTranslation()
	let currentValueLang = i18n.language || 'ru'

	const [date, setDate] = useState(defaultValue) // стейт для даты
	const {
		control,
		formState: { errors }
	} = useFormContext()
	const errorMessage = errors[name] ? errors[name].message : null

	// функция по отображению popup по выбора месяца
	const renderMonthContent = (shortMonth, longMonth) => {
		const tooltipText = `Tooltip for month: ${longMonth}`
		return <span title={tooltipText}>{shortMonth}</span>
	}

	return (
		<>
			<div className={`${wrapperClasses}`}>
				<label className={`form-label ${labelClasses}`}>
					{label}
					{redStar ? <span className='text-red-500'>*</span> : false}
				</label>
				<Controller
					control={control}
					name={name}
					defaultValue={defaultValue}
					rules={rules}
					render={({ field, fieldState: { error } }) => {
						const WrappedDatePicker = ({ className, children }) => {
							return (
								<div style={{ background: 'dark:border-gray-500' }}>
									<CalendarContainer className={className}>
										<div style={{ position: 'relative' }}>{children}</div>
									</CalendarContainer>
								</div>
							)
						}
						return (
							<>
								{typeCal === 'mouth' ? (
									<DatePicker
										placeholderText={t(placeholder)}
										disabled={disabled}
										onChange={e => {
											field.onChange(e)
											changeState(e)
											setDate(e)
										}}
										selected={date ? date : field.value} // если используется дефолтное значение
										dateFormat={dateFormat}
										isClearable={isClearable}
										locale={currentValueLang === 'ru' ? 'ru' : 'en'}
										className={
											error
												? `form-control form-control-sm is-invalid ${classes}`
												: `form-control form-control-sm ${classes}`
										}
										calendarClassName='dark-datepicker'
										calendarContainer={WrappedDatePicker}
										renderMonthContent={renderMonthContent}
										showMonthYearPicker
										minDate={minDate}
										maxDate={maxDate}
									/>
								) : (
									<DatePicker
										placeholderText={t(placeholder)}
										disabled={disabled}
										onChange={e => {
											field.onChange(e)
											changeState(e)
											setDate(e)
										}}
										selected={date ? date : field.value} // если используется дефолтное значение
										dateFormat={dateFormat}
										isClearable={isClearable}
										locale={currentValueLang === 'ru' ? 'ru' : 'en'}
										className={
											error
												? `form-control form-control-sm is-invalid ${classes}`
												: `form-control form-control-sm ${classes}`
										}
										calendarClassName='dark-datepicker'
										calendarContainer={WrappedDatePicker}
										closeOnScroll={closeOnScroll}
										minDate={minDate}
										maxDate={maxDate}
										startDate={startDate}
										endDate={endDate}
										showYearDropdown={showYearDropdown}
										scrollableYearDropdown={scrollableYearDropdown}
										scrollableMonthYearDropdown={scrollableMonthYearDropdown}
										showDisabledMonthNavigation={showDisabledMonthNavigation}
										yearDropdownItemNumber={yearDropdownItemNumber}
									/>
								)}
							</>
						)
					}}
				/>
				<div className='my-1 text-red-600'>
					<small>{errorMessage && <p>{errors[name].message || 'Ошибка!'}</p>}</small>
				</div>
			</div>
		</>
	)
}

export default DatePickerInput
