import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { userRoleEnum } from '../../../helpers/enums'
import ProfileCard from '@components/Profile/ProfileCard'
import Title from '@components/UI/Title'
import Breadcrumbs from '@components/UI/Breadcrumbs'
import Card from '@components/UI/Card'
// animation css
import styled, { keyframes } from 'styled-components'
import { fadeInRight, fadeInUp } from 'react-animations'
//translate
import { useTranslation } from 'react-i18next'

// animation css
const FadeInRight = styled.div`
	animation: 0.8s ${keyframes`${fadeInRight}`};
`
const FadeInUp = styled.div`
	animation: 0.8s ${keyframes`${fadeInUp}`};
`

/*
    функция ProfilePage отвечает за работу и рендеринг всех компонентов со страницы 'Личные данные'
*/
const ProfilePage = () => {
	const { t, i18n } = useTranslation()
	const { user, currentRole } = useSelector(state => state.authUser)
	const profilePages = useSelector(state => state.personalData.profilePages)
	const staffData = useSelector(state => state.staffData.data)
	const studentData = useSelector(state => state.studentData.data)

	const userInfo = {
		image: `https://staff.tpu.ru/person/photo/${user?.lichnost}`,
		name: `${user?.name?.last[`${i18n.language}`]} ${user?.name?.first[`${i18n.language}`]} ${
			user?.name?.middle[`${i18n.language}`]
		}`,
		email: `${user?.email}`,
		lichnostId: `${user?.lichnost}`
	}

	const renderHTML = rawHTML => React.createElement('div', { dangerouslySetInnerHTML: { __html: rawHTML } })

	const UserDataComponent = () => {
		switch (currentRole) {
			case userRoleEnum.student.id:
				if (studentData && studentData.length > 0) {
					return (
						<ul className='list-disc mb-2 pl-5'>
							{studentData?.map((item, i) => {
								return (
									<li key={i}>
										{item.group?.last?.title && `${item.group?.last?.title} | ${item.department?.last?.title}`}
									</li>
								)
							})}
						</ul>
					)
				}
				break
			case userRoleEnum.staff.id:
				if (staffData?.work?.length > 0) {
					return (
						<ul className='list-disc mb-2 pl-5'>
							{staffData?.work?.map((item, i) => (
								<li key={i}>{`${item.department_name}, ${item.position}`}</li>
							))}
						</ul>
					)
				}
				break
			default:
				return null
		}
	}

	return (
		<>
			<Helmet>
				<title>ТПУ - ЛК | Данные пользователя</title>
			</Helmet>
			<div className='profile-page'>
				<Title tag='h1' title={t('pages.personal_data.title_page')} className='!text-[24px] sm:!text-[30px]' />
				<FadeInRight>
					<div className='mb-3'>
						<ProfileCard userInfo={userInfo} activeComponent={UserDataComponent && <UserDataComponent />} />
					</div>
				</FadeInRight>

				<div className='mb-3'>
					<Breadcrumbs listLinks={profilePages} relativePath='/' />
				</div>

				<Outlet />
				<FadeInUp>
					<Card classes='mt-3 p-3'>{renderHTML(t('pages.personal_data.alert_block'))}</Card>
				</FadeInUp>
			</div>
		</>
	)
}

export default ProfilePage
