import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n
	// Подключение бэкенда i18next
	.use(Backend)
	// Автоматическое определение языка
	.use(LanguageDetector)
	// модуль инициализации
	.use(initReactI18next)
	.init({
		// react: {
		//     useSuspense: false //   <---- this will do the magic
		// },
		// Стандартный язык
		fallbackLng: 'ru',
		whitelist: ['ru', 'en'],
		debug: false,
		// Распознавание и кэширование языковых кук
		detection: {
			order: ['localStorage', 'cookie', 'htmlTag', 'path', 'subdomain'],
			caches: ['localStorage']
		},
		interpolation: {
			escapeValue: false
		}
	})

export default i18n
