import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import Footer from '@components/Dashboard/Footer'
import Sidebar from '@components/Dashboard/Sidebar'
import TopNavigation from '@components/Dashboard/TopNavigation'
import AlertMessage from '@components/UI/AlertMessage'

/*
    функция MainDashboardLayout рендерит все компоненты личного кабинета. Главная обертка сайта
*/
const MainDashboardLayout = () => {
	const anotherUser = useSelector(state => state.authUser.anotherUser)

	return (
		<>
			{anotherUser === 2 && (
				<AlertMessage status='warning' classes='mb-0'>
					Внимание! Вы вошли в личный кабинет с другой учетной записью. По завершении работы не забудьте выйти из нее.
				</AlertMessage>
			)}
			<div className='flex h-screen'>
				<Sidebar />
				<div className='flex relative overflow-y-auto flex-1 bg-g_color_4 dark:bg-gray-800 dark:text-gray-100'>
					<TopNavigation />
					<div className='flex w-full flex-col relative'>
						<div className='mt-[70px] mx-2 pb-10 grow'>
							<Outlet />
						</div>
						<Footer />
					</div>
				</div>
			</div>
		</>
	)
}

export default MainDashboardLayout
