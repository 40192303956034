import { useState, useEffect } from 'react'
import { useRequestApiEffect } from '../../../../../hooks/useRequestApi'
import { addBtn } from '@components/UI/btns/conf-btns/add'
import InputCustom from '@components/UI/InputCustom'
import btnWithModalForm from '@components/UI/HOC/btnWithModalForm'
import Title from '@components/UI/Title'
import FormAddEmail from './FormAddEmail'
import MainLoader from '@components/Loaders/MainLoader'
import Message from '@components/UI/Message'
//icons
import { FaEnvelope } from 'react-icons/fa'
//translate
import { useTranslation } from 'react-i18next'

/*
    функция AdditionalEmails выводит данные по emails
*/
const AdditionalEmails = () => {
	const { t } = useTranslation()
	const [data, setData] = useState([])
	const [error, setError] = useState(false)
	const [update, setUpdate] = useState({})

	const { resApi, isLoading, message, setMessage } = useRequestApiEffect({
		url: 'user/employee/email',
		subscribes: [update]
	})

	const prepareData = response => {
		// console.log(response)
		if (response?.data?.code === 200) {
			Array.isArray(response?.data?.data) && response?.data?.data.length !== 0 && setData(response.data.data)
		} else if (response.data.code === 429) {
			setError(true)
			setMessage(<Message key_message='errors.page_429' alert />)
		} else {
			setError(true)
			setMessage(<Message key_message='errors.data_load' alert />)
		}
	}

	useEffect(() => {
		resApi && prepareData(resApi)
		// eslint-disable-next-line
	}, [resApi, update])

	const EmailForm = btnWithModalForm(FormAddEmail, {
		title: t('pages.personal_data.contacts.button_add_email'),
		btn_text: t('pages.personal_data.contacts.button_add_email'),
		btn_icon: addBtn.btn_icon,
		btn_type: addBtn.btn_type,
		btn_classes: addBtn.btn_classes,
		btn_size: addBtn.btn_size,
		icon_size: addBtn.icon_size,
		updatable: true,
		set_update: () => setUpdate({}),
		dataBsTarget: 'EmailForm'
	})

	const handleUpdate = () => {
		setUpdate({})
	}

	const Content = () =>
		data && Array.isArray(data) && data.length > 0 ? (
			data.map(item => (
				<InputCustom
					key={item.id}
					initialValue={item.email}
					isDeletable
					deleteApi={`user/employee/email/${item.id}`}
					onDeleteApi={() => setData(data.filter(d => d.id !== item.id))}
					updatable={true}
					set_update={handleUpdate}
				>
					<span className='input-group-text !text-[14px]'>
						<FaEnvelope size={20} />
					</span>
				</InputCustom>
			))
		) : (
			<Message alert alert_status='secondary' key_message='errors.data_not_found' />
		)

	return (
		<>
			<Title tag='h3' title={t('pages.personal_data.contacts.emails')} />
			<EmailForm />
			{isLoading ? <MainLoader /> : error ? <div>{message}</div> : <Content />}
		</>
	)
}

export default AdditionalEmails
