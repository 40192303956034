import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Img from '@components/UI/Img'
import LogoTpu from '@components/LogoTpu'
import SideBarItem from './SidebarItem'
import Card from '@components/UI/Card'
import MainLoader from '@components/Loaders/MainLoader'
//Tippy
import Tippy from '@tippyjs/react'
//Styles
import './Sidebar.css'
//Language
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
//Icons
import { BsArrowBarLeft } from 'react-icons/bs'
//Redux
import { toggle } from '../../../store/slices/sidebarSlice'
import { NavLink, useParams } from 'react-router-dom'
import { fetchSidebarData } from '../../../store/actions/sidebarAction'
//service
import { languageEnum } from '../../../helpers/enums'

// функция выполняет сворачивание дерево меню
const hendlerToggleSubmenu = e => {
	const btnToggleSubmenu = e.target
	const subMenu = btnToggleSubmenu.parentElement.nextElementSibling

	if (subMenu?.classList?.contains('submenu_active')) {
		subMenu.classList.remove('submenu_active')
	} else {
		subMenu?.classList && subMenu.classList.add('submenu_active')
	}
}

/*
    Компонент ConstructMenu создает дерево меню, вместе со всеми вложенностями
*/
function ConstructMenu({ toggle }) {
	const { sidebarData, fetchSidebarStatus } = useSelector(state => state.sidebar)

	if (fetchSidebarStatus === 'loading') {
		return <MainLoader />
	} else {
		return sidebarData.map((submenu, index) => (
			<ul key={index} className='pl-0'>
				<span className='categories'>
					{toggle ? submenu.submenuName : <hr className='border-gray-300 dark:border-gray-600' />}
				</span>
				<RecursiveMenu items={submenu.items} toggle={toggle} />
			</ul>
		))
	}
}

/*
    Компонент RecursiveMenu создает вложенное дерево меню в компоненте ConstructMenu 
*/
const RecursiveMenu = ({ items, toggle }) => {
	const { alias } = useParams()
	return items.map(({ url, icon, text, submenu = [] }, index) => {
		const isSubMenu = submenu.length > 0
		return (
			<li key={`${index}`}>
				<SideBarItem
					url={url}
					icon={icon}
					text={text}
					submenu={isSubMenu}
					toggle={toggle}
					hendlerToggleSubmenu={hendlerToggleSubmenu}
				/>
				{isSubMenu > 0 && toggle ? (
					<ul className={`submenu pl-0 ${submenu.find(item => item.url === alias) && 'submenu_active'}`}>
						<RecursiveMenu items={submenu} toggle={toggle} />
					</ul>
				) : null}
			</li>
		)
	})
}

/*
    Kомпонент Sidebar отображает левую панель сайта (в котором находится информация о пользователе и дерево меню)
*/
const Sidebar = () => {
	const { user, accessToken, currentRole } = useSelector(state => state.authUser)
	const { i18n } = useTranslation()
	const lang = languageEnum[i18n.language]
	const [touchPosition, setTouchPosition] = useState(null)

	const toggleSidebar = useSelector(state => state.sidebar.toggleSidebar)
	const dispatch = useDispatch()

	useEffect(
		() => {
			//console.log(1)
			dispatch(fetchSidebarData(accessToken, currentRole, lang))
		},
		// eslint-disable-next-line
		[lang, currentRole]
	)

	// функция выполняет переход ссылки на новоую страницу
	const handleClickLink = e => {
		if (window.innerWidth < 1279) {
			dispatch(toggle())
		}
	}

	// функция отвечает за работу тачскрина на девайсах (начало закрытия сайдбара на девайсах)
	const handleTouchStart = e => {
		const touchDown = e.touches[0].clientX
		setTouchPosition(touchDown)
	}

	// функция отвечает за работу тачскрина на девайсах (конец закрытия сайдбара на девайсах)
	const handleTouchEnd = e => {
		const touchDown = touchPosition
		if (touchDown === null) {
			return
		}
		const currentTouch = e.changedTouches[0].clientX
		const diff = touchDown - currentTouch
		if (diff > 5) {
			dispatch(toggle())
		}
		setTouchPosition(null)
	}

	return (
		<>
			<SidebarStyled toggleSidebar={toggleSidebar} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
				<div className='flex justify-between pb-2'>
					<NavLink to='/' onClick={handleClickLink} className='flex items-center gap-3' aria-label="Лого ТПУ ссылка на главную страницу">
						<LogoTpu className='pt-[5px]' widthBox='0.66' heighBox='0.67' animate={false} hoverAnimate={true} />
						<div className="!font-['TPUFontBold'] leading-6 uppercase text-[18px] pt-1">
							{t('tpu.title_1')}
							<br />
							<span className="!font-['TPUFontBold'] text-black dark:!text-white">{t('tpu.title_2')}</span>
						</div>
					</NavLink>
					<BsArrowBarLeft
						className={`btn_close_sidebar ${toggleSidebar || 'hidden'} sm:block xs:block md:block lg:block xl:hidden`}
						onClick={() => dispatch(toggle())}
					/>
				</div>

				<Card
					classes={
						toggleSidebar
							? 'info-panel mb-1 p-2 info-panel_hidden'
							: 'info-panel !p-0.5 !rounded-full info-panel_hidden'
					}
				>
					<div className='info-panel__avatar'>
						<Tippy content={t('popup-menu.personal_data')} placement='right' theme='default'>
							<NavLink to='/profile' onClick={handleClickLink}>
								<Img
									src={`https://staff.tpu.ru/person/photo/${user?.lichnost}`}
									classes={['w-[45px]', 'h-[45px]', 'rounded-full', 'border', 'object-cover']}
								/>
							</NavLink>
						</Tippy>
					</div>
					{toggleSidebar ? (
						<>
							<div className='info-panel__name'>
								<NavLink to='/profile'>
									<span>
										{`${user?.name?.last[`${i18n.language}`]} ${user?.name?.first[`${i18n.language}`]} ${
											user?.name?.middle[`${i18n.language}`]
										}`}
									</span>
								</NavLink>
							</div>
						</>
					) : (
						''
					)}
				</Card>
				<ConstructMenu toggle={toggleSidebar} lang={i18n.language} />
			</SidebarStyled>
			<div
				className={`absolute w-100 h-100 z-20 xl:hidden ${!toggleSidebar && 'hidden'} bg-black opacity-50`}
				onClick={handleClickLink}
			></div>{' '}
			{/* Wrapper for Mobile version */}
		</>
	)
}

/*
    компонент SidebarStyled создает обертку со стилями для сайдбара
*/
const SidebarStyled = props => {
	const classNames = 'sidebar relative z-10 ' + (props.toggleSidebar ? 'sidebar__toggle_open' : 'sidebar__toggle_close')
	return <div className={classNames}>{props.children}</div>
}

export default Sidebar
