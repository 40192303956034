import { authEnum } from './enums.js'

const storage = (key, data = null) => {
	if (data) {
		localStorage.setItem(key, JSON.stringify(data))
		return null
	}
	return JSON.parse(localStorage.getItem(key))
}

const saveAuthToStorage = newState => {
	// Если newState - null: Удалить значения из localStorage
	if (newState === null) {
		localStorage.removeItem(authEnum.accessToken)
		localStorage.removeItem(authEnum.refreshToken)
		localStorage.removeItem(authEnum.user)
		localStorage.removeItem(authEnum.currentRole)
		localStorage.removeItem(authEnum.anotherUser)
	}
	// Иначе, установить значения в localStorage
	else {
		// Страшно :))
		//storage(authEnum.accessToken, newState.accessToken);
		storage(authEnum.refreshToken, newState.refreshToken)
		storage(authEnum.user, newState.user)
		storage(authEnum.currentRole, newState.currentRole)
		storage(authEnum.anotherUser, newState.anotherUser)
	}
}

//*******************************************************************

export { storage, saveAuthToStorage }
