import { useSelector } from 'react-redux'
import Title from '@components/UI/Title'
// icons
import { FaUserAlt } from 'react-icons/fa'
//translate
import { useTranslation } from 'react-i18next'

/*
    Функция Account выводит домен пользователя tpu.ru
*/
const Account = () => {
	const { t } = useTranslation()
	const user = useSelector(state => state.authUser.user)
	
	return (
		<>
			<Title tag='h3' title={t('pages.personal_data.contacts.account')} />
			<div className='input-group'>
				<span className='input-group-text'>
					<FaUserAlt size={20} />
				</span>
				<input
					className='form-control form-control-sm'
					disabled
					placeholder={user?.email}
					value={user?.email}
					aria-label='Email'
				/>
				<a
					href='https://user.tpu.ru'
					target='_blank'
					rel='noreferrer'
					className='dark:text-white text-white py-[6px] px-[12px] rounded-r-[5px] green-button'
				>
					<small>{t('pages.personal_data.contacts.button_change_password')}</small>
				</a>
			</div>
		</>
	)
}

export default Account
