import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { requestGetApi } from '@services/api/requestServerApi'
import { ShowDataBtn } from '@components/UI/btns/conf-btns/show-data-btn'
import Table from '@components/UI/Table'
import MainLoader from '@components/Loaders/MainLoader'
import Message from '@components/UI/Message'
import btnWithModalForm from '@components/UI/HOC/btnWithModalForm'
import Title from '@components/UI/Title'
import CloseBtn from '@components/UI/btns/CloseBtn'
//icons
import { FaFileAlt } from 'react-icons/fa'
//translate
import { useTranslation } from 'react-i18next'

/*
    функция EducationList подтягивает данные по api об образовании пользователя
*/
const EducationList = () => {
	const { t } = useTranslation()
	const [resApi, setResApi] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [isError, setIsError] = useState(false)
	const accessToken = useSelector(store => store.authUser.accessToken)

	const prepareData = async response => {
		response.data.code === 200 ? setResApi(response.data.data) : setIsError(true)
	}

	useEffect(() => {
		requestGetApi(
			`user/education/basic`,
			{ headers: { 'X-TOKEN': accessToken } },
			prepareData,
			setIsLoading,
			setIsError
		)
		// eslint-disable-next-line
	}, [])

	const BtnForm = btnWithModalForm(ModalForm, {
		title: t('pages.personal_data.education.modal_title_education'),
		btn_icon: ShowDataBtn.btn_icon,
		btn_type: ShowDataBtn.btn_type,
		btn_size: ShowDataBtn.btn_size,
		icon_size: ShowDataBtn.icon_size,
		btn_classes: ShowDataBtn.btn_classes,
		btn_tooltip_tippy: { status: true, content: t('temp_information.temp_3'), placement: 'top', theme: 'default' },
		dataBsTarget: 'EducationForm'
	})

	const TableContent = ({ data }) =>
		data && Array.isArray(data) && data.length > 0 ? (
			<Table striped bordered>
				<thead>
					<tr>
						<th className='w-[40%]'>{t('pages.personal_data.education.tables.0.columns.0')}</th>
						<th className='w-[10%]'>{t('pages.personal_data.education.tables.0.columns.1')}</th>
						<th className='w-[10%]'>{t('pages.personal_data.education.tables.0.columns.2')}</th>
						<th className='w-[10%]'>{t('pages.personal_data.education.tables.0.columns.3')}</th>
						<th className='w-[10%]'>{t('pages.personal_data.education.tables.0.columns.4')}</th>
						<th className='w-[10%]'>{t('pages.personal_data.education.tables.0.columns.5')}</th>
						<th className='w-[5%] text-center'>
							<FaFileAlt size='16' />
						</th>
					</tr>
				</thead>
				<tbody>
					{data.map(item => (
						<tr key={item.id}>
							<td>{item.organization}</td>
							<td>{item.year_begin}</td>
							<td>{item.year_end}</td>
							<td>{item.education_form}</td>
							<td>{item.organization_type}</td>
							<td>{item.city}</td>
							<td className='text-center'>
								<BtnForm data={item.document} />
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		) : (
			<Message alert alert_status='secondary' key_message='errors.data_not_found' />
		)

	return (
		<>
			<Title tag='h3' title={t('pages.personal_data.education.tables.0.title')} />
			{isLoading ? (
				<MainLoader />
			) : isError ? (
				<Message key_message='errors.data_load' alert alert_status='danger' />
			) : (
				<TableContent data={resApi} />
			)}
		</>
	)
}

const ModalForm = ({ data, handleCloseModal }) => {
	const { t } = useTranslation()
	return (
		<>
			<div className='modal-body'>
				<Table bordered classes='text-start'>
					<tbody>
						<tr>
							<td className='text-[14px]'>{t('pages.personal_data.education.modal_type')}</td>
							<td className='text-[14px]'>{data.type}</td>
						</tr>
						<tr>
							<td className='text-[14px]'>{t('pages.personal_data.education.modal_series')}</td>
							<td className='text-[14px]'>{data.series}</td>
						</tr>
						<tr>
							<td className='text-[14px]'>{t('pages.personal_data.education.modal_number')}</td>
							<td className='text-[14px]'>{data.number}</td>
						</tr>
						<tr>
							<td className='text-[14px]'>{t('pages.personal_data.education.modal_date-issue')}</td>
							<td className='text-[14px]'>{data.date}</td>
						</tr>
						<tr>
							<td className='text-[14px]'>{t('pages.personal_data.education.modal_status')}</td>
							<td className='text-[14px]'>{data.status}</td>
						</tr>
						<tr>
							<td className='text-[14px]'>{t('pages.personal_data.education.modal_medal')}</td>
							<td className='text-[14px]'>{data.medal}</td>
						</tr>
						<tr>
							<td className='w-[50%] text-[14px]'>{t('pages.personal_data.education.modal_speciality')}</td>
							<td className='w-[50%] text-[14px]'>{data.specialization}</td>
						</tr>
						<tr>
							<td className='w-[50%] text-[14px]'>
								{t('pages.personal_data.education.modal_qualification')}
							</td>
							<td className='w-[50%] text-[14px]'>{data.qualification}</td>
						</tr>
					</tbody>
				</Table>
			</div>
			<div className='modal-footer'>
				<CloseBtn onClick={handleCloseModal}>
					{t('modal_window.close')}
				</CloseBtn>
			</div>
		</>
	)
}

export default EducationList
