import { useSelector } from 'react-redux'
import GridWrapper from '@components/UI/GridWrapper'
import * as FontAwesome from 'react-icons/fa'
import LinkBlock from '@components/LinkBlock'
//translate
import { useTranslation } from 'react-i18next'

const classesGrid = ['grid-cols-2', 'gap-1', 'self-start', 'md:grid-cols-3', 'lg:grid-cols-5']

/*
    функция NavigatePage создает ссылочные навигационные кнопки
    на странице 'Личные данные'
*/
const NavigatePage = () => {
	const { t } = useTranslation()

	const profilePages = useSelector(state => state.personalData.profilePages)
	const { pages: linkBlocks } = profilePages.find(item => item.href === 'profile')

	return (
		<div className='navigate-page'>
			<GridWrapper classes={classesGrid}>
				{linkBlocks
					.filter(item => item.visible)
					.map((linkBlock, i) => {
						const Icon = FontAwesome[linkBlock.icon]
						return (
							<LinkBlock
								key={`${i}_linkBlock`}
								title={t(`${linkBlock.title}`)}
								icon={<Icon size='116' />}
								href={linkBlock.href}
								classes={['h-[100%]']}
							/>
						)
					})}
			</GridWrapper>
		</div>
	)
}

export default NavigatePage
