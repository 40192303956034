import { FaRegSave } from 'react-icons/fa'

/*
    Данный кнопка предназначена для отображения в компоненте <Modal.Footer> в основном в модальных окнах
*/
const SaveBtn = ({ type, onClick, classes, icon = true, children, disabled = false }) => {
	return (
		<button
			type={type}
			onClick={onClick}
			className={`btn btn-sm btn-success save-btn ${classes}`}
			disabled={disabled}
		>
			{icon && <FaRegSave size='14' className='mr-1' />}
			{children}
		</button>
	)
}

export default SaveBtn
