import { Controller, useFormContext } from 'react-hook-form'

/*
    компонент Input отвечает за валидирующий инпут из библиотеки react-hook-form

    На вход компонента <Input /> поступают четыре пропса, где 
        name - имя компонента. Обязательно должен быть уникальным для одной формы!
        label - тайтл данного инпута
        rules - правила по которому будет проходить валидация
        placeholder - плэйсхолдер инпута
        changeState - данный пропс передает функцию, которая, в свою очередь, отслеживает стейты данного инпута. Данные
        стейты, в свою очередь, изменяют ключи в глобальном контексте. Это сделано для того, чтобы отслеживать заполнены 
        были инпуты данной формы или нет, чтобы при заполнение одной формы автоматически появлялась для заполнения другая форма.
        Пример можно посмотреть здесь: 'src\widgets\MyProjectsCkp\MyProjects\subpages\AddProject\index.jsx'
        По дефолту changeState идет как пустая функция
            Пример использования компонета <Input />:
            <Input
                name='date_start_input'
                label={t('widgets.my_projects_ckp.my_projects.add_project.project_start_date')}
                rules={{ required: t('valid_params.required') }}
                placeholder={t('react_datepicker_lib.select_date')}
                changeState={() => {changeState(dateValue_1, setDateValue_1)}}
            />
*/
const Input = ({
	name,
	type = 'text',
	wrapperClasses = 'py-2 flex flex-col',
	labelClasses = 'mb-1',
	InputClasses,
	inputGroupClasses = '!text-[14px]',
	classes,
	label,
	defaultValue,
	rules,
	inputGroup,
	placeholder,
	disabled,
	redStar,
	changeState = () => {}
}) => {
	const {
		control,
		formState: { errors }
	} = useFormContext()
	const errorMessage = errors[name] ? errors[name].message : null

	return (
		<>
			<div className={`${wrapperClasses}`}>
				<label className={`form-label ${labelClasses}`}>
					{label}
					{redStar ? <span className='text-red-500'>*</span> : false}
				</label>
				{inputGroup ? (
					<div className={`input-group ${InputClasses}`}>
						<span className={`input-group-text ${inputGroupClasses}`}>{inputGroup}</span>
						<Controller
							name={name}
							control={control}
							rules={rules}
							render={({ field, fieldState: { error } }) => (
								<input
									type={type}
									className={
										error
											? `form-control form-control-sm is-invalid z-0 ${classes}`
											: `form-control form-control-sm -z-1 ${classes}`
									}
									placeholder={placeholder}
									// value={field.value}
									onChange={e => {
										field.onChange(e)
										changeState(e)
									}}
									defaultValue={defaultValue}
								/>
							)}
						/>
					</div>
				) : (
					<Controller
						name={name}
						control={control}
						rules={rules}
						render={({ field, fieldState: { error } }) => (
							<input
								className={
									error
										? `form-control form-control-sm is-invalid z-0 ${classes}`
										: `form-control form-control-sm -z-1 ${classes}`
								}
								placeholder={placeholder}
								disabled={disabled}
								// value={field.value}
								onChange={e => {
									field.onChange(e)
									changeState(e)
								}}
								defaultValue={defaultValue}
							/>
						)}
					/>
				)}
			</div>
			<div className='my-1 text-red-600'>
				<small>{errorMessage && <p>{errors[name].message || 'Ошибка!'}</p>}</small>
			</div>
		</>
	)
}

export default Input
