import Title from '@components/UI/Title'
import Card from '@components/UI/Card'
import AwardsList from './AwardsList'
import AwardsRank from './AwardsRank'
//translate
import { useTranslation } from 'react-i18next'

/*
    функция AwardsProfilePage отвечает за рендеринг страницы 'Награды'
*/
const AwardsProfilePage = () => {
	const { t } = useTranslation()
	return (
		<>
			<Title tag='h2' title={t('pages.personal_data.awards.title_page')} />
			<Card>
				<AwardsList />
			</Card>
			<Card>
				<AwardsRank />
			</Card>
		</>
	)
}

export default AwardsProfilePage
