/*
    Данный файл содержит функции по авторизации пользователя и по работе с токенами
*/
import { httpRequest } from '@services/http/httpRequest.js'
import { fetching, fetchingError, fetchingSuccess, setUser } from '../slices/authUserSlice'
import { saveAuthToStorage } from '../../helpers/utils.js'

const API_KEY = process.env.REACT_APP_API_KEY
const SERVER_AUTH = process.env.REACT_APP_SERVER_AUTH
const SERVER_API_TPU = process.env.REACT_APP_SERVER_API_TPU
const SERVER_API = process.env.REACT_APP_SERVER_API
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
const REACT_APP_CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET
const REACT_APP_GRANT_TYPE = process.env.REACT_APP_GRANT_TYPE
const PROTOCOL = process.env.REACT_APP_PROTOCOL
const HOST = process.env.REACT_APP_HOST
const PORT = process.env.REACT_APP_PORT && `3A${process.env.REACT_APP_PORT}`
const RESPONSE_TYPE = process.env.REACT_APP_RESPONSE_TYPE
const AUTH_STATE = process.env.REACT_APP_AUTH_STATE

export const login = () => {
	const port = PORT !== '' ? `%${PORT}` : ''
	const urlAuth = `${SERVER_AUTH}/authorize?client_id=${CLIENT_ID}&redirect_uri=${PROTOCOL}%3A%2F%2F${HOST}${port}&response_type=${RESPONSE_TYPE}&state=${AUTH_STATE}`
	window.location.replace(urlAuth)
}

export const getTokens = (codeGetParam, stateGetParam) => async dispatch => {
	const port = PORT !== '' ? `%${PORT}` : ''
	try {
		dispatch(fetching())

		const url = `${SERVER_AUTH}/access_token?client_id=${CLIENT_ID}&grant_type=${REACT_APP_GRANT_TYPE}&client_secret=${REACT_APP_CLIENT_SECRET}&redirect_uri=${PROTOCOL}%3A%2F%2F${HOST}${port}&code=${codeGetParam}&state=${stateGetParam}`
		const res = await httpRequest({ url })
		if (res.code && res.code !== 200) {
			throw new Error('Ошибка сервера авторизации')
		}

		await dispatch(fetchingSuccess(res))
		await dispatch(getUserByToken(res.access_token))
	} catch (err) {
		//console.log(err)
		dispatch(fetchingError(err.message))
	}
}

// функция по получения токенов при заходе через другого пользователя стр. 'Войти в ЛК под другим пользователем'
export const getTokensAnotherUser = data => async dispatch => {
	try {
		dispatch(fetching())
		// подготавливаем объект с токенами и с ключем another_user
		// another_user: 2 - суперадмин зашел под другим пользователем
		const res = { access_token: data.token, refresh_token: data.refresh_token, another_user: 2 } 
		await dispatch(fetchingSuccess(res))
		await dispatch(getUserByToken(res.access_token, res.another_user))
	} catch (err) {
		//console.log(err)
		dispatch(fetchingError(err.message))
	}
}

export const refreshTokens = refreshToken => async dispatch => {
	try {
		const urlRefreshToken = `${SERVER_API_TPU}/auth/token?refresh_token=${refreshToken}&apiKey=${API_KEY}`
		const res = await httpRequest({ url: urlRefreshToken, method: 'PUT' })
		//console.log(res.body)

		res.body.access_token = res.body.token
		if (res.code && res.code !== 200) {
			saveAuthToStorage(null)
			login()
		} else {
			await dispatch(fetchingSuccess(res.body))
			await dispatch(getUserByToken(res.body.access_token))
		}
	} catch (err) {
		//console.log(err)
		saveAuthToStorage(null)
		login()
	}
}

export const getUserByToken =
	(accessToken, anotherUser = 1) => // anotherUser = 1 - суперадмин не зашел под другим пользователем
	async dispatch => {
		const url = `${SERVER_API}/user/info?token=${accessToken}`
		try {
			dispatch(fetching())
			const res = await httpRequest({ url })

			if (res.code && res.code !== 200) {
				if (res.code === 429) {
					throw new Error('ОЙ! Слишком много запросов к базе данных. Зайдите через минутку')
				} else {
					throw new Error('Ошибка сервера авторизации')
				}
			}

			res.data.anotherUser = anotherUser // добавляем параметр anotherUser в res
			dispatch(setUser(res.data))
		} catch (err) {
			//console.log(err)
			dispatch(fetchingError(err.message))
		}
	}
