import { useSelector } from 'react-redux'
import { userRoleEnum } from '../../../../helpers/enums'
// import {FormAddChildInfo} from '../KindergartenPage/ChildInfo/FormAddChildInfo'
import Title from '@components/UI/Title'
import Card from '@components/UI/Card'
import PlaceOfWork from './PlaceOfWork'
import Account from './Account'
import AdditionalEmails from './AdditionalEmails'
import ContactPhoneNumbers from './ContactPhoneNumbers'
import GridWrapper from '@components/UI/GridWrapper'
//translate
import { useTranslation } from 'react-i18next'

/*
    функция ContactsProfilePage отвечает за рендеринг страницы 'Контакты'
*/
const ContactsProfilePage = () => {
	const { t } = useTranslation()
	const currentRole = useSelector(state => state.authUser.currentRole)
	return (
		<>
			<Title tag='h2' title={t('pages.personal_data.contacts.title_page')} />
			<GridWrapper classes={['grid-cols-1', 'gap-2', 'lg:grid-cols-[min(500px)_1fr]']}>
				<div>
					{currentRole === userRoleEnum.staff.id ? (
						<Card>
							<PlaceOfWork />
						</Card>
					) : null}
					<Card>
						<Account />
					</Card>
					<Card>
						<AdditionalEmails />
					</Card>
				</div>
				<Card>
					<ContactPhoneNumbers />
				</Card>
			</GridWrapper>
		</>
	)
}

export default ContactsProfilePage
