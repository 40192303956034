import Card from '@components/UI/Card'
// import Title from '@components/UI/Title'
import AlertMessage from '@components/UI/AlertMessage'
//translate
import { useTranslation } from 'react-i18next'

/*
    функция SocialActivityProfilePage отвечает за рендеринг страницы 'Общественная деятельность'
*/
const SocialActivityProfilePage = () => {
	const { t } = useTranslation()

	return (
		<Card>
			{/* <Title tag='h2' title ={t('pages.personal_data.social_activity.title_page')} />
            <Title tag='h3' title ='Победы в творческих конкурсах:' classes='my-4' />
            <p>У Вас пока нет записей в этой категории.</p>
            <Title tag='h3' title ='Участие в культурно-массовых мероприятиях:' classes='my-4' />
            <p>У Вас пока нет записей в этой категории.</p>
            <Title tag='h3' title ='Результаты творческой деятельности:' classes='my-4' />
            <p>У Вас пока нет записей в этой категории.</p> */}
			<AlertMessage status='warning'>
				<p className='my-2 text-[18px]'>{t('temp_information.temp_1')}</p>
			</AlertMessage>
		</Card>
	)
}

export default SocialActivityProfilePage
