import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { requestGetApi } from '@services/api/requestServerApi'
import Table from '@components/UI/Table'
import MainLoader from '@components/Loaders/MainLoader'
import Message from '@components/UI/Message'
import Title from '@components/UI/Title'
//translate
import { useTranslation } from 'react-i18next'

const REACT_APP_SERVER_API_YII2 = process.env.REACT_APP_SERVER_API_YII2
const REACT_APP_API_KEY = process.env.REACT_APP_API_KEY

/*
    функция AwardsRank подтягивает данные по api о званиях пользователя
*/
const AwardsRank = () => {
	const { t } = useTranslation()
	const [resApi, setResApi] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [isError, setIsError] = useState(false)
	const [message, setMessage] = useState(null)
	const accessToken = useSelector(store => store.authUser.accessToken)

	const prepareData = async response => {
		if (response.data.code === 200) {
			if (response.data.data) {
				// console.log(response.data?.data)
				setResApi(response.data.data)
			} else {
				setIsError(true)
				setMessage(<Message key_message='errors.data_load' alert alert_status='danger' />)
			}
		} else if (response.data.code === 429) {
			setIsError(true)
			setMessage(<Message key_message='errors.page_429' alert alert_status='danger' />)
		} else {
			setIsError(true)
			setMessage(<Message key_message='errors.data_load' alert alert_status='danger' />)
		}
	}

	useEffect(() => {
		requestGetApi(
			`${REACT_APP_SERVER_API_YII2}/portal/ranks?apiKey=${REACT_APP_API_KEY}`,
			{ headers: { Token: accessToken } },
			prepareData,
			setIsLoading,
			setIsError,
			null,
			false,
			true
		)
		// eslint-disable-next-line
	}, [])

	// преобразуем дату в нужный формат без времени
	const formatDate = inputDate => {
		const dateParts = inputDate.split(' ')[0].split('-')
		const formattedDate = dateParts[2] + '.' + dateParts[1] + '.' + dateParts[0]
		return formattedDate
	}

	const TableContent = ({ data }) =>
		data && Array.isArray(data) && data.length > 0 ? (
			<Table striped bordered>
				<thead>
					<tr>
						<th className='w-[10%]'>{t('pages.personal_data.awards.tables.1.columns.0')}</th>
						<th className='w-[90%]'>{t('pages.personal_data.awards.tables.1.columns.1')}</th>
					</tr>
				</thead>
				<tbody>
					{data.map((item, i) => (
						<tr key={`${i}_award`}>
							<td className='w-[10%]'>{formatDate(item.date_receive)}</td>
							<td className='w-[90%]'>{item.title}</td>
						</tr>
					))}
				</tbody>
			</Table>
		) : (
			<Message alert alert_status='secondary' key_message='errors.data_not_found' />
		)

	return (
		<>
			<Title tag='h3' title={t('pages.personal_data.awards.tables.1.title')} />
			{isLoading ? <MainLoader /> : isError ? <div>{message}</div> : <TableContent data={resApi} />}
		</>
	)
}

export default AwardsRank
