import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useRequestApiEffectOnce } from '../../../../hooks/useRequestApi'
import Table from '@components/UI/Table'
import Title from '@components/UI/Title'
import MainLoader from '@components/Loaders/MainLoader'
import Message from '@components/UI/Message'
//translate
import { useTranslation } from 'react-i18next'

/*
    функция InfoProfile подтягивает основную информацию пользователя по api
*/
const InfoProfile = () => {
	const { t } = useTranslation()
	const [data, setData] = useState([])
	const [error, setError] = useState(false)
	const accessToken = useSelector(store => store.authUser.accessToken)

	const SERVER_API = process.env.REACT_APP_SERVER_API_YII2_TEMP_2
	const API_KEY = process.env.REACT_APP_API_KEY

	const { resApi, isLoading, message, setMessage } = useRequestApiEffectOnce({
		url: `${SERVER_API}/portal/employee/common?apiKey=${API_KEY}`,
		headers: { token: accessToken },
		otherApi: true
	})

	const prepareData = response => {
		if (response?.data?.code === 200) {
			Object.prototype.toString.call(response?.data?.data) === '[object Object]' &&
				response?.data?.data &&
				setData(response.data.data)
			// console.log(response.data?.data)
		} else if (response.data.code === 429) {
			setError(true)
			setMessage(<Message key_message='errors.page_429' alert alert_status='danger' />)
		} else {
			setError(true)
			setMessage(<Message key_message='errors.data_load' alert alert_status='danger' />)
		}
	}

	useEffect(() => {
		resApi && prepareData(resApi)
		// eslint-disable-next-line
	}, [resApi])

	const TableContent = () =>
		data ? (
			<Table>
				<tbody>
					<tr>
						<td className='w-[10%]'>{t('pages.personal_data.general_info.tables.0.columns.0')}</td>
						<td className='w-[50%]'>{data.citizenship}</td>
					</tr>
					<tr>
						<td className='w-[10%]'>{t('pages.personal_data.general_info.tables.0.columns.1')}</td>
						<td className='w-[50%]'>{data.inn_nomer}</td>
					</tr>
					<tr>
						<td className='w-[10%]'>{t('pages.personal_data.general_info.tables.0.columns.2')}</td>
						<td className='w-[50%]'>{data.insurance_nomer}</td>
					</tr>
					<tr>
						<td className='w-[10%]'>{t('pages.personal_data.general_info.tables.0.columns.3')}</td>
						<td className='w-[50%]'>{data.birthdate}</td>
					</tr>
					<tr>
						<td className='w-[10%]'>{t('pages.personal_data.general_info.tables.0.columns.4')}</td>
						<td className='w-[50%]'>{data.birthplace}</td>
					</tr>
					<tr>
						<td className='w-[10%]'>{t('pages.personal_data.general_info.tables.0.columns.5')}</td>
						<td className='w-[50%]'>{data.address}</td>
					</tr>
					<tr>
						<td className='w-[10%]'>{t('pages.personal_data.general_info.tables.0.columns.6')}</td>
						<td className='w-[50%]'>{data.address_residential}</td>
					</tr>
					<tr>
						<td className='w-[10%]'>{t('pages.personal_data.general_info.tables.0.columns.7')}</td>
						<td className='w-[50%]'>{data.family_status}</td>
					</tr>
					{/* <tr>
						<td className='w-[10%]'>{t('pages.personal_data.general_info.tables.0.columns.8')}</td>
						<td className='w-[50%]'>{data.nationality}</td>
					</tr> */}
				</tbody>
			</Table>
		) : (
			<Message alert alert_status='secondary' key_message='errors.data_not_found' />
		)

	return (
		<>
			<Title tag='h3' title={t('pages.personal_data.general_info.tables.0.title')} />
			{isLoading ? <MainLoader /> : error ? <div>{message}</div> : <TableContent />}
		</>
	)
}

export default InfoProfile
