import { useState } from 'react'
import { FaWikipediaW } from 'react-icons/fa'
import Title from '@components/UI/Title'
//translate
import { useTranslation } from 'react-i18next'

const wikiTPU = 'https://wiki.tpu.ru/wiki/Седнев_Дмитрий_Андреевич'

/*
    функция WikipediaTPU выводит ссылку на википедию тпу
*/
const WikipediaTPU = () => {
	const { t } = useTranslation()
	const [isPersonalWiki] = useState(true)

	return (
		<>
			<Title tag='h3' title={t('pages.personal_data.online.wikipedia_tpu')} className='mb-2' />
			{isPersonalWiki && (
				<>
					<FaWikipediaW size='20' className='mr-2' />
					<a href={wikiTPU}>{wikiTPU}</a>
				</>
			)}
		</>
	)
}

export default WikipediaTPU
