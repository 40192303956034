// Функции по работе с api Get Post Delete
import axios from 'axios'
import Message from '@components/UI/Message'

const SERVER_API = process.env.REACT_APP_SERVER_API
const SERVER_BASE_API = process.env.REACT_APP_SERVER_BASE_API

//TODO: devSwitch - ключ по которому url GET запрос идет в мимо переменной SERVER_API и идет к SERVER_BASE_API (потом убрать, когда api будет нормально сделан)

export const requestGetApi = async (
	apiUrl,
	params = {},
	prepareData,
	setIsLoading = null,
	setIsError = null,
	setErrorMessage = null,
	devSwitch = false,
	otherApi = false
) => {
	try {
		if (setIsError) {
			setIsError(false)
		}
		if (setIsLoading) {
			setIsLoading(true)
		}
		// const response = await axios.get(`${SERVER_API}/${apiUrl}`, params)
		let response = null
		if (otherApi) {
			response = await axios.get(apiUrl, params)
		} else if (devSwitch !== true) {
			response = await axios.get(`${SERVER_API}/${apiUrl}`, params)
		} else {
			response = await axios.get(`${SERVER_BASE_API}/${apiUrl}`, params)
		}
		// console.log(response)
		if (response.status === 200) {
			await prepareData(response)
		} else {
			if (setIsError) {
				setIsError(true)
			}
			throw new Error()
		}
		if (setIsLoading) {
			setIsLoading(false)
		}
	} catch (error) {
		console.log(error)
		if (setIsError) {
			setIsError(true)
		}
		if (setErrorMessage)
			setErrorMessage(
				<Message key_message='errors.server' alert={true}>
					{error.message}
				</Message>
			)
		if (setIsLoading) {
			setIsLoading(false)
		}
	}
}

//TODO: devSwitch - ключ по которому url GET запрос идет в мимо переменной SERVER_API и идет к SERVER_BASE_API (потом убрать, когда api будет нормально сделан)
export const requestPostApi = async (
	apiUrl,
	data,
	params = {},
	prepareData,
	setIsLoading = null,
	setIsError = null,
	setErrorMessage = null,
	devSwitch = false,
	otherApi = false
) => {
	try {
		if (setIsError) {
			setIsError(false)
		}
		if (setIsLoading) {
			setIsLoading(true)
		}
		let response = null
		if (otherApi) {
			console.log(response)
			response = await axios.post(apiUrl, data, params)
		} else if (devSwitch !== true) {
			response = await axios.post(`${SERVER_API}/${apiUrl}`, data, params)
		} else {
			response = await axios.post(`${SERVER_BASE_API}/${apiUrl}`, data, params)
		}
		// if (devSwitch !== true) {
		// 	response = await axios.post(`${SERVER_API}/${apiUrl}`, data, params)
		// } else {
		// 	response = await axios.post(`${SERVER_BASE_API}/${apiUrl}`, data, params)
		// }
		if (response.status === 200) {
			await prepareData(response)
		} else {
			if (setIsError) {
				setIsError(true)
			}
		}
		// console.log(response)
		if (setIsLoading) {
			setIsLoading(false)
		}
	} catch (error) {
		console.log(error)
		if (setIsError) {
			setIsError(true)
		}
		if (setErrorMessage)
			setErrorMessage(
				<Message key_message='errors.server' alert={true}>
					{error.message}
				</Message>
			)
		if (setIsLoading) {
			setIsLoading(false)
		}
	}
}

//TODO: devSwitch - ключ по которому url GET запрос идет в мимо переменной SERVER_API и идет к SERVER_BASE_API (потом убрать, когда api будет нормально сделан)
export const requestDeleteApi = async (
	apiUrl,
	headers = {},
	prepareData,
	setIsLoading = null,
	setIsError = null,
	setErrorMessage = null,
	devSwitch = false,
	otherApi = false
) => {
	try {
		if (setIsError) {
			setIsError(false)
		}
		if (setIsLoading) {
			setIsLoading(true)
		}
		// const response = await axios.delete(`${SERVER_API}/${apiUrl}`, headers)
		// console.log(response)
		let response = null
		if (devSwitch !== true) {
			response = await axios.delete(`${SERVER_API}/${apiUrl}`, headers)
		} else {
			response = await axios.delete(`${SERVER_BASE_API}/${apiUrl}`, headers)
		}
		if (response.status === 200) {
			await prepareData(response)
		} else {
			if (setIsError) {
				setIsError(true)
			}
		}
		if (setIsLoading) {
			setIsLoading(false)
		}
	} catch (error) {
		console.log(error)
		if (setIsError) {
			setIsError(true)
		}
		if (setErrorMessage)
			setErrorMessage(
				<Message key_message='errors.server' alert={true}>
					{error.message}
				</Message>
			)
		if (setIsLoading) {
			setIsLoading(false)
		}
	}
}
