const httpRequest = async ({
	url,
	method = 'GET',
	body = null,
	headers = { 'Content-Type': 'application/json' },
	options = {}
}) => {
	//
	try {
		if (options.token) {
			headers['X-TOKEN'] = options.token
		}

		const response = await fetch(url, { method, body, headers })
		//console.log(response)

		if (!response.ok) {
			throw new Error(`Could not fetch ${url}, status: ${response.status}`)
		}

		const data = await response.json()
		//console.log(data)

		return data
	} catch (e) {
		throw e
	}
}

export { httpRequest }
