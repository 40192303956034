// /* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	data: null,
	fetchStaffWorkInfoDataStatus: 'idle'
}

const staffWorkInfoDataSlice = createSlice({
	name: 'staffWorkInfoData',
	initialState,
	reducers: {
		fetching: (state, action) => {
			state.fetchStaffWorkInfoDataStatus = 'loading'
		},

		fetchingError: {
			reducer: (state, action) => {
				state.fetchStaffWorkInfoDataStatus = action.payload
			},
			prepare: strError => {
				return {
					payload: strError || 'Произошла ошибка!!!'
				}
			}
		},

		fetchingSuccess: {
			reducer: (state, action) => {
				state.fetchStaffWorkInfoDataStatus = 'idle'
				state.data = action.payload
			},
			prepare: staffWorkInfoData => {
				return { payload: staffWorkInfoData }
			}
		},

		removeData: (state, action) => {
			state.fetchStaffWorkInfoDataStatus = 'idle'
			state.data = null
		}
	}
})

const { actions, reducer } = staffWorkInfoDataSlice

export const { fetching, fetchingError, fetchingSuccess, removeData } = actions

export default reducer
