//icons
import { FaExclamationCircle, FaExclamationTriangle, FaExclamation, FaInfoCircle } from 'react-icons/fa'
import './AlertMessage.css'
//translate
import { useTranslation } from 'react-i18next'

/*
На вход компонента <AlertMessage /> поступают два пропса, где 
    status - тип сообщения (насколько оно важно);
        Принимает четыре значения:
        'danger' - ставит компоненту класс danger
        'warning' - ставит компоненту класс warning
        'success' - ставит компоненту класс exclamation
        '' - по дефолту ставит компоненту класс primary
    classes - стили css
    children - принимает текст данного сообщения (или html код)
        Пример использования компонета <AlertMessage />:
        <AlertMessage status='danger' >
            *текст (html код) данного сообщения*
        </AlertMessage>
*/
const Alert = ({ status = 'primary', classes, children }) => {
	const { t } = useTranslation()
	const icon = status => {
		switch (status) {
			case 'danger':
				return <FaExclamationCircle size='20' />
			case 'warning':
				return <FaExclamationTriangle size='20' />
			case 'success':
				return <FaExclamation size='20' />
			default:
				return <FaInfoCircle size='20' />
		}
	}

	return (
		<div className={`alert-message alert-${status} px-3 ${classes}`}>
			<div className='alert-message__title'>
				<div className='alert-message__icon'>{icon(status)}</div>
				<h2 className='ml-2 font-bold'>{t(`alert_message.${status}`)}</h2>
			</div>
			<div className='alert-message__content'>{children}</div>
		</div>
	)
}

export default Alert
