import InfoProfile from './InfoProfile'
import InfoFamily from './InfoFamily'
import InfoFile from './InfoFile'
import Card from '@components/UI/Card'
import Title from '@components/UI/Title'
//translate
import { useTranslation } from 'react-i18next'

/*
    функция InfoProfilePage отвечает за рендеринг страницы 'Общие сведения'
*/
const InfoProfilePage = () => {
	const { t } = useTranslation()
	return (
		<>
			<Title tag='h2' title={t('pages.personal_data.general_info.title_page')} />
			<InfoFile />
			<Card>
				<InfoProfile />
			</Card>
			<Card>
				<InfoFamily />
			</Card>
		</>
	)
}

export default InfoProfilePage
