import GridWrapper from '@components/UI/GridWrapper'
// import SidebarSingle from '@components/Dashboard/SidebarSingle'
// import Slider from '@components/UI/Slider'
import PersonalMenu from './PersonalMenu'
import Message from '@components/UI/Message'
//translate
import { useTranslation } from 'react-i18next'

//1600*300 - Средний размер баннера
// const sliderData = [
// 	{
// 		urlImage: 'https://content.nebo.by/photos/steklo/big/34914-skinali-7382.jpg',
// 		link: 'https://tpu.ru/',
// 		title: 'Увидеть эволюцию компьютерной техники смогут томичи на выставке в ТПУ',
// 		description: 'Комплекс музеев Томского политехнического университета приглашает студентов и сотрудников....'
// 	},
// 	{
// 		urlImage: 'https://content.nebo.by/photos/steklo/big/skinali-4118.jpg',
// 		link: 'https://tpu.ru/',
// 		title: 'Заголовок 2',
// 		description: 'Lorem, ipsum dolor sit amet consectetur adipisicing. '
// 	},
// 	{
// 		urlImage: 'https://www.omegawatches.com/media/catalog/category/background-back-constellation-gents-collection.jpg',
// 		link: 'https://tpu.ru/',
// 		title: 'Заголовок 3',
// 		description: 'Lorem, ipsum dolor sit amet consectetur adipisicing.'
// 	}
// ]

/*
    Функция MainDashboard рендерит главную страницу '/' личного кабинета
*/
const MainDashboard = () => {
	const { t } = useTranslation()

	return (
		<div className='wrapper max-w-[1920px]'>
			{/* TODO: потом поменять на 'lg:grid-cols-[1fr_max(350px)]' когда компонент <SidebarSingle/> будет готов */}
			<Message alert alert_status='warning' classes='mt-2'>
				Действующая версия&nbsp;<a href='https://portal.tpu.ru/desktop'>личного кабинета ТПУ</a>.
			</Message>
			<GridWrapper classes={['grid-wrapper', 'grid-cols-1', 'lg:grid-cols-[1fr_max(0px)]', 'gap-2']}>
				<div>
					{/* {<Slider className='mb-2' sliderData={sliderData} interval={10000} />} */}
					<div className='w-100 bg-g_color_1 text-white text-center uppercase font-bold py-1 mb-2'>
						{t('personal_area.title')}
					</div>
					<PersonalMenu />
				</div>
				{/* <SidebarSingle/> */}
			</GridWrapper>
		</div>
	)
}

export default MainDashboard
