import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { requestGetApi } from '@services/api/requestServerApi'
import Card from '@components/UI/Card'
import Title from '@components/UI/Title'
import ImageEditor from '@components/ImageEditor'
import AlertMessage from '@components/UI/AlertMessage'
import GridWrapper from '@components/UI/GridWrapper'
import Message from '@components/UI/Message'
import MainLoader from '@components/Loaders/MainLoader'
//translate
import { useTranslation } from 'react-i18next'

/*
    функция AvatarEditorPage отвечает за рендеринг страницы 'Изменить изображение'
*/
const AvatarEditorPage = () => {
	const { t } = useTranslation()
	const { user } = useSelector(state => state.authUser)
	const accessToken = useSelector(state => state.authUser.accessToken)

	const [resApi, setResApi] = useState(null)
	const [isLoading, setLoading] = useState(false)
	const [isError, setIsError] = useState(false)
	const [message, setMessage] = useState(null)

	const prepareData = async response => {
		if (response.data.code === 200) {
			if (response.data.data) {
				// console.log(response.data?.data)
				setResApi(response.data.data)
			} else {
				setIsError(true)
				setMessage(<Message key_message='errors.data_load' alert />)
			}
		} else if (response.data.code === 429) {
			setIsError(true)
			setMessage(<Message key_message='errors.page_429' alert />)
		} else {
			setIsError(true)
			setMessage(<Message key_message='errors.data_load' alert />)
		}
	}

	useEffect(() => {
		requestGetApi(`user/photo/moderation`, { headers: { 'X-TOKEN': accessToken } }, prepareData, setLoading, setIsError)
		// eslint-disable-next-line
	}, [])

	return (
		<>
			<AlertMessage status='warning'>{t('pages.personal_data.avatar_editor.requirements')}</AlertMessage>
			<>
				{isLoading ? (
					<MainLoader />
				) : isError ? (
					<div>{message}</div>
				) : (
					<>
						{!resApi?.isOnModerationForWeb ? (
							<Card>
								<GridWrapper classes={['grid-wrapper', 'grid-cols-1', 'lg:grid-cols-[110px_max(600px)]', 'gap-2', '']}>
									<div className='min-w-[100px]'>
										<img src={`https://staff.tpu.ru/person/photo/${user?.lichnost}`} alt='' width={110} />
									</div>
									<div className='lg:ml-3'>
										<Title
											tag='h4'
											title={`${t('pages.personal_data.avatar_editor.photo_title_1')} 120*140px`}
											className='mb-3'
										/>
										<ImageEditor photo_type_id={-2} width={120} height={140} />
									</div>
								</GridWrapper>
							</Card>
						) : (
							<Message alert_status='secondary'>{t('pages.personal_data.avatar_editor.photo_moderation_1')}</Message>
						)}
						{!resApi?.isOnModerationForPersSite ? (
							<Card>
								<GridWrapper classes={['grid-wrapper', 'grid-cols-1', 'lg:grid-cols-[110px_max(600px)]', 'gap-2', '']}>
									<div className='min-w-[100px]'>
										<img
											src={`https://staff.tpu.ru/personal/show-photo-personality?type=5&lid=${user?.lichnost}`}
											alt=''
											width={110}
										/>
									</div>
									<div className='lg:ml-3'>
										<Title
											tag='h4'
											title={`${t('pages.personal_data.avatar_editor.photo_title_2')} 400*400px`}
											className='mb-3'
										/>
										<ImageEditor photo_type_id={-5} width={400} height={400} />
									</div>
								</GridWrapper>
							</Card>
						) : (
							<Message alert_status='secondary'>{t('pages.personal_data.avatar_editor.photo_moderation_2')}</Message>
						)}
					</>
				)}
			</>
		</>
	)
}

export default AvatarEditorPage
