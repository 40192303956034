import { useState } from 'react'
import { useSelector } from 'react-redux'
import { FormProvider, useForm } from 'react-hook-form'
import { requestPostApi } from '@services/api/requestServerApi'
import MainLoader from '@components/Loaders/MainLoader'
import Message from '@components/UI/Message'
import SaveBtn from '@components/UI/btns/SaveBtn'
import CloseBtn from '@components/UI/btns/CloseBtn'
import Input from '@components/UI/ElementsReusable/Input'
//translation
import { useTranslation } from 'react-i18next'

/*
    функция FormAddEmail отвечает за рендеринг модального окна 'Добавить почтовый ящик'
*/
const FormAddEmail = ({ handleCloseModal }) => {
	const { t } = useTranslation()
	const accessToken = useSelector(state => state.authUser.accessToken)
	//Отправка формы
	const [isSuccess, setIsSuccess] = useState(false)
	// eslint-disable-next-line
	const [isLoading, setIsLoading] = useState(false)
	// eslint-disable-next-line
	const [isError, setIsError] = useState(false)
	const [message, setMessage] = useState(null)

	const onSubmit = data => {
		const newData = { email: null }
		newData.email = data.email_input
		// console.log(data)
		handleSubmitData(newData)
		reset('', {
			keepValues: false,
			keepDefaultValues: true
		})
	}

	const methods = useForm({
		defaultValues: {
			email_input: null
		}
	})

	const { handleSubmit, reset } = methods

	const prepareDataSubmit = response => {
		// console.log(response)
		if (response?.data?.code === 200 && response?.data?.data) {
			setIsSuccess(true)
			setMessage(<Message alert alert_status='success' key_message='success.data_send' />)
		} else {
			setIsError(true)
			setMessage(<small className='text-red-600'>{response?.data?.message}</small>)
		}
	}

	const handleSubmitData = data => {
		setMessage(
			<div className='mt-2'>
				<MainLoader />
			</div>
		)
		requestPostApi(
			'user/employee/email',
			new URLSearchParams({ ...data }),
			{
				headers: {
					'X-TOKEN': accessToken,
					'Content-Type': 'application/x-www-form-urlencoded'
				}
			},
			prepareDataSubmit,
			setIsLoading,
			setIsError
		)
	}

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className='modal-body'>
					{!isSuccess && (
						<Input
							name='email_input'
							type = 'email'
							label={t('pages.personal_data.contacts.new_email')}
							placeholder='example@mail.ru'
							rules={{
								required: t('valid_params.required'),
								pattern: {
									value:
										/^(([^<>()[\]\\.,:\s@']+(\.[^<>()[\]\\.,:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
									message: t('valid_params.pattern_email')
								}
							}}
							classes='!text-[14px]'
							inputGroup='Email:'
							redStar
						/>
					)}
					{message}
				</div>
				<div className='modal-footer'>
					{!isSuccess && (
						<SaveBtn type='submit' onClick={handleSubmit(onSubmit)}>
							{t('modal_window.save')}
						</SaveBtn>
					)}
					<CloseBtn onClick={handleCloseModal}>
						{t('modal_window.close')}
					</CloseBtn>
				</div>
			</form>
		</FormProvider>
	)
}

export default FormAddEmail
