/*
    Данная папка conf-bts была создана для описания кнопок одной категории (Например: скачать, удалить и тд)
    Так как HOC btnWithModalForm модалка и кнопка связаны вместе, было решено создать объект, которая описывает
    свойства одной категории кнопок. 
*/
// Данный файл описывает свойства кнопки addBtn.
export const addBtn = {
	btn_icon: 'Plus',
	btn_type: 'success',
	btn_classes: 'my-1 green-button',
	btn_size: 'sm',
	icon_size: 14
}
