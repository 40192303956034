import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { addBtn } from '@components/UI/btns/conf-btns/add'
import Title from '@components/UI/Title'
import btnWithModalForm from '@components/UI/HOC/btnWithModalForm'
import InputCustom from '@components/UI/InputCustom'
import Input from '@components/UI/ElementsReusable/Input'
import SaveBtn from '@components/UI/btns/SaveBtn'
import CloseBtn from '@components/UI/btns/CloseBtn'
//icons
import { FaGlobe } from 'react-icons/fa'
//translate
import { useTranslation } from 'react-i18next'

const SocialLinks = [
	{ id: 1, url: 'https://vk.com/', isRequired: true },
	{ id: 2, url: 'https://ok.ru/', isRequired: false }
]

/*
    функция SocialMedia выводит данные по социальным сетям пользователя
*/
const SocialMedia = () => {
	const { t } = useTranslation()
	// eslint-disable-next-line
	const [update, setUpdate] = useState({})

	const BtnAddSocialForm = btnWithModalForm(AddSocialLinkForm, {
		title: t('pages.personal_data.online.button_add_social_media'),
		btn_text: t('pages.personal_data.online.button_add_social_media'),
		btn_icon: addBtn.btn_icon,
		btn_type: addBtn.btn_type,
		btn_classes: addBtn.btn_classes,
		btn_size: addBtn.btn_size,
		icon_size: addBtn.icon_size,
		updatable: true,
		set_update: () => setUpdate({}),
		dataBsTarget: 'SocialLinkForm'
	})

	// задаем параметры валидации для emails
	const validModeLinks = 'onSubmit'
	const validParamsLinks = {
		required: t('valid_params.required'),
		maxLength: {
			value: 100,
			message: t('valid_params.max_length100')
		},
		pattern: {
			value:
				/^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/,
			message: t('valid_params.pattern_url')
		}
	}

	return (
		<>
			<Title tag='h3' title={t('pages.personal_data.online.social_media')} className='mb-2' />
			{SocialLinks.length > 0 && (
				<>
					{SocialLinks.map(link => (
						<InputCustom
							key={link.id}
							initialValue={link.url}
							placeholder='https://'
							isChangable
							validMode={validModeLinks}
							validParams={validParamsLinks}
							isDeletable
							required
						>
							<span className='input-group-text !text-[14px]'>
								<a href={link.url} target='_blank' rel='noreferrer'>
									<FaGlobe size='24' />
								</a>
							</span>
						</InputCustom>
					))}
				</>
			)}
			<BtnAddSocialForm />
		</>
	)
}

const AddSocialLinkForm = ({ apiUrl, handleCloseModal }) => {
	const { t } = useTranslation()
	// eslint-disable-next-line
	const [isSuccess, setIsSuccess] = useState(false)

	const methods = useForm({
		defaultValues: {
			url: null
		}
	})

	const {
		handleSubmit,
		reset,
		formState: { errors }
	} = methods

	const onSubmit = data => {
		alert(JSON.stringify(data))
		// console.log(data)
		reset('', {
			keepValues: false,
			keepDefaultValues: true
		})
		handleCloseModal()
	}

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className='modal-body'>
					<Input
						name='url'
						label={t('pages.personal_data.online.label_social_media')}
						placeholder='https://example.ru'
						rules={{
							required: t('valid_params.required'),
							pattern: {
								// value: new RegExp('https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}'),
								value:
									/^https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}$/,
								message: t('valid_params.pattern_url')
							}
						}}
						classes='!text-[14px]'
						isInvalid={errors.url}
						inputGroup='Url:'
						redStar
					/>
				</div>
				<div className='modal-footer'>
					{!isSuccess && (
						<SaveBtn type='submit' onClick={apiUrl}>
							{t('modal_window.save')}
						</SaveBtn>
					)}
					<CloseBtn onClick={handleCloseModal}>
						{t('modal_window.close')}
					</CloseBtn>
				</div>
			</form>
		</FormProvider>
	)
}

export default SocialMedia
