import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
//Icons
import { FaMoon, FaSun } from 'react-icons/fa'
import { MdMenu } from 'react-icons/md'
import { BsArrowBarLeft, BsDoorOpenFill } from 'react-icons/bs'
//Styles
import './TopNavigation.css'
import { useDarkMode } from '../../../hooks/useDarkMode'
//Redux
import { toggle } from '../../../store/slices/sidebarSlice'
import { removeAuthUser, updateCurrentRole } from '../../../store/slices/authUserSlice'
import { getDataStudent } from '../../../store/actions/studentDataAction'
import { getDataStaff } from '../../../store/actions/staffDataAction'
import { getDataWorkInfoStaff } from '../../../store/actions/staffWorkInfoDataAction'
import { removeData as removeDataStaff } from '../../../store/slices/staffDataSlice'
import { removeData as removeDataStudent } from '../../../store/slices/studentDataSlice'
import Tippy from '@tippyjs/react'
import Img from '@components/UI/Img'
//Translation
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'
import '../../../i18next'

import { userRoleEnum } from '../../../helpers/enums'

const REACT_APP_SERVER_AUTH = process.env.REACT_APP_SERVER_AUTH
const PROTOCOL = process.env.REACT_APP_PROTOCOL
const HOST = process.env.REACT_APP_HOST
const PORT = process.env.REACT_APP_PORT && `3A${process.env.REACT_APP_PORT}`

/*
	Компонент TopNavigation обертка компонента Navigation
*/
const TopNavigation = () => {
	const toggleSidebar = useSelector(state => state.sidebar.toggleSidebar)
	const { user, currentRole, accessToken } = useSelector(state => state.authUser)
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { i18n } = useTranslation()
	// eslint-disable-next-line
	const changeLanguage = lang => {
		i18n.changeLanguage(lang)
	}
	// eslint-disable-next-line
	let currentLang = i18n.language || 'ru'

	// функция отвечает за смену ролей пользователя
	const handlChangeRole = ev => {
		dispatch(updateCurrentRole(user.roles, ev.target.value))
		navigate('/')
	}

	const getRoleList = () => {
		const keyRoleList = Object.keys(userRoleEnum)

		const roleNames = user.roles.map((role, i) => {
			const userRole = keyRoleList.find(key => userRoleEnum[key].id === role)
			return (
				<option key={`${i}_role`} value={role}>
					{userRoleEnum[userRole].name[i18n.language]}
				</option>
			)
		})

		return roleNames
	}

	const handleLogout = e => {
		//https://oauth.tpu.ru/auth/logout?redirect=http://return.url
		const port = PORT !== '' ? `%${PORT}` : ''
		dispatch(removeAuthUser())
		window.location.href = `${REACT_APP_SERVER_AUTH}/auth/logout?redirect=${PROTOCOL}%3A%2F%2F${HOST}${port}`
	}

	useEffect(
		() => {
			let cleanupFunction = false
			if (!cleanupFunction) {
				dispatch(removeDataStaff())
				dispatch(removeDataStudent())

				switch (currentRole) {
					case userRoleEnum.student.id:
						dispatch(getDataStudent(accessToken))
						break
					case userRoleEnum.staff.id:
						dispatch(getDataStaff(accessToken))
						dispatch(getDataWorkInfoStaff(accessToken))
						break
					default:
						break
				}
			}
			return () => (cleanupFunction = true)
		},
		// eslint-disable-next-line
		[currentRole]
	)

	const handleClickLink = e => {
		if (window.innerWidth < 1279) {
			dispatch(toggle())
		}
	}

	return (
		<Navigation toggleSidebar={toggleSidebar}>
			<div className='flex items-center'>
				<BsArrowBarLeft
					size='35'
					className={`top-btn-toggle top-navigation-icon vvsm:hidden vsm:hidden xs:hidden sm:block md:block lg:block xl:block 2xl:block ${
						!toggleSidebar && 'rotate-180'
					}`}
					onClick={() => dispatch(toggle())}
				/>
				<MdMenu
					size='35'
					className='top-btn-toggle top-navigation-icon vvsm:block vsm:block xs:block sm:hidden md:hidden lg:hidden xl:hidden 2xl:hidden'
					onClick={() => dispatch(toggle())}
				/>

				<div className='hidden md:flex items-center'>
					<>
						<Tippy content={t('popup-menu.personal_data')} placement='bottom-start' theme='default'>
							<NavLink to='/profile' onClick={handleClickLink}>
								<Img
									src={`https://staff.tpu.ru/person/photo/${user?.lichnost}`}
									classes={['w-[45px]', 'h-[45px]', 'rounded-full', 'border', 'object-cover']}
								/>
							</NavLink>
						</Tippy>
					</>
					<div className='ml-[8px] mr-[8px]'>
						<NavLink to='/profile'>
							<span className='text-black dark:!text-white'>
								{`${user?.name?.last[`${i18n.language}`]} ${user?.name?.first[`${i18n.language}`]} ${
									user?.name?.middle[`${i18n.language}`]
								}`}
							</span>
						</NavLink>
					</div>
				</div>
			</div>

			<div className='flex items-center'>
				<Tippy content='Выбрать роль' placement='bottom-end' theme='default'>
					<select
						className='select_language mx-1 rounded border-2 cursor-pointer'
						onChange={handlChangeRole}
						defaultValue={Number(currentRole)}
					>
						{getRoleList()}
					</select>
				</Tippy>

				{/* <Tippy content='Сменить язык' placement='bottom-end' theme='default'>
					{currentLang === 'ru' ? (
						<div
							className='ml-2 cursor-pointer font-bold text-[20px] text-gray-500 hover:text-g_color_1 dark:text-white dark:hover:text-g_color_1'
							onClick={() => changeLanguage('en')}
						>
							EN
						</div>
					) : (
						<div
							className='ml-2 cursor-pointer font-bold text-[20px] text-gray-500 hover:text-g_color_1 dark:text-white dark:hover:text-g_color_1'
							onClick={() => changeLanguage('ru')}
						>
							RU
						</div>
					)}
				</Tippy> */}

				<div>
					<ThemeIcon />
				</div>

				<div className='mr-2'>
					<Tippy content='Выйти' placement='bottom-end' theme='default'>
						<NavLink to='/'>
							<BsDoorOpenFill
								size='28'
								onClick={handleLogout}
								className='text-gray-500 hover:text-g_color_1 dark:text-white dark:hover:text-g_color_1'
							/>
						</NavLink>
					</Tippy>
				</div>
				{/* <FaRegBell size='24' className='top-navigation-icon' /> */}
			</div>
		</Navigation>
	)
}

/*
	Компонент Navigation отображает верхную навигационную панель сайта
*/
const Navigation = props => {
	const classNames =
		'top-navigation w-full ' + (props.toggleSidebar ? 'top-navigation__toggle_open' : 'top-navigation__toggle_close')
	return <div className={classNames}>{props.children}</div>
}

/*
	компонет ThemeIcon отечает за тему сайта
*/
const ThemeIcon = () => {
	const [darkTheme, setDarkTheme] = useDarkMode()
	const handleMode = () => setDarkTheme(!darkTheme)
	return (
		<Tippy content='Сменить тему' placement='bottom-end' theme='default' animation='fade'>
			<span onClick={handleMode}>
				{darkTheme ? (
					<FaMoon size='24' className='top-navigation-icon dark:text-white dark:hover:text-g_color_1' />
				) : (
					<FaSun size='24' className='top-navigation-icon dark:text-white dark:hover:text-g_color_1' />
				)}
			</span>
		</Tippy>
	)
}

export default TopNavigation
