import Title from '@components/UI/Title'
import Table from '@components/UI/Table'
import AlertMessage from '@components/UI/AlertMessage'
import btnWithModalForm from '@components/UI/HOC/btnWithModalForm'
//icons
import { FaAddressCard, FaLock, FaRegHdd } from 'react-icons/fa'
//translate
import { useTranslation } from 'react-i18next'

const sinceIdentifiers = [
	{
		id: 1,
		id_name: 'Author ID',
		icon: '',
		name: 'Scopus',
		link: 'https://www.scopus.com/authid/detail.uri?authorId=55543300100',
		identifier: '55543300100',
		verified: false,
		apiUrl: () => {}
	},
	{
		id: 2,
		id_name: 'Researcher ID',
		icon: '',
		name: 'Web of science',
		link: 'http://www.researcherid.com/rid/F-9839-2014',
		identifier: 'F-9839-2014',
		verified: false,
		apiUrl: () => {}
	},
	{
		id: 3,
		id_name: 'ORCID ID',
		icon: '',
		name: 'ORCID',
		link: '#',
		identifier: '-',
		verified: false,
		apiUrl: () => {}
	},
	{
		id: 4,
		id_name: 'Author ID',
		icon: '',
		name: 'Elibrary AuthorID',
		link: 'https://www.elibrary.ru/author_profile.asp?id=753102',
		identifier: '753102',
		verified: true,
		apiUrl: () => {}
	},
	{
		id: 5,
		id_name: 'SPIN',
		icon: '',
		name: 'Elibrary Spin',
		link: 'https://www.elibrary.ru/author_profile.asp?id=753102',
		identifier: '1668-4866',
		verified: false,
		apiUrl: () => {}
	}
]

/*
    функция ScientificIdentifiers выводит данные по научным идентификаторам пользователя
*/
const ScientificIdentifiers = () => {
	const { t } = useTranslation()
	return (
		<>
			<Title tag='h3' title={t('pages.personal_data.online.scientific_identifiers')} className='mb-2' />
			<Table classes='mb-3'>
				<thead>
					<tr>
						<td className='min-w-[160px]'>
							{t('pages.personal_data.online.title_name_table')} / {t('pages.personal_data.online.value_table')}
						</td>
						{/* <td className='min-w-[110px]'>{t('pages.personal_data.online.value_table')}</td> */}
						<td className='min-w-[170px]'></td>
					</tr>
				</thead>
				<tbody>
					{sinceIdentifiers.map(ident => {
						const ChangeIdentificatorForm = btnWithModalForm(FormChangeIdentificator, {
							title: `${t('modal_window.title_modal_change_data')} ${ident.name}`,
							btn_text: '',
							btn_icon: 'PencilAlt',
							btn_type: 'primary',
							dataBsTarget: 'IdentificatorForm'
						})
						return (
							<tr key={ident.id}>
								<td className='align-middle'>
									<FaAddressCard size='18' />
									&nbsp;&nbsp;
									<a href={ident.link} target='_blank' rel='noreferrer'>
										{ident.name}
									</a>
									&nbsp;
									<br />
									<FaRegHdd size='18' />
									&nbsp; {ident.id_name}
								</td>
								{/* <td className='align-middle'>{ident.id_name}</td> */}
								<td>
									<div className='input-group my-1'>
										<input type='text' className='form-control' disabled value={ident.identifier} />
										{ident.verified ? (
											<button type='button' className="btn btn-secondary" disabled>
												<FaLock size='20' />
											</button>
										) : (
											<ChangeIdentificatorForm />
										)}
									</div>
								</td>
							</tr>
						)
					})}
				</tbody>
			</Table>
			<AlertMessage status='warning'>
				<p className='my-2 text-[14px]'>
					Если вы хотите изменить научный индетификатор после утверждения, то вам необходимо обратиться в 'Отдел
					портальных решений'.
				</p>
			</AlertMessage>
		</>
	)
}

const FormChangeIdentificator = ({ apiUrl, handleCloseModal }) => {
	const { t } = useTranslation()
	return (
		<div className='modal-footer'>
			<button type='button' className="btn btn-secondary" onClick={apiUrl}>
				{t('modal_window.save')}
			</button>
			<button type='button' className="btn btn-secondary" onClick={handleCloseModal}>
				{t('modal_window.no')}
			</button>
		</div>
	)
}

export default ScientificIdentifiers
