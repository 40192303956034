// /* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	toggleSidebar: window.innerWidth > 1280,
	sidebarData: [],
	fetchSidebarStatus: 'idle'
}

const sidebarSlice = createSlice({
	name: 'sidebar',
	initialState,
	reducers: {
		fetching: (state, action) => {
			state.fetchSidebarStatus = 'loading'
		},

		fetchingError: {
			reducer: (state, action) => {
				state.fetchSidebarStatus = action.payload
			},
			prepare: strError => {
				return {
					payload: strError || 'Произошла ошибка!!!'
				}
			}
		},

		fetchingSuccess: {
			reducer: (state, action) => {
				state.fetchSidebarStatus = 'idle'
				state.sidebarData = action.payload
			},
			prepare: sidebarData => {
				return { payload: sidebarData }
			}
		},

		toggle: (state, action) => {
			state.toggleSidebar = !state.toggleSidebar
		}
	}
})

const { actions, reducer } = sidebarSlice

export const { fetching, fetchingError, fetchingSuccess, toggle } = actions

export default reducer
