import AlertMessage from '@components/UI/AlertMessage'

import { useTranslation } from 'react-i18next'

/*
    ???
*/
const ResponseWithErrors = ({ message, code, status = 'danger' }) => {
	const { t } = useTranslation()
	let errorMessage = message

	if (code === 401 && (message === 'Требуется токен' || message === 'Нет доступа, требуется обновить токен')) {
		errorMessage = t('errors.session_dined')
	}

	// if(code === 401 || code === 403 || code === 404){
	//     return (
	//         <AlertMessage status={status}>{errorMessage}</AlertMessage>
	//     )
	// }

	return <AlertMessage status={status}>{errorMessage}</AlertMessage>
}

export default ResponseWithErrors
