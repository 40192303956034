import { Controller, useFormContext } from 'react-hook-form'
//translate
import { useTranslation } from 'react-i18next'

/*
    компонент SelectInput отвечает за валидирующий select из библиотеки react-hook-form

    На вход компонента <SelectInput /> поступают пять пропсов, где 
        name - имя компонента. Обязательно должен быть уникальным для одной формы!
        label - тайтл данного инпута
        rules - правила по которому будет проходить валидация
        defaultValue - дефолтное значение
        disableOption - заблокированный пункт из селекта
        optionsData - массив данных из которой формируется список селекта
        changeState - данный пропс передает функцию, которая, в свою очередь, отслеживает стейты данного инпута. Данные
        стейты, в свою очередь, изменяют ключи в глобальном контексте. Это сделано для того, чтобы отслеживать заполнены 
        были инпуты данной формы или нет, чтобы при заполнение одной формы автоматически появлялась для заполнения другая форма.
        Пример можно посмотреть здесь: 'src\widgets\MyProjectsCkp\MyProjects\subpages\AddProject\index.jsx'
        По дефолту changeState идет как пустая функция
            Пример использования компонета <SelectInput />:
            <SelectInput
                name='date_start_input'
                label={t('widgets.my_projects_ckp.my_projects.add_project.project_start_date')}
                rules={{ required: t('valid_params.required') }}
                disableOption={t('widgets.my_projects_ckp.my_projects.add_project.none_organization')}
                optionsData={
                    resApi.map((item, index) =>(
                        <option key={index} value={item.value}>
                            {item.name}
                        </option>
                    ))
                }
                changeState={() => {changeState(dateValue_1, setDateValue_1)}}
            />
*/
const SelectInput = ({
	name,
	wrapperClasses = 'mb-2 flex flex-col',
	labelClasses = 'mb-1',
	inputGroupClasses = '!text-[14px]',
	inputGroup,
	InputClasses,
	classes,
	label,
	disabled,
	redStar,
	rules = '',
	defaultValue = '',
	disableOption = '---',
	optionsData = '',
	changeState = () => {}
}) => {
	const { t } = useTranslation()

	// state react-hook-form
	const {
		control,
		formState: { errors }
	} = useFormContext()
	const errorMessage = errors[name] ? errors[name].message : null

	return (
		<>
			<div className={`${wrapperClasses}`}>
				<label className={`form-label ${labelClasses}`}>
					{label}{redStar ? <span className='text-red-500 bg-white border-none dark:!bg-gray-700'>*</span> :false}
				</label>
				{inputGroup ? (
					<div className={`input-group ${InputClasses}`}>
						<span className={`input-group-text ${inputGroupClasses}`}>{inputGroup}</span>
						<Controller
							name={name}
							control={control}
							rules={rules}
							render={({ field, fieldState: { error } }) => (
								<select
									defaultValue={defaultValue}
									onChange={e => {
										field.onChange(e)
										changeState(e)
									}}
									className={error ? `form-control form-select form-select-sm is-invalid ${classes}` : `form-control form-select form-select-sm ${classes}`}
									disabled={disabled}
								>
									<option value={defaultValue} disabled>
										{disableOption}
									</option>
									{optionsData.length !== 0 ? optionsData : <option disabled>{t('temp_information.temp_4')}</option>}
								</select>
							)}
						/>
					</div>
				) : (
					<Controller
						name={name}
						control={control}
						rules={rules}
						render={({ field, fieldState: { error } }) => (
							<select
								defaultValue={defaultValue}
								onChange={e => {
									field.onChange(e)
									changeState(e)
								}}
								className={error ? `form-control form-select form-select-sm is-invalid ${classes}` : `form-control form-select form-select-sm ${classes}`}
								disabled={disabled}
								// size='sm'
							>
								<option value={defaultValue} disabled>
									{disableOption}
								</option>
								{optionsData.length !== 0 ? optionsData : <option disabled>{t('temp_information.temp_4')}</option>}
							</select>
						)}
					/>
				)}
			</div>
			<div className='my-1 text-red-600'>
				<small>{errorMessage && <p>{errors[name].message || 'Ошибка!'}</p>}</small>
			</div>
		</>
	)
}

export default SelectInput
