import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useRequestApiEffectOnce } from '../../../../hooks/useRequestApi'
import Table from '@components/UI/Table'
import Title from '@components/UI/Title'
import MainLoader from '@components/Loaders/MainLoader'
import Message from '@components/UI/Message'
//translate
import { useTranslation } from 'react-i18next'

const SERVER_API = process.env.REACT_APP_SERVER_API_YII2
const API_KEY = process.env.REACT_APP_API_KEY

/*
    функция InfoFamily подтягивает данные по api о семье пользователя
*/
const InfoFamily = () => {
	const { t } = useTranslation()
	const [data, setData] = useState([])
	const [error, setError] = useState(false)
	const accessToken = useSelector(store => store.authUser.accessToken)

	const { resApi, isLoading, message, setMessage } = useRequestApiEffectOnce({
		url: `${SERVER_API}/portal/family?apiKey=${API_KEY}`,
		headers: { token: accessToken },
		otherApi: true
	})

	const prepareData = response => {
		if (response?.data?.code === 200) {
			Array.isArray(response?.data?.data) && response?.data?.data.length !== 0 && setData(response.data.data)
			// console.log(response.data?.data)
		} else if (response.data.code === 429) {
			setError(true)
			setMessage(<Message key_message='errors.page_429' alert alert_status='danger' />)
		} else {
			setError(true)
			setMessage(<Message key_message='errors.data_load' alert alert_status='danger' />)
		}
	}

	useEffect(() => {
		resApi && prepareData(resApi)
		// eslint-disable-next-line
	}, [resApi])

	const TableContent = () =>
		data && Array.isArray(data) && data.length > 0 ? (
			<Table>
				<tbody>
					{data.map(item => (
						<tr key={`${item.lichnost_id}_parents`}>
							<td className='w-[10%]'>{item.stepen}</td>
							<td className='w-[90%]'>{item.fio}</td>
						</tr>
					))}
				</tbody>
			</Table>
		) : (
			<Message alert alert_status='secondary' key_message='errors.data_not_found' />
		)

	return (
		<>
			<Title tag='h3' title={t('pages.personal_data.general_info.relatives')} />
			{isLoading ? <MainLoader /> : error ? <div>{message}</div> : <TableContent />}
		</>
	)
}

export default InfoFamily
