import { useState } from 'react'
// import { useSelector } from 'react-redux'
// import { Modal, Button } from 'react-bootstrap'
// import { Img } from '@components/UI/Img'
import Title from '@components/UI/Title'
// import btnWithModalForm from '@components/UI/HOC/btnWithModalForm'
// import ImageEditor from '@components/ImageEditor'
//icons
import { FaGlobe } from 'react-icons/fa'
//translate
import { useTranslation } from 'react-i18next'

// const wikiTPU = 'https://wiki.tpu.ru/wiki/Седнев_Дмитрий_Андреевич'

/*
    функция PersonalSite выводит ссылку на персональный сайт тпу
*/
const PersonalSite = () => {
	const { t } = useTranslation()
	const [isPersonalSite] = useState(true)
	// const user = useSelector(state => state.authUser.user)

	// const ProfileInfo = {
	//     image: `https://staff.tpu.ru/personal/show-photo-personality?type=5&lid=${user?.lichnost}`,
	// }

	// const BtnAddPersonalSite = btnWithModalForm(AddPersonalSiteForm, {
	//         title:t('pages.personal_data.online.button_apply_application'),
	//         btn_text: t('pages.personal_data.online.button_apply_application'),
	//         btn_icon: 'Plus',
	//     }
	// )

	return (
		<>
			<Title tag='h3' title={t('pages.personal_data.online.personal_site')} className='mb-2' />
			{/* {isPersonalSite ? (   
                    <div className='profile-card'>
                        <div className='relative'>
                            <Img src={ProfileInfo.image} classes={['rounded-[3px]', 'w-[200px]']} alt={'Personal site'}/>
                        </div>
                        <div>
                            <p className='text-g_color_1 text-xl mb-1'>Корпоративный портал ТПУ</p>
                            <hr className='my-1 '/>
                            <p>Описание данного блока</p>
                            <hr className='my-1'/>
                            <p>{t('image_editor.title_btn')}</p>
                            <div className='mt-1'><ImageEditor photo_type_id={-5} width={400} height={400}/></div>
                        </div>
                    </div> 
                    ) : <BtnAddPersonalSite/>  
                } */}
			{isPersonalSite && (
				<>
					<FaGlobe size='20' className='mr-2' />
					<a href='https://portal.tpu.ru/SHARED/s/SEDNEV'>https://portal.tpu.ru/SHARED/s/SEDNEV</a>
				</>
			)}
		</>
	)
}

// const AddPersonalSiteForm = ({apiUrl, handleCloseModal}) => {
//     const { t } = useTranslation()
//     return (
//         <>
//             <Modal.Body>
//             <p>test</p>
//             </Modal.Body>
//             <Modal.Footer>
//                 <Button variant='secondary' onClick={apiUrl}>{t('modal_window.save')}</Button>
//                 <Button variant='secondary' onClick={handleCloseModal}>{t('modal_window.no')}</Button>
//             </Modal.Footer>
//         </>
//     )
// }

export default PersonalSite
