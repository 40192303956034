// eslint-disable-next-line
import { NavLink, Link } from 'react-router-dom'
import './LinkBlock.css'

import styled, { keyframes } from 'styled-components'
import { zoomIn } from 'react-animations'

const zoomInAnimation = keyframes`${zoomIn}`

const ZoomInDiv = styled.div`
	animation: ${p => p.timeAnimate}ms ${zoomInAnimation};
`

/*
    компонент LinkBlock отображает карточку со ссылкой
*/
const LinkBlock = ({ title, icon, href, classes, timeAnimate = 300, imgNum = undefined, target = undefined }) => {
	return (
		// проверяем это алиас или ссылка
		<>
			{!href.match(/https?/g) ? (
				<ZoomInDiv timeAnimate={timeAnimate}>
					<NavLink to={`${href}`} className={`link-block ${classes && classes}`}>
						{
							<div
								className='backImg'
								// style={imgNum && { backgroundImage: `url('./images/fonLinkBlock/${imgNum}.jpg'`}}
							></div>
						}
						{icon}
						<span className='text-center xs:text-left'>{title}</span>
					</NavLink>
				</ZoomInDiv>
			) : (
				<ZoomInDiv timeAnimate={timeAnimate}>
					<a href={`${href}`} className={`link-block ${classes && classes}`} target={`${target ? target : ''}`}>
						{<div className='backImg'></div>}
						{icon}
						<span className='text-center xs:text-left'>{title}</span>
					</a>
				</ZoomInDiv>
			)}
		</>
	)
}

export default LinkBlock
