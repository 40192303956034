import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useEffectOnce } from '../../../../hooks/useEffectOnce'
import { requestGetApi } from '@services/api/requestServerApi'
import Table from '@components/UI/Table'
import MainLoader from '@components/Loaders/MainLoader'
import Title from '@components/UI/Title'
import Message from '@components/UI/Message'
//translate
import { useTranslation } from 'react-i18next'

/*
    функция AcademicTitle подтягивает данные по api об ученых званиях пользователя
*/
const AcademicTitle = () => {
	const { t } = useTranslation()
	const [resApi, setResApi] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [isError, setIsError] = useState(false)
	const [message, setMessage] = useState(null)
	const accessToken = useSelector(store => store.authUser.accessToken)

	const prepareData = async response => {
		// console.log(response)
		if (response.data.code === 200) {
			if (response.data.data) {
				// console.log(response.data?.data)
				setResApi(response.data.data)
			} else {
				setIsError(true)
				setMessage(<Message key_message='errors.data_load' alert alert_status='danger' />)
			}
		} else if (response.data.code === 429) {
			setIsError(true)
			setMessage(<Message key_message='errors.page_429' alert alert_status='danger' />)
		} else {
			setIsError(true)
			setMessage(<Message key_message='errors.data_load' alert alert_status='danger' />)
		}
	}

	useEffectOnce(() => {
		requestGetApi(`user/science/rank`, { headers: { 'X-TOKEN': accessToken } }, prepareData, setIsLoading, setIsError)
	}, [])

	const TableContent = ({ data }) =>
		data && Array.isArray(data) && data.length > 0 ? (
			<Table striped bordered>
				<thead>
					<tr>
						<th className='w-[20%]'>{t('pages.personal_data.science.tables.1.columns.0')}</th>
						<th className='w-[70%]'>{t('pages.personal_data.science.tables.1.columns.1')}</th>
						<th className='w-[70%]'>{t('pages.personal_data.science.tables.1.columns.2')}</th>
						<th className='w-[70%]'>{t('pages.personal_data.science.tables.1.columns.3')}</th>
						<th className='w-[70%]'>{t('pages.personal_data.science.tables.1.columns.4')}</th>
					</tr>
				</thead>
				<tbody>
					{data.map((item, index) => (
						<tr key={`${index}_rank`}>
							<td>{item.rank}</td>
							<td>{item.specialization}</td>
							<td>{item.date}</td>
							<td>{item.series}</td>
							<td>{item.number}</td>
						</tr>
					))}
				</tbody>
			</Table>
		) : (
			<Message alert alert_status='secondary' key_message='errors.data_not_found' />
		)

	return (
		<>
			<Title tag='h3' title={t('pages.personal_data.science.tables.1.title')} />
			{isLoading ? <MainLoader /> : isError ? <div>{message}</div> : <TableContent data={resApi} />}
		</>
	)
}

export default AcademicTitle
