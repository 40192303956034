// /* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'
import { saveAuthToStorage } from '../../helpers/utils.js'
import { userRoleEnum } from '../../helpers/enums.js'

const initialState = {
	accessToken: null,
	refreshToken: null,
	user: null,
	anotherUser: false,
	currentRole: userRoleEnum.guest.id,
	fetchAuthUserStatus: 'idle'
}

const authUserSlice = createSlice({
	name: 'authUser',
	initialState: initialState,

	reducers: {
		fetching: (state, action) => {
			state.fetchAuthUserStatus = 'loading'
		},

		fetchingError: {
			reducer: (state, action) => {
				state.fetchAuthUserStatus = action.payload
			},
			prepare: strError => {
				return {
					payload: strError || 'Произошла ошибка!!!'
				}
			}
		},

		fetchingSuccess: {
			reducer: (state, action) => {
				state.fetchAuthUserStatus = 'idle'
				state.accessToken = action.payload.accessToken
				state.refreshToken = action.payload.refreshToken
				saveAuthToStorage(state)
			},
			prepare: tokens => {
				return {
					payload: {
						accessToken: tokens.access_token,
						refreshToken: tokens.refresh_token
					}
				}
			}
		},

		setUser: {
			reducer: (state, action) => {
				state.fetchAuthUserStatus = 'idle'
				state.user = action.payload.authUser
				state.currentRole = action.payload.role
				state.anotherUser = action.payload.anotherUser
				saveAuthToStorage(state)
			},
			prepare: authUser => {
				let role
				let anotherUser
				// localStorage.currentRole - из кеша подтягиваем роль, которую выбрал пользователь
				// если у пользователя нет currentRole, то загружаем первую его роль из массива ролей пользователя
				// запись роли в localStorage.currentRole происходит в файле utils.js
				if (!localStorage.currentRole) {
					role = Number(authUser.roles[0])
				} else {
					role = Number(localStorage.currentRole)
				}
				// localStorage.anotherUser - из кеша подтягиваем ключ anotherUser, который показывает зашел ли суперадмин
				// под другим пользователем, чтобы показать специальную плашку.
				// 1 - не зашел
				// 2 - зашел
				// были выбраны эти цифры, тк возникла проблема с функцией storage() utils.js
				if (!localStorage.anotherUser) {
					anotherUser = Number(authUser.anotherUser)
				} else {
					anotherUser = Number(localStorage.anotherUser)
				}
				return {
					payload: { authUser, role, anotherUser }
				}
			}
		},

		removeAuthUser: (state, action) => {
			state.fetchAuthUserStatus = 'idle'
			state.accessToken = null
			state.refreshToken = null
			state.user = null
			state.currentRole = userRoleEnum.guest.id
			state.anotherUser = null
			saveAuthToStorage(null)
		},

		updateCurrentRole: {
			reducer: (state, action) => {
				state.fetchAuthUserStatus = 'idle'
				state.currentRole = action.payload
				saveAuthToStorage(state)
			},
			prepare: (roles, roleId) => {
				let role = userRoleEnum.guest.id
				if (roles.find(role => Number(role) === Number(roleId))) {
					role = +roleId
				}
				return {
					payload: role
				}
			}
		}
	}
})

const { actions, reducer } = authUserSlice

export const { fetching, fetchingError, fetchingSuccess, setUser, removeAuthUser, updateCurrentRole } = actions

export default reducer
