import Card from '@components/UI/Card'
import Title from '@components/UI/Title'
// import btnWithModalForm from '@components/UI/HOC/btnWithModalForm'
import EducationList from './EducationList'
import TrainingList from './TrainingList'
import LanguageList from './LanguageList'
//translate
import { useTranslation } from 'react-i18next'

/*
    функция EducationProfilePage отвечает за рендеринг страницы 'Образование'
*/
const EducationProfilePage = () => {
	const { t } = useTranslation()

	return (
		<div>
			<>
				<Title tag='h2' title={t('pages.personal_data.education.title_page')} />
				<Card>
					<EducationList />
				</Card>
				<Card>
					<TrainingList />
				</Card>
				{/* <CertificateForm /> */}
				<Card>
					<LanguageList />
				</Card>
			</>
		</div>
	)
}

export default EducationProfilePage
