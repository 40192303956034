import { FaTimes } from 'react-icons/fa'

/*
    Данный кнопка предназначена для отображения в компоненте <Modal.Footer> в основном в модальных окнах
*/
const CloseBtn = ({ type, onClick, classes, icon = true, disabled = false, children }) => {
	return (
		<button
			type={type}
			onClick={onClick}
			className={`btn btn-sm close-btn btn-secondary ${classes}`}
			disabled={disabled}
			data-bs-dismiss='modal'
		>
			{icon && <FaTimes size='14' className='mr-1' />}
			{children}
		</button>
	)
}

export default CloseBtn
