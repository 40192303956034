import { httpRequest } from '@services/http/httpRequest.js'
import { fetching, fetchingError, fetchingSuccess } from '../slices/sidebarSlice'

const SERVER_API = process.env.REACT_APP_SERVER_API

export const fetchSidebarData =
	(token, role = 2, lang = 97) =>
	async dispatch => {
		const url = `${SERVER_API}/main-menu?token=${token}&role=${role}&lang=${lang}`

		try {
			dispatch(fetching())
			const response = await httpRequest({ url })
			//console.log(response)

			if (response.code && response.code !== 200) {
				throw new Error('Ошибка загрузки данных')
			}
			dispatch(fetchingSuccess(response.data))
		} catch (err) {
			//console.log(err)
			dispatch(fetchingError(err.message))
		}
	}
