import './GridWrapper.css'

/*
На вход компонента <GridWrapper /> поступают два пропса, где 
    classes - стили css
    children - принимает текст данного сообщения (или html код)
        Пример использования компонета <GridWrapper />:
        <GridWrapper status='danger' >
            *текст (html код) данного сообщения*
        </GridWrapper>
*/

const GridWrapper = ({ classes = [], children }) => {
	return <div className={`grid-wrapper ${classes.join(' ')}`}>{children}</div>
}

export default GridWrapper
