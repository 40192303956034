import { Suspense, useEffect, useRef } from 'react'
import { Routes, Route, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { storage } from './helpers/utils.js'
import { authEnum } from './helpers/enums'
import { getTokens, login, refreshTokens } from './store/actions/authUserAction'
import { Helmet } from 'react-helmet-async'

import MainDashboardLayout from './layouts/Dashboard/MainLayout'
import MainPage from './pages/Dashboard/MainPage'
import ProfilePage from './pages/Dashboard/ProfilePage/index'
import ContentPage from './pages/Dashboard/ContentPage'
import NavigatePage from './pages/Dashboard/ProfilePage/NavigatePage'
import InfoProfilePage from './pages/Dashboard/ProfilePage/InfoProfilePage'
import ContactsProfilePage from './pages/Dashboard/ProfilePage/ContactsProfilePage'
import EducationProfilePage from './pages/Dashboard/ProfilePage/EducationProfilePage'
import LinksOnlineProfilePage from './pages/Dashboard/ProfilePage/LinksOnlineProfilePage'
import ScienceProfilePage from './pages/Dashboard/ProfilePage/ScienceProfilePage'
import AwardsProfilePage from './pages/Dashboard/ProfilePage/AwardsProfilePage'
import SportAwardsProfilePage from './pages/Dashboard/ProfilePage/SportAwardsProfilePage'
import SocialActivityProfilePage from './pages/Dashboard/ProfilePage/SocialActivityProfilePage'
import EmploymentHistoryProfilePage from './pages/Dashboard/ProfilePage/EmploymentHistoryProfilePage'
import AvatarEditorPage from './pages/Dashboard/ProfilePage/AvatarEditorPage'
import KindergartenPage from './pages/Dashboard/ProfilePage/KindergartenPage'
import { NotFoundPage } from './pages/NotFoundPage'
import Overlay from '@components/UI/Overlay'
import LogoTpu from '@components/LogoTpu'
import Message from '@components/UI/Message'
import './app.css'

function App() {
	const location = useLocation()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	// Извлекаем некоторые параметры запроса из url
	const [searchParams] = useSearchParams()
	const codeGetParam = searchParams.get('code') || ''
	const stateGetParam = searchParams.get('state') || ''

	const refreshToken = storage(authEnum.refreshToken)

	let firstMountRef = useRef(false)

	// Проверка на авторизацию.
	useEffect(
		() => {
			if (firstMountRef.current === false) {
				firstMountRef.current = true

				if (!refreshToken) {
					if (codeGetParam.length > 0 && stateGetParam.length > 0) {
						dispatch(getTokens(codeGetParam, stateGetParam))
						navigate('/')
					} else {
						login()
					}
				} else {
					const fromPage = location.pathname || '/'
					// Обновить токен (если он протух) и вернуть на текущую страницу, где находится пользователь
					refreshAccessToken(() => navigate(fromPage, { replace: true }))
				}
			}
		},
		// eslint-disable-next-line
		[]
	)

	const refreshAccessToken = async (redirect = null) => {
		dispatch(refreshTokens(refreshToken))
		if (redirect) {
			redirect()
		}
	}

	return (
		<Suspense
			fallback={
				<Overlay>
					<LogoTpu widthBox='3' heighBox='3' animate />
				</Overlay>
			}
		>
			<div className='App'>
				<RenderStart />
			</div>
		</Suspense>
	)
}

const RenderStart = () => {
	const { user, fetchAuthUserStatus } = useSelector(state => state.authUser)

	if (user === null) {
		// Идет загрузка
		if (fetchAuthUserStatus === 'loading') {
			return (
				<Overlay>
					<LogoTpu widthBox='3' heighBox='3' animate />
				</Overlay>
			)
		}
		// Показать сообщение с ошибкой
		else if (fetchAuthUserStatus !== 'idle') {
			return (
				<div className='m-4'>
					<Message alert alert_status='danger'>
						{fetchAuthUserStatus}
					</Message>
				</div>
			)
		}
	} else {
		return (
			<>
				<Helmet>
					<title>ТПУ - Личный кабинет</title>
				</Helmet>
				<Routes>
					<Route path='/' element={<MainDashboardLayout />}>
						<Route index element={<MainPage />} />
						<Route path='profile/' element={<ProfilePage />}>
							<Route index element={<NavigatePage />} />
							<Route path='info' element={<InfoProfilePage />} />
							<Route path='avatar_editor' element={<AvatarEditorPage />} />
							<Route path='contacts' element={<ContactsProfilePage />} />
							<Route path='education' element={<EducationProfilePage />} />
							<Route path='links-online' element={<LinksOnlineProfilePage />} />
							<Route path='science' element={<ScienceProfilePage />} />
							<Route path='awards' element={<AwardsProfilePage />} />
							<Route path='sport' element={<SportAwardsProfilePage />} />
							<Route path='social-activity' element={<SocialActivityProfilePage />} />
							<Route path='employment-history' element={<EmploymentHistoryProfilePage />} />
							<Route path='kindergarten' element={<KindergartenPage />} />
						</Route>
						<Route path='page/:alias/*' element={<ContentPage />} />
						<Route path='*' element={<NotFoundPage />} />
					</Route>
				</Routes>
				{/* <Message alert alert_status='warning' classes='my-2 mx-4'>
					Сайт находится в процессе разработки. Используйте текущую версию&nbsp;
					<a href='https://portal.tpu.ru/desktop'>личного кабинета ТПУ</a>.
				</Message> */}
			</>
		)
	}
}

export default App
