import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { requestGetApi } from '@services/api/requestServerApi'
import { languageEnum } from '../../../helpers/enums'
import GridWrapper from '@components/UI/GridWrapper'
import LinkBlock from '@components/LinkBlock'
import ResponseWithErrors from '@components/UI/HOC/ResponseWithErrors'
import MainLoader from '@components/Loaders/MainLoader'
import * as FontAwesome from 'react-icons/fa'
//translate
import { useTranslation } from 'react-i18next'

const classesGrid = [
	'grid-cols-2',
	'gap-1',
	'self-start',
	'lg:grid-cols-3',
	'xl:grid-cols-3',
	'2xl:grid-cols-4',
	'lg:col-start-1'
]

/*
    функция PersonalMenu рендерит персональное меню пользователя в зависимости от роли (Список карточек)
*/
const PersonalMenu = () => {
	const { t, i18n } = useTranslation()
	const [resApi, setResApi] = useState([])
	const [isError, setIsError] = useState(false)
	const [errorMessage, setErrorMessage] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const { accessToken, currentRole } = useSelector(store => store.authUser)

	const prepareData = response => {
		// console.log(response)
		if (response?.data?.code === 200) {
			Array.isArray(response?.data?.data) && response?.data?.data.length !== 0 && setResApi(response.data.data)
		} else {
			setIsError(true)
			setErrorMessage(response?.data)
		}
	}

	useEffect(() => {
		requestGetApi(
			`menu/personal?lang=${languageEnum[i18n.language]}&role=${currentRole}`,
			{ headers: { 'X-TOKEN': accessToken } },
			prepareData,
			setIsLoading,
			setIsError
		)
		// eslint-disable-next-line
	}, [i18n.language, currentRole])

	const MenuComponent = () => {
		let imgNumberLinkBlock = 0
		if (isLoading) {
			return (
				<div className='text-center mt-5'>
					<MainLoader />
				</div>
			)
		} else {
			return (
				<GridWrapper classes={classesGrid}>
					{resApi.map((linkBlock, i) => {
						const Icon = FontAwesome[linkBlock.icon]
						imgNumberLinkBlock = imgNumberLinkBlock + 1 < 14 ? imgNumberLinkBlock + 1 : 1
						return (
							<LinkBlock
								key={i}
								title={t(`${linkBlock.text}`)}
								icon={<Icon size='116' />}
								classes={`h-[100%] `}
								// сделать исключение для profile страница 'Мой профиль', тк это статическая страница,
								// чтобы путь был `profile` а не `page/profile`
								href={linkBlock.url === 'page/profile' ? 'profile' : linkBlock.url}
								imgNum={imgNumberLinkBlock}
								target={linkBlock?.target}
							/>
						)
					})}
				</GridWrapper>
			)
		}
	}

	return <>{isError ? <ResponseWithErrors {...errorMessage} /> : <MenuComponent />}</>
}

export default PersonalMenu
