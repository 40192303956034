import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useEffectOnce } from '../../../../hooks/useEffectOnce'
import { requestGetApi } from '@services/api/requestServerApi'
import { ShowDataBtn } from '@components/UI/btns/conf-btns/show-data-btn'
import Title from '@components/UI/Title'
import Table from '@components/UI/Table'
import MainLoader from '@components/Loaders/MainLoader'
import Message from '@components/UI/Message'
import btnWithModalForm from '@components/UI/HOC/btnWithModalForm'
import CloseBtn from '@components/UI/btns/CloseBtn'
//icons
import { FaFileAlt } from 'react-icons/fa'
//translate
import { useTranslation } from 'react-i18next'

/*
    функция academicDegree подтягивает данные по api об ученых степенях пользователя
*/
const AcademicDegree = () => {
	const { t } = useTranslation()
	const [resApi, setResApi] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [isError, setIsError] = useState(false)
	const [message, setMessage] = useState(null)
	const accessToken = useSelector(store => store.authUser.accessToken)

	const prepareData = async response => {
		// console.log(response)
		if (response.data.code === 200) {
			if (response.data.data) {
				// console.log(response.data?.data)
				setResApi(response.data.data)
			} else {
				setIsError(true)
				setMessage(<Message key_message='errors.data_load' alert alert_status='danger' />)
			}
		} else if (response.data.code === 429) {
			setIsError(true)
			setMessage(<Message key_message='errors.page_429' alert alert_status='danger' />)
		} else {
			setIsError(true)
			setMessage(<Message key_message='errors.data_load' alert alert_status='danger' />)
		}
	}

	useEffectOnce(() => {
		requestGetApi(`user/science/degree`, { headers: { 'X-TOKEN': accessToken } }, prepareData, setIsLoading, setIsError)
	}, [])

	const BtnForm = btnWithModalForm(ModalForm, {
		title: t('pages.personal_data.science.modal_title_science'),
		btn_icon: ShowDataBtn.btn_icon,
		btn_type: ShowDataBtn.btn_type,
		btn_size: ShowDataBtn.btn_size,
		icon_size: ShowDataBtn.icon_size,
		btn_classes: ShowDataBtn.btn_classes,
		btn_tooltip_tippy: { status: true, content: t('temp_information.temp_3'), placement: 'top', theme: 'default' },
		dataBsTarget: 'AcademicDegreeForm'
	})

	const TableContent = ({ data }) =>
		data && Array.isArray(data) && data.length > 0 ? (
			<Table striped bordered>
				<thead>
					<tr>
						<th className='w-[20%]'>{t('pages.personal_data.science.tables.0.columns.0')}</th>
						<th className='w-[70%]'>{t('pages.personal_data.science.tables.0.columns.1')}</th>
						<th className=' w-[10%] text-center'>
							<FaFileAlt size='18' />
						</th>
					</tr>
				</thead>
				<tbody>
					{data.map(item => (
						<tr key={item.id}>
							<td>{item.degree}</td>
							<td>{item.science}</td>
							<td className='text-center'>
								<BtnForm data={item.document} />
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		) : (
			<Message alert alert_status='secondary' key_message='errors.data_not_found' />
		)

	return (
		<>
			<Title tag='h3' title={t('pages.personal_data.science.tables.0.title')} />
			{isLoading ? <MainLoader /> : isError ? <div>{message}</div> : <TableContent data={resApi} />}
		</>
	)
}

const ModalForm = ({ data, handleCloseModal }) => {
	const { t } = useTranslation()
	return (
		<>
			<div className='modal-body'>
				<Table>
					<tbody>
						<tr>
							<td>{t('pages.personal_data.science.tables.2.columns.0')}</td>
							<td>{data.council}</td>
						</tr>
						<tr>
							<td>{t('pages.personal_data.science.tables.2.columns.1')}</td>
							<td>{data.date_award}</td>
						</tr>
						<tr>
							<td>{t('pages.personal_data.science.tables.2.columns.2')}</td>
							<td>{data.number_decision}</td>
						</tr>
						<tr>
							<td>{t('pages.personal_data.science.tables.2.columns.3')}</td>
							<td>{data.date_diplom}</td>
						</tr>
						<tr>
							<td>{t('pages.personal_data.science.tables.2.columns.4')}</td>
							<td>{data.series}</td>
						</tr>
						<tr>
							<td>{t('pages.personal_data.science.tables.2.columns.5')}</td>
							<td>{data.number}</td>
						</tr>
						<tr>
							<td>{t('pages.personal_data.science.tables.2.columns.6')}</td>
							<td>{data.organization}</td>
						</tr>
					</tbody>
				</Table>
			</div>
			<div className='modal-footer'>
				<CloseBtn onClick={handleCloseModal}>
					{t('modal_window.close')}
				</CloseBtn>
			</div>
		</>
	)
}

export default AcademicDegree
