import { useEffect } from 'react'
import { useLocalStorage } from './useLocalStorage'

// хук сменяет тему сайта
export const useDarkMode = () => {
	const [enabled, setEnabled] = useLocalStorage('dark-theme')
	const isEnabled = typeof enabled === 'undefined' || enabled

	useEffect(() => {
		const className = 'dark'
		const bodyClass = window.document.body.classList

		!isEnabled ? bodyClass.add(className) : bodyClass.remove(className)
	}, [enabled, isEnabled])

	return [isEnabled, setEnabled]
}
