import './Table.css'

/*
На вход компонента <Table /> поступатет один пропс, где
    classes - стили css
    ishover - Включить эффект hover (true). Выключить false
    children - принимает структуру html таблицы
    striped - разделение строк цветом
    bordered - добавляет рамку таблицы
    Данный компонент подтягивает готовый стиль оформления таблицы данных
        Пример использования компонета <Table />:
        <Table>
            * структура html кода*
        </Table>
*/
const Table = ({ classes = '', ishover = false, striped = false, bordered = false, responsive = true, children }) => {
	return (
		<div className={`${responsive && `table-responsive`}`}>
			<table
				className={`
					table data-table mb-1 ${classes}
					${striped && `table-striped`}
					${bordered && `table-bordered`}
					${ishover && `table-hover`}
				`}
			>
				{children}
			</table>
		</div>
	)
}

export default Table
