import { useState } from 'react'
// import { Modal } from 'react-bootstrap'
import * as FontAwesome from 'react-icons/fa'
import Tippy from '@tippyjs/react'

//HOC
//Gets the value ComponentForm
//Return BUTTON and MODAL WINDOW with included ComponentForm
//Adds the modal window closing functionality to the form component

const btnWithModalForm = (
	ComponentForm,
	{
		title = '', //Title modal window
		btn_text = '', //Button text
		btn_icon = 'Trash', //Button icon from FONT AWESOME
		btn_type = 'secondary', //Type button bootstrap
		btn_classes = '', // css style button
		modal_size = '', // size modal: sm, md, lg, xl
		modal_header = true,
		btn_size = '',
		icon_size = 20, // size icon
		btn_tooltip = '', // default browser tooltip
		btn_tooltip_tippy = { status: false, content: 'Tooltip', placement: 'top', theme: 'default' }, // tooltip from component 'Tippy'
		disabled = false,
		closeButton = false, // close button in top right place
		backdrop = true,
		centered = true,
		updatable = false, // Update parent component in handleClose function
		set_update = () => {}, //Function update parent state
		// Важные атрибуты для кнопки bootstrap 5 открывающее модальное окно
		dataBsToggle = 'modal',
		dataBsTarget = ''
	}
) => {
	return function (props) {
		const [show, setShow] = useState(false)
		const handleClose = () => {
			setShow(false)
			if (updatable) set_update()
		}
		const handleShow = () => setShow(true)
		const Fa = FontAwesome[`Fa${btn_icon}`]

		/*
			Эта функция возвращает idModalForm со случаемым числом в заданном интервале.
			Возвращаемое значение не менее (и может быть равно) min и не более (и не равно) max.
			Это сделанно для того, что если есть таблица с многими полями данными,
			то у каждой кнопки в связки с модальным окном (HOC), должна быть уникальная id
		*/
		function getRandomId(idModalForm) {
			const min = Math.ceil(0)
			const max = Math.floor(1000)
			const randomId = Math.floor(Math.random() * (max - min)) + min // Максимум не включается, минимум включается
			const result = `${idModalForm}-${randomId}`
			return result
		}

		const idModalForm = getRandomId(dataBsTarget)

		return (
			<>
				{btn_tooltip_tippy.status ? (
					<Tippy
						content={btn_tooltip_tippy.content}
						placement={btn_tooltip_tippy.placement}
						theme={btn_tooltip_tippy.theme}
					>
						<button
							type='button'
							className={`btn ${btn_type && `btn-${btn_type}`} ${btn_size && `btn-${btn_size}`} ${btn_classes}`}
							onClick={handleShow}
							disabled={disabled}
							data-bs-toggle={dataBsToggle}
							data-bs-target={`#${idModalForm}`}
						>
							<Fa size={icon_size} /> {btn_text}
						</button>
					</Tippy>
				) : (
					<button
						type='button'
						className={`btn ${btn_type && `btn-${btn_type}`} ${btn_size && `btn-${btn_size}`} ${btn_classes}`}
						onClick={handleShow}
						title={btn_tooltip}
						disabled={disabled}
						data-bs-toggle={dataBsToggle}
						data-bs-target={`#${idModalForm}`}
					>
						<Fa size={icon_size} /> {btn_text}
					</button>
				)}
				{/* <Modal show={show} onHide={handleClose} size={modal_size} backdrop={backdrop} centered={centered}>
					<div className='modal-header'>
						<div className='modal-title h4'>{title}</div>
					</div>
					<ComponentForm {...props} handleCloseModal={handleClose} />
				</Modal> */}
				<div
					className={`modal fade ${show && 'show'}`}
					id={idModalForm}
					// onClick={handleClose}
					tabIndex='-1'
					data-backdrop={backdrop ? 'static' : ''}
				>
					<div className={`modal-dialog ${centered && 'modal-dialog-centered'} ${modal_size && `modal-${modal_size}`}`}>
						<div className='modal-content'>
							<div className='modal-header'>
								{modal_header && <div className='modal-title h4 text-start'>{title}</div>}
							</div>
							<div>
								<ComponentForm {...props} handleCloseModal={handleClose} />
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}

export default btnWithModalForm
