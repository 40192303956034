import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { requestGetApi, requestPostApi } from '@services/api/requestServerApi'
import NumberFormat from 'react-number-format'
import SelectInput from '@components/UI/ElementsReusable/SelectInput'
import Message from '@components/UI/Message'
import MainLoader from '@components/Loaders/MainLoader'
import SaveBtn from '@components/UI/btns/SaveBtn'
import CloseBtn from '@components/UI/btns/CloseBtn'
//translation
import { useTranslation } from 'react-i18next'

const FormAddNumber = ({ apiUrl, handleCloseModal }) => {
	const { t } = useTranslation()
	const [value, setValue] = useState(null)
	const accessToken = useSelector(state => state.authUser.accessToken)
	const [defaultCountryCode, setDefaultCountryCode] = useState('37')
	const [resApiCodeOfCountry, setResApiCodeOfCountry] = useState([])
	// eslint-disable-next-line
	const [isErrorCodeOfCountry, setIsErrorCodeOfCountry] = useState([])
	// eslint-disable-next-line
	const [isLoadingCodeOfCountry, setIsLoadingCodeOfCountry] = useState([])

	//Отправка формы
	const [isSuccess, setIsSuccess] = useState(false)
	// eslint-disable-next-line
	const [isLoading, setIsLoading] = useState(false)
	// eslint-disable-next-line
	const [isError, setIsError] = useState(false)
	const [message, setMessage] = useState(null)

	const prepareData = response => {
		if (response?.data?.code === 200) {
			// console.log(response.data.data)
			Array.isArray(response?.data?.data) &&
				response?.data?.data.length !== 0 &&
				setResApiCodeOfCountry(response.data.data)
		}
	}

	useEffect(() => {
		requestGetApi(
			'tool/phone/country-code',
			{ headers: { 'X-TOKEN': accessToken } },
			prepareData,
			setIsLoadingCodeOfCountry,
			setIsErrorCodeOfCountry,
			setMessage
		)
		// eslint-disable-next-line
	}, [])

	// state react-hook-form
	const methods = useForm({
		// defaultValues: {
		// 	// type: null
		// }
	})

	const {
		setValue: setPhoneForm,
		register,
		control,
		handleSubmit,
		// reset,
		resetField,
		watch,
		formState: { errors }
	} = methods

	const prepareDataSubmit = response => {
		// console.log(response)
		if (response?.data?.code === 200 && response?.data?.data) {
			setIsSuccess(true)
			setMessage(<Message alert alert_status='success' key_message='success.data_send' />)
		} else {
			setIsError(true)
			setMessage(<small className='text-red-600'>{response?.data?.message}</small>)
		}
	}

	const handleSubmitData = data => {
		setMessage(
			<div className='mt-2'>
				<MainLoader />
			</div>
		)
		requestPostApi(
			'user/employee/phone',
			new URLSearchParams({ ...data }),
			{
				headers: {
					'X-TOKEN': accessToken,
					'Content-Type': 'application/x-www-form-urlencoded'
				}
			},
			prepareDataSubmit,
			setIsLoading,
			setIsError
		)
	}

	const onSubmit = data => {
		// console.log(data)
		if (Object.keys(data).length) {
			const createPhone = {
				type: Number(data.type),
				place: data.place ? Number(data.place) : (Number(data.type) === 2 && 3) || (Number(data.type) === 3 && 2) || 0,
				country_id: data.country_id ? data.country_id : 0,
				city_code: data.city_code ? data.city_code : 0,
				phone: data.phone ? data.phone.replace(/[^+\d]/g, '') : null
			}
			handleSubmitData(createPhone)
			// console.log(createPhone)
			// reset('', {
			// 	keepValues: false,
			// 	keepDefaultValues: true
			// })
			// handleCloseModal()
			setPhoneForm('phone', '')
		}
	}

	useEffect(() => {
		const subscription = watch((value, { name, type }) => {
			// console.log(value, name, type)
			if (name === 'type') {
				resetField('phone')
				setValue(value.type)
				value.type !== '1' && resetField('place')
				resetField('city_code')
				value.type === '3' && resetField('country_id')
			}
			if (name === 'country_id') {
				setDefaultCountryCode(value.country_code)
			}
		})
		return () => subscription.unsubscribe()
		// eslint-disable-next-line
	}, [watch])

	const InputPhone = ({ name, rules, placeholder, format, defaultValue = null, autoFocus = false, isAllowed}) => {
		return (
			<>
				<Controller
					name={name}
					control={control}
					defaultValue={defaultValue}
					rules={rules}
					render={({ field, fieldState: { error } }) => (
						<NumberFormat
							className={error ? 'form-control is-invalid' : 'form-control'}
							placeholder={placeholder}
							format={format}
							mask='_'
							autoFocus={autoFocus}
							isAllowed={isAllowed}
							{...field}
						/>
					)}
				/>
			</>
		)
	}

	const CodeOfCountry = ({ resApi, defaultValue }) => {
		return (
			<>
				<label className='text-[12px] vvsm:hidden vsm:hidden sm:block'>
					{t('pages.personal_data.contacts.form_add_phone_number.code_of_country')}
					{<span className='text-red-500'>*</span>}
				</label>
				<select
					value={defaultValue}
					className='form-select form-select-sm py-2 h-[34.25px] max-w-[120px]'
					{...register('country_id')}
				>
					{resApi.map((code, i) => (
						<option key={`phone_code_${i}`} value={code.id}>
							+ {code.code} {code.name}
						</option>
					))}
				</select>
			</>
		)
	}

	const CityPhone = ({ register }) => {
		return (
			<>
				<div className='mb-1'>
					<label className='mb-1'>
						{t('pages.personal_data.contacts.form_add_phone_number.phone_location')}
						{<span className='text-red-500'>*</span>}
					</label>
					<select defaultValue={'2'} className='mb-1 form-select form-select-sm' {...register('place')}>
						<option value='2'>{t('pages.personal_data.contacts.form_add_phone_number.work_phone')}</option>
						<option value='1'>{t('pages.personal_data.contacts.form_add_phone_number.home_phone')}</option>
						<option value='3'>{t('pages.personal_data.contacts.form_add_phone_number.personal_phone')}</option>
						<option value='0'>{t('pages.personal_data.contacts.form_add_phone_number.other_phone')}</option>
					</select>
				</div>
				<div className='mb-1'>
					<label className='mb-1'>
						{t('pages.personal_data.contacts.form_add_phone_number.phone_number')}
					</label>
					<div className='flex flex-row items-center mb-1'>
						<div className='flex flex-col mr-2'>
							<CodeOfCountry register={register} resApi={resApiCodeOfCountry} defaultValue={defaultCountryCode} />
						</div>
						<div className='flex flex-col mr-2 w-[18%]'>
							<label className='text-[12px] vvsm:hidden vsm:hidden sm:block md:block'>
								{t('pages.personal_data.contacts.form_add_phone_number.code_of_city')}
								{<span className='text-red-500'>*</span>}
							</label>
							<InputPhone
								name='city_code'
								rules={{
									required: t('valid_params.required'),
									pattern: {
										value:
											/^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm,
										message: t('valid_params.pattern_phone_number')
									}
								}}
								placeholder='3822'
								defaultValue='3822'
								isAllowed={values => {
									const MAX_LIMIT = 99999 // лимитированное кол-во чисел
									const { value } = values
									return value < MAX_LIMIT
								}}
							/>
						</div>
						<div className='flex flex-col'>
							<label className='text-[12px] vvsm:hidden vsm:hidden sm:block'>
								{t('pages.personal_data.contacts.form_add_phone_number.number_phone')}
								{<span className='text-red-500'>*</span>}
							</label>
							<InputPhone
								name='phone'
								rules={{
									required: t('valid_params.required'),
									pattern: {
										value:
											/^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm,
										message: t('valid_params.pattern_phone_number')
									}
								}}
								placeholder=''
								autoFocus={true}
								isAllowed={values => {
									const MAX_LIMIT = 9999999
									const { value } = values
									return value < MAX_LIMIT
								}}
							/>
						</div>
					</div>
				</div>
				<div className='mb-1 text-red-600'>
					{errors?.code_city_input && <small>{errors?.code_city_input?.message || 'Ошибка!'}</small>}
					{errors?.city_phone_input && <small>{errors?.city_phone_input?.message || 'Ошибка!'}</small>}
				</div>
			</>
		)
	}

	const MobilePhone = ({ register }) => {
		return (
			<>
				<label>{t('pages.personal_data.contacts.form_add_phone_number.phone_number')}</label>
				<div className='flex flex-row items-center mb-1'>
					<div className='flex flex-col mr-2'>
						<CodeOfCountry resApi={resApiCodeOfCountry} defaultValue={defaultCountryCode} />
					</div>
					<div className='flex flex-col'>
						<label className='text-[12px]'>
							{t('pages.personal_data.contacts.form_add_phone_number.number_phone')}
							{<span className='text-red-500'>*</span>}
						</label>
						<InputPhone
							name='phone'
							rules={{
								required: t('valid_params.required'),
								pattern: {
									value:
										/^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm,
									message: t('valid_params.pattern_phone_number')
								}
							}}
							placeholder='___ ___-____'
							format='### ###-####'
						/>
					</div>
				</div>
				<div className='mb-1 text-red-600'>
					{errors?.mobile_phone_input && <small>{errors?.mobile_phone_input?.message || 'Ошибка!'}</small>}
				</div>
			</>
		)
	}

	const InternalPhone = ({ register }) => {
		return (
			<>
				<label>{t('pages.personal_data.contacts.form_add_phone_number.internal_telephone_number')}</label>
				{<span className='text-red-500'>*</span>}
				<InputPhone
					name='phone'
					// isInvalid={errors?.phone_type}
					rules={{
						required: t('valid_params.required'),
						pattern: {
							value:
								/^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm,
							message: t('valid_params.pattern_phone_number')
						}
					}}
					placeholder='1234'
					format='####'
					autoFocus
				/>
				<div className='mb-1 text-red-600'>
					{errors?.internal_phone_input && <small>{errors?.internal_phone_input?.message || 'Ошибка!'}</small>}
				</div>
			</>
		)
	}

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className='modal-body'>
					{!isSuccess && (
						<>
							<div className='mb-1'>
								<SelectInput
									name='type'
									label={t('pages.personal_data.contacts.form_add_phone_number.phone_type')}
									classes='mb-1'
									rules={{ required: t('valid_params.required') }}
									optionsData={
										<>
											{/*<option value='0' disabled>*/}
											{/*	-*/}
											{/*</option>*/}
											<option value='1'>
												{t('pages.personal_data.contacts.form_add_phone_number.city_phone_value')}
											</option>
											<option value='2'>
												{t('pages.personal_data.contacts.form_add_phone_number.mobile_phone_value')}
											</option>
											<option value='3'>
												{t('pages.personal_data.contacts.form_add_phone_number.internal_phone_value')}
											</option>
										</>
									}
									redStar
								/>
							</div>
							{value === '1' && <CityPhone register={register} />}
							{value === '2' && <MobilePhone register={register} />}
							{value === '3' && <InternalPhone register={register} />}
							{/*{value === '0' && null}*/}
						</>
					)}
					<div>{message}</div>
				</div>
				<div className='modal-footer'>
					{!isSuccess && (
						<SaveBtn type='submit' onClick={apiUrl}>
							{t('modal_window.save')}
						</SaveBtn>
					)}
					<CloseBtn onClick={handleCloseModal}>
						{t('modal_window.close')}
					</CloseBtn>
				</div>
			</form>
		</FormProvider>
	)
}

export default FormAddNumber
