import { useState, useEffect } from 'react'
import btnWithModalForm from '../HOC/btnWithModalForm'
import { useForm } from 'react-hook-form'
import { delBtn } from '@components/UI/btns/conf-btns/delete'
import FormDelete from '@components/UI/FormsReusable/FormDelete'
// icons
import { FaLock, FaEdit, FaCheck } from 'react-icons/fa'
import { VscChromeClose } from 'react-icons/vsc'
//translate
import { useTranslation } from 'react-i18next'

/*
    Компонент InputCustom - это универсальный инпут со своими кнопками добавить, удалить, отменить изменения и т.д.
*/
const InputCustom = ({
	initialValue = '',
	placeholder = '',
	isLock = false,
	saveApi = null, //GET Function Api
	deleteApi = null, //GET Function Api
	onDeleteApi = null,
	isChangable = false,
	isDeletable = false,
	validMode = 'onSubmit',
	validParams = {},
	required = false,
	children,
	updatable = false,
	set_update = null
}) => {
	const [value] = useState(initialValue)
	const [isDisabled, setIsDisabled] = useState(true)
	const [tempValue, setTempValue] = useState(null)
	const [isEditing, setEditing] = useState(false)

	const { t } = useTranslation()

	// state react-hook-form
	const {
		register,
		handleSubmit,
		setFocus,
		setValue,
		formState: { errors }
	} = useForm({
		mode: validMode
	})

	const handleEditing = () => {
		setEditing(!isEditing)
	}

	const handleKeyDown = e => {
		// if (e.key === 'Enter'){
		// }
		if (e.key === 'Escape') {
			handleButtonClickClose()
		}
	}

	const handleButtonChangeInput = e => {
		e.preventDefault()
		setTempValue(value)
		setIsDisabled(!isDisabled)
		handleEditing()
	}

	const handleButtonClickClose = () => {
		setValue('input_custom', tempValue)
		setIsDisabled(!isDisabled)
		handleEditing()
		errors.input_custom = undefined // убираем всплывающий div c ошибкой
	}

	const onSubmit = () => {
		// проверяем, что это не форма по удалению записи
		if (!updatable) {
			setIsDisabled(!isDisabled)
			handleEditing()
		}
	}

	useEffect(() => {
		if (isEditing) {
			setFocus('input_custom')
		}
	}, [setFocus, isEditing])

	useEffect(() => {
		if (value) {
			setValue('input_custom', value)
		}
	}, [setValue, value])

	const DeleteBtn =
		isDeletable &&
		btnWithModalForm(FormDelete, {
			title: t('modal_window.title_modal_delete_data'),
			modal_header: false,
			btn_text: '',
			btn_icon: delBtn.btn_icon,
			btn_type: delBtn.btn_type,
			updatable: updatable,
			set_update: set_update && set_update,
			btn_tooltip_tippy: { status: true, content: t('modal_window.delete_title'), placement: 'top', theme: 'default' },
			dataBsTarget: 'FormDelete'
		})

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className='input-group my-1'>
				{children}
				<input
					defaultValue={value}
					{...register('input_custom', validParams)}
					disabled={isDisabled}
					placeholder={placeholder}
					className={`
						${!isDisabled ? 'active form-control form-control-sm' : 'form-control form-control-sm'}
						${errors.input_custom ? 'is-invalid' : ''}
					`}
					onKeyDown={handleKeyDown}
					required={required}
				/>
				{isLock ? (
					<butoon type='button' className='btn btn-secondary' disabled>
						<FaLock size='20' />
					</butoon>
				) : isDisabled ? (
					<>
						{isChangable && (
							<button type='button' className='btn btn-primary' onClick={handleButtonChangeInput}>
								<FaEdit size='20' />
							</button>
						)}
						{isDeletable && <DeleteBtn urlApi={deleteApi} onDelete={onDeleteApi} />}
					</>
				) : (
					<>
						<button className='btn btn-success' type='submit'>
							<FaCheck size='20' />
						</button>
						<button type='button' className='btn btn-danger' onClick={handleButtonClickClose}>
							<VscChromeClose size='20' />
						</button>
					</>
				)}
			</div>
			<div className='mb-1 text-red-600'>
				{errors?.input_custom && <p>{errors?.input_custom?.message || 'Ошибка!'}</p>}
			</div>
		</form>
	)
}

export default InputCustom
