import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { requestGetApi } from '@services/api/requestServerApi'
import Table from '@components/UI/Table'
import MainLoader from '@components/Loaders/MainLoader'
import Message from '@components/UI/Message'
import Title from '@components/UI/Title'
//translate
import { useTranslation } from 'react-i18next'

/*
    функция LanguageList подтягивает данные по api о знаниях иностранных языков пользователя
*/
const LanguageList = () => {
	const { t } = useTranslation()

	const [resApi, setResApi] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [isError, setIsError] = useState(false)
	const accessToken = useSelector(store => store.authUser.accessToken)

	const SERVER_API = process.env.REACT_APP_SERVER_API_YII2
	const API_KEY = process.env.REACT_APP_API_KEY

	const prepareData = async response => {
		response.data.code === 200 ? setResApi(response.data.data) : setIsError(true)
	}

	useEffect(() => {
		requestGetApi(
			`${SERVER_API}/portal/language?apiKey=${API_KEY}`,
			{ headers: { 'Token': accessToken } },
			prepareData,
			setIsLoading,
			setIsError,
			null,
			false,
			true
		)
		// eslint-disable-next-line
	}, [])

	const TableContent = ({ data }) =>
		data && Array.isArray(data) && data.length > 0 ? (
			<Table striped bordered>
				<thead>
					<tr>
						<th className='w-[30%]'>{t('pages.personal_data.education.tables.2.columns.0')}</th>
						<th className='w-[30%]'>{t('pages.personal_data.education.tables.2.columns.1')}</th>
						<th className='w-[30%]'>{t('pages.personal_data.education.tables.2.columns.2')}</th>
						<th className='w-[10%]'>{t('pages.personal_data.education.tables.2.columns.3')}</th>
					</tr>
				</thead>
				<tbody>
					{data.map((item, index) => (
						<tr key={`${index}-language`}>
							<td>{item.language}</td>
							<td>{item.language_level}</td>
							<td>{item.basis}</td>
							<td>{item.date_basis}</td>
						</tr>
					))}
				</tbody>
			</Table>
		) : (
			<Message alert alert_status='secondary' key_message='errors.data_not_found' />
		)

	return (
		<>
			<Title tag='h3' title={t('pages.personal_data.education.tables.2.title')} />
			{isLoading ? (
				<MainLoader />
			) : isError ? (
				<Message key_message='errors.data_load' alert alert_status='danger' />
			) : (
				<TableContent data={resApi} />
			)}
		</>
	)
}

export default LanguageList
