// /* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	data: null,
	fetchStaffDataStatus: 'idle'
}

const staffDataSlice = createSlice({
	name: 'staffData',
	initialState,
	reducers: {
		fetching: (state, action) => {
			state.fetchStaffDataStatus = 'loading'
		},

		fetchingError: {
			reducer: (state, action) => {
				state.fetchStaffDataStatus = action.payload
			},
			prepare: strError => {
				return {
					payload: strError || 'Произошла ошибка!!!'
				}
			}
		},

		fetchingSuccess: {
			reducer: (state, action) => {
				state.fetchStaffDataStatus = 'idle'
				state.data = action.payload
			},
			prepare: staffData => {
				return { payload: staffData }
			}
		},

		removeData: (state, action) => {
			state.fetchStaffDataStatus = 'idle'
			state.data = null
		}
	}
})

const { actions, reducer } = staffDataSlice

export const { fetching, fetchingError, fetchingSuccess, removeData } = actions

export default reducer
