import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { FaTrashAlt } from 'react-icons/fa'
import { requestGetApi } from '@services/api/requestServerApi'
import { addBtn } from '@components/UI/btns/conf-btns/add'
import { delBtn } from '@components/UI/btns/conf-btns/delete'
import btnWithModalForm from '@components/UI/HOC/btnWithModalForm'
import Title from '@components/UI/Title'
import Table from '@components/UI/Table'
import FormAddNumber from './FormAddNumber'
import MainLoader from '@components/Loaders/MainLoader'
import FormDelete from '@components/UI/FormsReusable/FormDelete'
import AlertMessage from '@components/UI/AlertMessage'
import Message from '@components/UI/Message'
// icons
import { FcPhone, FcPhoneAndroid, FcCellPhone, FcMultipleSmartphones } from 'react-icons/fc'
//translate
import { useTranslation } from 'react-i18next'

/*
    функция ContactPhoneNumbers выводит данные по телефонам пользователя
*/
const ContactPhoneNumbers = () => {
	const { t } = useTranslation()
	const [resApi, setResApi] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [isError, setIsError] = useState(false)
	const [message, setMessage] = useState(null)
	const accessToken = useSelector(state => state.authUser.accessToken)
	const [update, setUpdate] = useState({})

	const prepareData = async response => {
		// console.log(response)
		if (response?.data?.code === 200) {
			Array.isArray(response?.data?.data) && response?.data?.data.length !== 0 && setResApi(response.data.data)
		} else if (response.data.code === 429) {
			setIsError(true)
			setMessage(<Message key_message='errors.page_429' alert />)
		} else {
			setIsError(true)
			setMessage(<Message key_message='errors.data_load' alert />)
		}
	}

	useEffect(() => {
		requestGetApi('user/employee/phone', { headers: { 'X-TOKEN': accessToken } }, prepareData, setIsLoading, setIsError)
		// eslint-disable-next-line
	}, [update])

	const NumberForm = btnWithModalForm(FormAddNumber, {
		title: t('pages.personal_data.contacts.add_number'),
		btn_text: t('pages.personal_data.contacts.add_number'),
		btn_icon: addBtn.btn_icon,
		btn_type: addBtn.btn_type,
		btn_classes: addBtn.btn_classes,
		btn_size: addBtn.btn_size,
		icon_size: addBtn.icon_size,
		updatable: true,
		set_update: () => setUpdate({}),
		dataBsTarget: 'NumberForm'
	})

	const DeletePhone = btnWithModalForm(FormDelete, {
		title: t('modal_window.title_modal_delete_data'),
		modal_header: false,
		btn_type: delBtn.btn_type,
		btn_icon: delBtn.btn_icon,
		btn_size: delBtn.btn_size,
		icon_size: delBtn.icon_size,
		updatable: true,
		btn_tooltip_tippy: { status: true, content: t('modal_window.delete_title'), placement: 'top', theme: 'default' },
		set_update: () => setUpdate({}),
		dataBsTarget: 'DeletePhone'
	})

	const getTypePhone = type => {
		switch (type) {
			case '1':
				return t('pages.personal_data.contacts.form_add_phone_number.city_phone_value')
			case '2':
				return t('pages.personal_data.contacts.form_add_phone_number.mobile_phone_value')
			case '3':
				return t('pages.personal_data.contacts.form_add_phone_number.internal_phone_value')
			default:
				return t('pages.personal_data.contacts.form_add_phone_number.other_phone')
		}
	}

	const IconTypePhone = ({ type }) => {
		switch (type) {
			case '1':
				return <FcPhone size={20} />
			case '2':
				return <FcPhoneAndroid size={20} />
			case '3':
				return <FcCellPhone size={20} />
			default:
				return <FcMultipleSmartphones size={20} />
		}
	}

	const getPositionPhone = position => {
		switch (position) {
			case '1':
				return t('pages.personal_data.contacts.form_add_phone_number.work_phone')
			case '2':
				return t('pages.personal_data.contacts.form_add_phone_number.home_phone')
			// case '3': return t('pages.personal_data.contacts.form_add_phone_number.personal_phone')
			// default: return t('pages.personal_data.contacts.form_add_phone_number.other_phone')
			default:
				return t('pages.personal_data.contacts.form_add_phone_number.personal_phone')
		}
	}

	const TableContent = ({ data }) => (
		<>
			<NumberForm />
			{data && Array.isArray(data) && data.length > 0 ? (
				<Table striped bordered classes='align-middle my-2'>
					<thead>
						<tr>
							<th className='w-[30px]'></th>
							<th>{t('pages.personal_data.contacts.tables.0.columns.0')}</th>
							<th>{t('pages.personal_data.contacts.tables.0.columns.1')}</th>
							{/* <td className='w-[100px]'>{t('pages.personal_data.contacts.tables.0.columns.2')}</td> */}
							{/* <td className='w-[100px]'>{t('pages.personal_data.contacts.tables.0.columns.3')}</td> */}
							<th>{t('pages.personal_data.contacts.tables.0.columns.4')}</th>
							<th className='text-center w-[40px]'>
								<FaTrashAlt />
							</th>
						</tr>
					</thead>
					<tbody>
						{data.map((item, i) => (
							<tr key={`phone_${i}`}>
								<td>
									<IconTypePhone type={item.type_id} />
								</td>
								<td>{getTypePhone(item.type_id)}</td>
								<td>{getPositionPhone(item.is_work)}</td>
								{/* <td>{item.country_code}</td> */}
								<td>
									{item.type_id !== '3' && '+'}
									{item.country_code}
									{item.city_code}
									{item.phone}
								</td>
								{/* <td>{item.phone}</td> */}
								{/* <td><Button variant='danger' size='sm'><FaTrashAlt/></Button></td> */}
								<td>
									<DeletePhone
										urlApi={`user/employee/phone/${item.phone_id}`}
										// onDelete={() => setResApi(data.filter(d => d.phone_id !== item.phone_id))}
									/>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			) : (
				<Message alert alert_status='secondary' key_message='errors.data_not_found' />
			)}
			<AlertMessage status='warning' classes='mt-2'>
				{t('pages.personal_data.contacts.contact_numbers_message')}
			</AlertMessage>
		</>
	)

	return (
		<>
			<Title tag='h3' title={t('pages.personal_data.contacts.contact_numbers')} />
			{isLoading ? <MainLoader /> : isError ? <div>{message}</div> : <TableContent data={resApi} />}
		</>
	)
}

export default ContactPhoneNumbers
