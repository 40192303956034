import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { requestGetApi } from '@services/api/requestServerApi'
// import { addBtn } from '../../../../../components/UI/btns/conf-btns/add'
import btnWithModalForm from '@components/UI/HOC/btnWithModalForm'
import Title from '@components/UI/Title'
import FormChangeAddress from './FormChangeAddress'
import Message from '@components/UI/Message'
import MainLoader from '@components/Loaders/MainLoader'
// icons
import { FaMap } from 'react-icons/fa'
//translate
import { useTranslation } from 'react-i18next'

/*
    функция PlaceOfWork выводит данные по основному месту работы пользователя
*/
const PlaceOfWork = () => {
	const { t } = useTranslation()
	const [isAddress] = useState(true)
	const accessToken = useSelector(state => state.authUser.accessToken)

	const [resApi, setResApi] = useState(null)
	const [isLoading, setLoading] = useState(false)
	const [isError, setIsError] = useState(false)
	const [message, setMessage] = useState(null)
	const [isUpdaited, setIsUpdaited] = useState({})

	const prepareData = async response => {
		if (response.data.code === 200) {
			if (response.data.data) {
				// console.log(response.data?.data)
				setResApi(response.data.data)
			} else {
				setIsError(true)
				setMessage(<Message key_message='errors.data_load' alert />)
			}
		} else if (response.data.code === 429) {
			setIsError(true)
			setMessage(<Message key_message='errors.page_429' alert />)
		} else {
			setIsError(true)
			setMessage(<Message key_message='errors.data_load' alert />)
		}
	}

	useEffect(() => {
		requestGetApi(
			`user/employee/workplace`,
			{ headers: { 'X-TOKEN': accessToken } },
			prepareData,
			setLoading,
			setIsError
		)
		// eslint-disable-next-line
	}, [isUpdaited])

	const AddressForm = btnWithModalForm(FormChangeAddress, {
		title: !isAddress
			? t('pages.personal_data.contacts.button_add_address')
			: `${t('pages.personal_data.contacts.title_modal_change_data')}`,
		btn_text: !isAddress && t('pages.personal_data.contacts.button_add_address'),
		btn_icon: isAddress ? 'PencilAlt' : 'Plus',
		btn_type: isAddress ? 'primary' : 'secondary',
		btn_classes: 'btn-success green-button',
		updatable: true,
		icon_size: 16,
		btn_tooltip_tippy: { status: true, content: t('modal_window.edit'), placement: 'top', theme: 'default' },
		set_update: () => handlerUpdateWorkPlace(),
		dataBsTarget: 'AddressForm'
	})

	const handlerUpdateWorkPlace = () => {
		setIsUpdaited({})
	}

	return (
		<>
			<Title tag='h3' title={t('pages.personal_data.contacts.place_work')} />
			{isLoading ? (
				<MainLoader />
			) : isError ? (
				<div>{message}</div>
			) : (
				<>
					{isAddress ? (
						<>
							<div className='input-group'>
								<span className='input-group-text'>{isLoading ? <MainLoader small={'spinner-border-sm'} /> : <FaMap size={20} />}</span>
								<input
									className='form-control form-control-sm'
									disabled
									value={resApi ? `${resApi.building}, ${resApi.office}` : ''}
								/>
								<AddressForm />
							</div>
						</>
					) : (
						<AddressForm />
					)}
				</>
			)}
		</>
	)
}

export default PlaceOfWork
