import Img from '@components/UI/Img'
import Card from '@components/UI/Card'
import './ProfileCard.css'
//Tippy
import Tippy from '@tippyjs/react'
//icons
import { FaEnvelope } from 'react-icons/fa'
import { AiFillTool } from 'react-icons/ai'
import { Link } from 'react-router-dom'
//translate
import { useTranslation } from 'react-i18next'

/*
    Компонент ProfileCard отображает навигационную форму со всеми карточками в компоненте ProfilePage
*/
export const ProfileCard = ({ classes = '', userInfo, activeComponent = null }) => {
	const { t } = useTranslation()

	return (
		<Card classes={classes}>
			<div className='profile-card w-100'>
				<div className='area_animation absolute w-100 h-[72px] z-[0] md:m-[-11px] m-[-7px] bg-gradient-to-r from-green-800 to-green-500'>
					<ul className='animation_cubs'>
						{Array(20)
							.fill()
							.map((_, i) => {
								const wh = `${Math.floor(Math.random() * (60 - 10 + 1) + 10)}px`
								return (
									<li
										key={i}
										style={{
											left: `${Math.floor(Math.random() * (90 - 5 + 1) + 5)}%`,
											width: wh,
											height: wh,
											animationIterationCount: 'infinite',
											animationDuration: `${Math.random() * (8 - 4 + 1) + 4}s`,
											animationDelay: `${Math.random() * (2 - 1 + 1) + 1}s`,
											bottom: '-100px'
										}}
									></li>
								)
							})}
					</ul>
				</div>
				<div className='relative w-[110px]'>
					<Tippy content={t('pages.personal_data.avatar_editor.title_page')} placement='top' theme='default'>
						<Link
							to={'/profile/avatar_editor'}
							className='absolute cursor-pointer shadow bg-gray-300 h-[20px] w-[20px] rounded-sm m-1 right-0 z-10'
						>
							<AiFillTool className='absolute top-[2px] left-[2px] text-gray-700' />
						</Link>
					</Tippy>
					<Img src={userInfo.image} classes={['rounded-md mb-2 relative border-4 border-gray-400']} />
				</div>
				<div className='relative z-[1] xs:mt-[70px]'>
					<p className='text-xl mb-1 font-bold'>
						<a
							href={`https://staff.tpu.ru/personal/employee?lid=${userInfo.lichnostId}`}
							target='_blank'
							rel='noreferrer'
						>
							{userInfo.name}
						</a>
					</p>

					{activeComponent}

					<p className='text-[13px]'>
						<FaEnvelope size='18' />
						<a href={`mailto:${userInfo.email}`}>{userInfo.email}</a>
					</p>
				</div>
			</div>
		</Card>
	)
}

export default ProfileCard
